import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { BASE_URL } from "../../../utils/variables";
import DataTable from "../../../components/Table";
import OverAllTenantSelection from "../../../components/OverAllTenantSelection";
import SimpleTable from "../../../components/Table/simple";
import FilterTable from "../../../components/Table/FilterTable";
import ImportLogs from "../../../components/ImportLogs";

const AnalyticsPage = () => {
  const [data, setData] = useState({
    loading: true,
    auditHeaders: [
      { align: "left", value: "Import Name" },
      { align: "left", value: "Date" },
      { align: "left", value: "Run Status" },
      { align: "right", value: "Rows Processed" },
      { align: "right", value: "Run Time" },
      { align: "right", value: "Connection Name" },
      { align: "left", value: "Error" },
    ],

    failedAuditHeaders: [
      { align: "left", value: "Import Name" },
      { align: "left", value: "Error" },
      { align: "left", value: "Run Time" },
    ],

    stats: {},
    overAllStats: [
      {
        label: "Total Connections",
        value: "2",
        id: "total_connections",
      },
      {
        label: "Total Imports",
        value: "75",
        id: "total_imports",
      },
      {
        label: "Total Active Imports",
        value: "73",
        id: "active_imports",
      },
      {
        label: "Total Inactive Imports",
        value: "2",
        id: "inactive_imports",
      },
      {
        label: "Total Failed Imports",
        value: "9",
        id: "failed_imports",
      },
    ],
    failedStats: [],
    imports_scheduled: [],

    allConnections: [],
    auditLogs: [],
    auditLogss: [],
    failedAuditLogs: [],
  });

  const [graphData, setGraphData] = useState({});

  const graphOptions = {
    scales: {
      yAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: "Number of Imports",
          },
          ticks: {
            display: false,
          },
        },
      ],
      xAxes: [],
    },
  };

  const fetchDashBoardData = async () => {
    try {
      let tenant_id = localStorage.getItem("selected_tenant_id");
      const response = await fetch(`${BASE_URL}dashboard/${tenant_id}/page/1`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const responseData = await response.json();

      let active_imports = {};

      let newAuditLogs = [],
        repeated_logs = {};
      //console.log("##$$@@ ", responseData.audit_logs);

      for (let x in responseData.audit_logs) {
        let this_log = responseData.audit_logs[x];
        let tenant_name = responseData.audit_logs[x].tenant_name;

        let newLog = {
          "Import Name": {
            type: "text",
            align: "left",
            id: "import_name",
            value: this_log.program_name,
          },
          Date: {
            type: "text",
            align: "left",
            id: "Date",
            value: this_log.end_time.slice(0, 19),
          },
          Frequency: {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.imports_data
              ? this_log.imports_data.frequency
              : "-",
          },
          "Date/Day/Time": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: "-",
          },
          "Time of Run": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.imports_data
              ? this_log.imports_data.time_of_run
              : "-",
          },
          "Run Status": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.status,
          },
          Error: {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.error,
          },
          "Rows Processed": {
            type: "number",
            align: "right",
            id: "Frequency",
            value: parseInt(this_log.rows_written),
          },
          "Data Processed": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.size
              ? `${parseFloat(this_log.size).toFixed(2)} MB`
              : "0",
          },
          "Start Time": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.start_time,
          },
          "End Time": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.end_time,
          },
          "Run Time": {
            type: "text",
            align: "right",
            id: "Frequency",
            value: this_log.run_duration.slice(0, 7),
          },
          "Connection Name": {
            type: "text",
            align: "right",
            id: "Frequency",
            value: this_log.connection_type.toUpperCase(),
          },
        };
        // newAuditLogs.push(newLog);
        // console.log("##$$$$$$$$", this_log);

        // take email import
        if (this_log.connection_type.toLowerCase() === "email") {
          // check if that email import exists in repeated variable

          if (repeated_logs[this_log.import_id]) {
            if (repeated_logs[this_log.import_id].includes(this_log.time)) {
              // console.log(
              //   "#########----------",
              //   this_log.date,
              //   repeated_logs[this_log.import_id],
              //   newAuditLogs.length
              // );
              newAuditLogs.push(newLog);
              repeated_logs[this_log.import_id].push(this_log.date);
            } else {
            }
          } else {
            repeated_logs[this_log.import_id] = [];
            repeated_logs[this_log.import_id].push(this_log.date);
            newAuditLogs.push(newLog);
          }
        } else {
          newAuditLogs.push(newLog);
        }
        if (moment(this_log.date).diff(moment(), "days") < 2)
          if (active_imports[tenant_name]) {
            active_imports[tenant_name].rows_written += parseFloat(
              this_log.rows_written
            );
            active_imports[tenant_name].data_processed += parseFloat(
              this_log.size
            );
          } else {
            active_imports[tenant_name] = {
              active_imports: 1,
              failed_imports: 0,
              rows_written: parseFloat(this_log.rows_written),
              data_processed: parseFloat(this_log.size),
            };
          }
      }

      let failedAuditLogs = [];
      for (let y in responseData.failed_audit_logs) {
        let this_log = responseData.failed_audit_logs[y];
        let newLog = {
          "Import Name": {
            type: "text",
            align: "left",
            id: "import_name",
            value: this_log.program_name,
          },
          Error: {
            type: "text",
            align: "left",
            id: "error",
            value: this_log.error,
          },
          "Run Time": {
            type: "text",
            align: "right",
            id: "run_time",
            value: this_log.end_time.slice(0, 19),
          },
        };

        failedAuditLogs.push(newLog);
      }

      let today = moment();
      setGraphData({
        labels: [
          today.format("DD-MM-YY"),
          today.subtract(1, "days").format("DD-MM-YY"),
          today.subtract(1, "days").format("DD-MM-YY"),
          today.subtract(1, "days").format("DD-MM-YY"),
          today.subtract(1, "days").format("DD-MM-YY"),
          today.subtract(1, "days").format("DD-MM-YY"),
          today.subtract(1, "days").format("DD-MM-YY"),
        ],
        datasets: [
          {
            label: "Successful Imports",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "green",
            borderColor: "green", // The main line color
            borderCapStyle: "butt",
            borderDash: [], // try [5, 15] for instance
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "green",
            pointBackgroundColor: "white",
            pointBorderWidth: 1,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: "green",
            pointHoverBorderColor: "green",
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
            data: responseData.graph_data["success"],
          },
          {
            label: "Failed Imports",
            lineTension: 0.1,
            backgroundColor: "orange",
            borderColor: "orange",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "white",
            pointBackgroundColor: "orange",
            pointBorderWidth: 1,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: "orange",
            pointHoverBorderColor: "orange",
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
            data: responseData.graph_data["failed"],
          },
        ],
      });

      setData({
        ...data,
        auditLogs: responseData.audit_logs,

        stats: responseData.stats,
        failedAuditLogs,
        loading: false,
      });
    } catch (error) {
      if (error.code === "token_not_valid") {
        localStorage.clear();
        window.location.replace("/");
      }
      setData({
        ...data,
        loading: false,
      });
    }
  };

  useEffect(() => {
    fetchDashBoardData();
  }, []);

  if (data.loading) {
    return (
      <div className="h-screen max-w-screen mx-auto text-center flex justify-center flex-col">
        <AiOutlineLoading3Quarters
          className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
          size={40}
        />
      </div>
    );
  }

  return (
    <Fragment>
      <main className="m-2 lg:m-4">
        <div className=" my-5 md:flex md:justify-between max-w-full">
          <div className="text-3xl font-bold flex justify-between items-center">
            Welcome, {localStorage.getItem("full_name")}
          </div>
          {/* <div className="flex">
            <OverAllTenantSelection />
          </div> */}
        </div>

        <div className="max-w-full">
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
            {data.overAllStats.map((stat) => (
              <div
                key={stat.id}
                className="px-4 py-5 bg-white shadow-lg rounded-lg overflow-hidden sm:p-6"
              >
                <dt className="text-sm font-medium text-gray-500 truncate">
                  {stat.label}
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {data.stats[stat.id]}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="mt-10 my-5 flex justify-between max-w-full"></div>
        <div>
          <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
            <div className="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
              <div className="">
                <div className="header mb-3">
                  <p className="text-2xl text-center">
                    Failed Imports (Last 24 Hours)
                  </p>
                </div>
                <div className="flex flex-col">
                  <div
                    className="overflow-x-auto shadow-xl"
                    style={{ maxHeight: "300px" }}
                  >
                    <div className="align-middle inline-block min-w-full">
                      <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <SimpleTable
                          data={data.failedAuditLogs}
                          columns={data.failedAuditHeaders}
                          actions={[]}
                          tableCSS={{ headerColor: "#E2EAE3", color: "black" }}
                        />
                        {/* <DataTable
                          headers={data.failedAuditHeaders}
                          content={data.failedAuditLogs}
                          values
                          statusDropdown={false}
                          emptyNotification="No Failed Imports"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:rounded-tr-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
              <div className="header mb-3">
                <p className="text-2xl text-center">Imports Status Overview</p>
              </div>
              <Line data={graphData} options={graphOptions} />
            </div>
          </div>
        </div>

        {/* ------------------------------------------------Table Started--------------------------------  */}

        <div className="my-5"></div>

        <ImportLogs data={data.auditLogs} />
      </main>
    </Fragment>
  );
};

export default AnalyticsPage;
