import { AiOutlineLoading3Quarters } from "react-icons/ai";



const Loading = () => {
  return (
    <div className="h-screen max-w-screen mx-auto text-center flex justify-center flex-col">
      <AiOutlineLoading3Quarters
        className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
        size={40}
      />
    </div>
  );
};

export default Loading;
