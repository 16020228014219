const DataConvert = (amount) => {
  amount = parseInt(amount);
  if (isNaN(amount)) return "0 MB";

  let i = 0;
  const type = ["MB", "GB", "TB"];
  while (amount > 1024) {
    amount = parseFloat(amount / 1024).toFixed(2);
    if (i < 2) i += 1;
  }
  return `${amount} ${type[i]}`;
};

module.exports = { DataConvert };
