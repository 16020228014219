import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  AiOutlineSearch,
  AiOutlineLoading3Quarters,
  AiFillPlusCircle,
  AiFillAlert

} from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { BASE_URL, TENANT_ID, USERS } from "../../../utils/variables";
import SimpleTable from "../../../components/Table/simple";
import CreateConnectionModal from "./createConnection/index";
import EditConnectionModal from "./editConnection";
import RemoveConnectionModal from "./removeConnection";
import ReIssuePasswordModal from "./ReIssuePassword";
import DataCard from "../../../components/Cards/DataCard";



const ConnectionsPage = () => {
  const [showSearch, setShowSearch] = useState(false);

  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
  }
  let history = useHistory();
  const [data, setData] = useState({
    loading: true,
    headers: [
      { align: "left", value: "Name" },
      { align: "left", value: "Connection Type" },
      { align: "left", value: "Network ID/Name" },
      { align: "left", value: "Created By" },
      { align: "left", value: "# Imports" },
      { align: "left", value: "Last Updated" },
    ],
    raw_connections: [],
    allConnections: [],
    filteredData: []
  });
  const [isOpen, setIsOpen] = useState(false);
  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const [state, setState] = useState({
    selected: 0,
    removeConnectionData: "",
  });
  // console.log("STATESELECTED", state.selected);
  const modalRef = useRef();
  const editConnectionRef = useRef();
  const removeConnectionRef = useRef();
  const reIssuePasswordRef = useRef();
  //console.log("RAW CONNECTIONS", JSON.stringify(data))
  const fetchAllConnection = async () => {
    try {
      var TENANT_IDs =
        localStorage.getItem("isSuperAdmin") === true
          ? 0
          : localStorage.getItem("selected_tenant_id");

      const response = await fetch(`${BASE_URL}connection/all/${TENANT_IDs}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,

        },
      });
      const responseData = await response.json();

      let AllConnections = [],
        raw_connections = responseData.all_connections;

      //Code to Count Number of connections
      const numObjects = responseData.all_connections.length;
      setState(prevState => ({
        ...prevState,
        count: numObjects
      }));

      for (let x in responseData.all_connections) {
        let connection = responseData.all_connections[x];
        // console.log(connection.total_imports);
        let newLog = {
          name: {
            type: "text",
            align: "left",
            id: "import_name",
            style: "",
            value: connection.connection_name,
          },
          statuschip: {
            type: "text",
            align: "center",
            id: "connection_type",
            style: "bg-[#D8DADC]",//"bg-[#cdf5f4]",
            value: connection.connection_type.toUpperCase(),
          },
          network_id: {
            type: "text",
            align: "left",
            id: "Frequency",
            style: "",
            value: !connection.email ? connection.network_id : connection.email,
          },
          // textinfo1
          created_by: {
            type: "text",
            align: "left",
            id: "created_by",
            style: "",
            value: connection.created_by,
          },
          // databox
          total_imports: {
            type: "text",
            align: "center",
            id: "total_imports",
            style: "",
            value: connection.total_imports,
          },
          // textinfo2
          last_updated: {
            type: "text",
            align: "left",
            id: "lastupdated",
            style: "",
            value:
              moment(connection.updated_at).format("YYYY-MM-DD hh:mm a") || "-",
          },
          id: {
            type: "text",
            align: "left",
            id: "Frequency",
            style: "",
            value: connection.connection_id,
          },
          old_owners: {
            type: "text",
            align: "left",
            id: "old_owners",
            style: "",
            value: connection.old_owners,
          },

          service_account: {
            type: "text",
            align: "left",
            id: "service_account",
            style: "",
            value: connection.service_account,
          },
          project_name: {
            type: "text",
            align: "left",
            id: "project_name",
            style: "",
            value: connection.project_name,
          },
          created_at: {
            type: "text",
            align: "left",
            id: "created_at",
            style: "",
            value: connection.created_at,
          },
        };
        AllConnections.push(newLog);
      }
      setData({
        ...data,
        allConnections: AllConnections,
        filteredData: AllConnections,
        loading: false,
        raw_connections,
      });
    } catch (error) {
      if (error.code === "token_not_valid") {
        localStorage.clear();
        window.location.replace("/");
      }
      setData({ ...data, error: error });
    }
  };

  useEffect(() => {
    fetchAllConnection();
  }, []);

  // console.log("ALLL CONN", JSON.stringify(data.allConnections));
  {/* UNCOMMENT FOR SEARCH BAR */ }
  const handleSearch = (e) => {

    const value = e.target.value.toLowerCase();
    const filteredData = data.allConnections.filter((connection) =>
      connection.name.value.toLowerCase().includes(value) ||
      connection.statuschip.value.toLowerCase().includes(value) ||
      // connection.project_name.value.toLowerCase().includes(value) ||
      connection.created_by.value.toLowerCase().includes(value)
    );
    // setData(filteredData);
    setData({ ...data, filteredData: filteredData });
    // console.log("filtered data", filteredData);
  };

  const _openRemoveConnectionModal = (e) => {
    setState({ ...state, removeConnectionData: e });
    setTimeout(() => {
      removeConnectionRef.current.openModal();
    }, 100);
  };
  const _openShowConnection = (k) => {
    JSON.parse(localStorage.getItem("tenants")).map(
      (tenant) => {
        if (
          tenant.tenant_id ===
          data.raw_connections[k].tenant
        ) {
          var selected_name = tenant.tenant_name;
          localStorage.setItem(
            "selected_tenant_name",
            tenant.tenant_name
          );
          // console.log(
          //   "selected_tenant_name",
          //   selected_name
          // );
        }
      }
    );

    localStorage.setItem(
      "selectedNetworkId",
      data.raw_connections[k].network_id
    );
    localStorage.setItem(
      "selectedServiceAccount",
      data.raw_connections[k].service_account
    );
    history.push(
      `/dashboard/imports?connection=${data.raw_connections[k].connection_id}&type=${data.raw_connections[k].connection_type}`
    );
  }
  // console.log("TESTING", data.raw_connections[2]);
  const _openEditConnection = (usecase, k) => {
    // console.log('K=======>', k)

    const connPageData = data.raw_connections[k];
    // console.log("DATAAAAA", connPageData);

    let type = "";
    if (usecase.statuschip.value === "EMAIL") {
      type = (usecase.statuschip.value).toLowerCase()
    } else {
      type = usecase.statuschip.value
    }





    // console.log("UPDATE", state.selected);

    history.push(
      `/dashboard/connections/edit/${type}`,
      { connPageData }
    );



  }
  // console.log("DATAAAA", data.raw_connections[state.selected])

  if (data.loading) {
    return (
      <div className="h-screen max-w-screen mx-auto text-center flex justify-center flex-col">
        <AiOutlineLoading3Quarters
          className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
          size={40}
        />
      </div>
    );
  }

  return (
    <Fragment>

      <CreateConnectionModal modalRef={modalRef} />
      <RemoveConnectionModal
        removeConnectionRef={removeConnectionRef}
        removeConnectionData={state.removeConnectionData}
      />
      {/* <ReIssuePasswordModal
        def={data.raw_connections[state.selected]}
        reIssuePasswordRef={reIssuePasswordRef}
      /> */}
      {/* UNCOMMENT FOR SEARCH BAR */}
      {data.filteredData?.length > 0 && (
        // {data.allConnections?.length > 0 && (
        <ReIssuePasswordModal
          def={data.raw_connections[state.selected]}
          reIssuePasswordRef={reIssuePasswordRef}
        />
      )}
      {/* UNCOMMENT FOR SEARCH BAR */}
      {data.filteredData?.length > 0 && (
        // {data.allConnections?.length > 0 && (
        <EditConnectionModal
          def={data.raw_connections[state.selected]}
          editConnectionRef={editConnectionRef}
        />
      )}

      <main className="" id="home">

        {/* Rendering Heading and Button */}
        <div className="mt-5 ml-3 md:px-5 flex justify-between max-w-full">
          <div>
            <h2 className="text-2xl sm:text-3xl text-ai-primary-text-dark font-semibold">
              Connections
            </h2>
            <span className="ml-1 text-sm text-ai-primary-text-teal font-semibold">
              {state.count} connections
            </span>
          </div>

          <div className="flex pl-2 md:px-6">
            <div className="mr-4 mt-1">
              <div className="pt-2 relative">
                {/* UNCOMMENT FOR SEARCH BAR */}
                <input
                  onChange={handleSearch}
                  type="search"
                  placeholder="Search"
                  className={`${showSearch ? 'w-64' : 'w-0 h-2'
                    } rounded-full h-9 border bg-gray-50 mr-1 focus:border-ai-primary-teal focus:outline-none focus:ring-1 focus:ring-sa-secondary transition-all duration-500 ease-in-out`}
                />
                <button
                  onClick={handleToggleSearch}
                  className="absolute mt-1 p-2 top-0 right-0 bg-ai-primary-text-teal rounded-full w-10 h-10 flex items-center justify-center ml-2 cursor-pointer focus:outline-none transition-all duration-500 ease-in-out"
                >
                  <AiOutlineSearch className="text-white text-xl" />
                </button>
              </div></div>
            <button
              disabled={TENANT_ID == 0}
              onClick={() => modalRef.current.openModal()}
              className="p-2 my-2 flex items-center cursor-pointer rounded bg-ai-primary-text-teal text-white uppercase text-xs font-semibold transition-all duration-500 ease-in-out"
            >
              {TENANT_ID != 0 && <AiFillPlusCircle className="w-5 h-5 mr-2" />}
              {TENANT_ID == 0 ? 'Select any Tenant' : 'Create Connection'}
            </button>
          </div>
        </div>
        {data.filteredData.length === 0 ?

          <div className="h-[80vh] max-w-screen mx-auto text-center items-center text-3xl font-semibold flex justify-center flex-col opacity-70">
            <div>    <span className=""><AiFillAlert className=" font-extrabold text-lg mx-auto" /> No Connections Found</span></div>
          </div>
          :


          <div className="px-6 py-2 sm:px-10">
            <div>
              <dl className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">

                {/*[ We are passing our AllConnections data to the datacard Component as props,
We are passing actions through props as well. These actions are in turn passed into the
three dots dropdown.]
*/}

                {/* UNCOMMENT FOR SEARCH BAR */}
                {data.filteredData?.map((data, k) => (
                  // {data.allConnections?.map((data, k) => (
                  <DataCard
                    k={k}
                    usecase={data}
                    actions={[

                      {
                        label: "Edit",
                        action: (usecase, k) => {
                          // console.log("EDIT", k, connection);
                          setData({
                            ...data,

                            loading: true,

                          });
                          setState((prev) => {
                            return { ...prev, selected: k }
                          });

                          setTimeout(
                            () => _openEditConnection(usecase, k),
                            1000
                          );

                        },
                        style: "text-indigo-600 hover:text-indigo-900 text-left mr-10",
                      },
                      {
                        label: "Show",
                        action: ""
                        // (usecase, k) => {
                        //   //  console.log("THISISK", k);
                        //   _openShowConnection(k)
                        // }
                        ,
                        style: "text-indigo-600 hover:text-indigo-900 mr-10",
                      },
                      {
                        label: "Issue Password",
                        action: (usecase, k) => {
                          //console.log("EDIT", k);
                          setState({ ...state, selected: k });
                          setTimeout(
                            () => reIssuePasswordRef.current.openModal(),
                            100
                          );
                        },
                        style: "text-gray-800 text-left mr-10",
                      },
                      {
                        label: "Delete",
                        action: (usecase, k) => {
                          _openRemoveConnectionModal(
                            usecase.id.value
                          );
                        },
                        style: "text-red-600",
                      },
                    ]}
                    openShow={(usecase, k) => {
                      //  console.log("THISISK", k);
                      _openShowConnection(k)

                    }}
                  />
                ))}

              </dl>
            </div>
          </div>

        }


      </main>
    </Fragment>
  );
};

export default ConnectionsPage;
