import React, { Fragment, useEffect, useState, useRef } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import DataTable from "../../../components/Table";
import { BASE_URL, TENANT_ID } from "../../../utils/variables";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import CreateImport from "./CreateImport";
import ModalItemRemoveImport from "./RemoveImport";
import { AiFillPlusCircle } from "react-icons/ai";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import SimpleTable from "../../../components/Table/simple";
import ImportDataCard from "../../../components/Cards/ImportDataCard";
import { useHistory } from "react-router-dom";

const ImportsPage = () => {
  let history = useHistory();
  const [permitted, setPermitted] = useState({
    permitted: true,
  });
  let scrollRef = null;

  const [data, setData] = useState({
    loading: false,
    editImport: false,
    // headers: [
    //   "Import Name",
    //   "Frequency",
    //   "Day",
    //   "Time",
    //   "Latest Run Time",
    //   "Latest Run Status",
    //   "Status",
    //   "Edit",
    //   "Upload",
    //   "Run",
    //   "Delete",
    // ],
    headers: [
      { align: "left", value: "Import Name" },
      { align: "left", value: "Frequency" },
      { align: "left", value: "Day" },
      { align: "left", value: "Time" },
      { align: "left", value: "Latest Run Time" },
      { align: "left", value: "Latest Run Status" },
      { align: "center", value: "Status" },
      // { align: "left", value: "Actions" },
    ],
    // stats: {
    //   total: "7,421",
    //   success: "98.16%",
    //   attentionNeeded: "101",
    // },
    raw_imports: [],
    imports: [],
    allReportNames: [],
    connectionDetails: {},
    allReportsData: false,
  });
  const modalRefRemoveImport = useRef();
  scrollRef = useRef();
  const [currentConnectionType, setCurrentConnectionType] = useState(false);

  const [state, setState] = useState({
    connection: "",
    connection_type: "",
    editSelected: false,
    network_id: "",
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  const fetchImports = async () => {
    // let selected_tenant_id = parseInt(localStorage.selected_tenant_id)
    let params = window.location.href.split("?")[1];
    if (params?.includes("#")) {
      params = params.split("#")[0];
    }
    //let connection_id = parseInt(params.split("&")[0].split("=")[1]);
    let connection = parseInt(params.split("&")[0].split("=")[1]);
    let connection_type = params.split("&")[1].split("=")[1].toLowerCase();

    setState({ ...state, connection, connection_type });
    try {
      const response = await fetch(`${BASE_URL}imports/get/${TENANT_ID}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        //removed connection_id from below
        body: JSON.stringify({ connection_type, connection }),
      });
      const responseJson = await response.json();
      var newImports = [];
      let tempImports = {};
      //console.log("This is Imports get body", JSON.stringify({ connection, connection_type }))
      if (responseJson.IsPermitted === "False") {
        //  console.log("Permission Status", responseJson.IsPermitted)
        // setPermitted({ permitted: false })
        setPermitted({ permitted: false });
      } else {

        for (let i in responseJson.imports) {
          //console.log(responseJson.imports);
          // let imports = responseJson.imports[i];
          let newLog = {
            "Import Name": {
              type: "text",
              align: "left",
              id: "import_name",
              value: responseJson.imports[i].program_name,
            },
            Frequency: {
              type: "text",
              align: "left",
              id: "frequency",
              value: responseJson.imports[i].frequency,
            },
            Day: {
              type: "text",
              align: "left",
              id: "day",
              value:
                responseJson.imports[i].day != null
                  ? responseJson.imports[i].day
                  : "-",
            },
            Time: {
              type: "text",
              align: "left",
              id: "time",
              value: responseJson.imports[i].time,
            },
            Status: {
              type: "status",
              align: "left",
              id: "status",
              value:
                responseJson.imports[i].status === "Inactive"
                  ? "InActive"
                  : "Active",
            },
            "Latest Run Time": {
              type: "text",
              align: "left",
              id: "latest_run_time",
              value: responseJson.imports[i].last_run_time.slice(0, 19),
            },
            "Latest Run Status": {
              type: "text",
              align: "left",
              id: "latest_run_status",
              value: responseJson.imports[i].last_run_status,
            },
            Import_id: {
              type: "text",
              align: "left",
              id: "import_id",
              value: responseJson.imports[i].import_id,
            },
          };
          newImports.push(newLog);
        }
      }

      setState({ ...state, network_id: responseJson.imports[0]?.network_id });
      setData({
        ...data,
        imports: newImports,
        raw_imports: responseJson.imports,
        connectionDetails: responseJson.connection,
        loading: false,
      });
    } catch (error) {
      if (error.code === "token_not_valid") {
        localStorage.clear();
        window.location.replace("/");
      }
      setData({ ...data, error: error, loading: false });
    }
  };



  useEffect(() => {
    setData({
      ...data,

      loading: true,
    });
    fetchImports();
    // eslint-disable-next-line
  }, []);


  const getConnectionTypeByURL = () => {
    let params = window.location.href.split("?")[1];
    if (params.includes("#")) {
      params = params.split("#")[0];
    }
    let connection_type = params.split("&")[1].split("=")[1];
    return connection_type;
  };
  const getConnectionIdByURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const connection = searchParams.get("connection");
    //console.log("Connection ID", connection);
    return connection;
  };
  const _onUserStatusChange = async (e, k) => {
    // console.log("k.importid", k);
    let inputBody = {
      import_id: k,
      connectionType: getConnectionTypeByURL(),
      status: e ? "Active" : "Inactive",
      connection: getConnectionIdByURL(),
    };

    const API = `${BASE_URL}imports/statusupdate/${TENANT_ID}`;
    try {
      const response = await fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(inputBody),
        //body: JSON.stringify({ ...inputBody, selected_tenant_id: parseInt(localStorage.selected_tenant_id) }),
      });
      const responseData = await response.json();

      // console.log("--->", responseData);
      setToast({
        type: false,
        message:
          responseData.response + " Status" + (e ? " Active" : " Inactive"),
      });
      window.location.reload();

    } catch (error) {
      setState({ ...state, success: false, error: "Something went wrong" });
      setToast({
        type: true,
        message: "Something went wrong",
      });
    }
  };

  const _onClickEditImport = (k) => {
    // console.log("KATEDITIMPORT", k);
    //_UpdateUserStatusAPICall(e, k);
    setCurrentConnectionType(false);
    setData({ ...data, editImport: true });
    setState({ ...state, editSelected: k["Import_id"].value });
    let params = window.location.href.split("?")[1];
    if (params.includes("#")) {
      params = params.split("#")[0];
    }
    let connection_type = params.split("&")[1].split("=")[1];
    // fetchReportNames(state.network_id);
    setTimeout(() => {
      setCurrentConnectionType(connection_type);
      // scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
    const dataImports = {
      type: currentConnectionType,
      import_data: data.raw_imports.filter(
        (imp) => imp.import_id === k.Import_id.value
      ),
      connectionID: state.connection,
      state: state || [],
      editImport: true,
      allReportNames: data?.allReportNames,
      allReportsData: data?.allReportsData?.response || [],
      connectionDetails: data.connectionDetails,
    };
    history.push("/dashboard/createimport", { dataImports })

  };

  const _onClickManualRun = async (e) => {
    try {
      const response = await fetch(`${BASE_URL}manual-run`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          import_id: e.Import_id.value,
          import_type: window.location.href.toLowerCase().includes("email")
            ? "email"
            : window.location.href.toLowerCase().includes("gam")
              ? "gam"
              : "tam",
        }),
      });

      const responseData = await response.json();
      setToast({
        type: false,
        message: responseData.response,
      });
    } catch (error) {
      setToast({
        type: true,
        message: error.error,
      });
    }
  };

  const _onFileUploadAndRun = async (e, FileName) => {
    try {
      const response = await fetch(`${BASE_URL}upload-run`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          import_id: e.Import_id.value,
          filename: e.FileName,
        }),
      });

      const responseData = await response.json();
      setToast({
        type: false,
        message: responseData.response,
      });
    } catch (error) {
      setToast({
        type: true,
        message: error.error,
      });
    }
  };
  const _onClickRemoveImport = (e) => {
    setState({ ...state, connection: e.Import_id.value });
    setTimeout(() => {
      modalRefRemoveImport.current.openModal();
    }, 100);
  };

  const onFileUpload = async (e, import_data) => {
    // console.log("click", import_data);
    try {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "import");
      formData.append("import_id", import_data.Import_id);

      const response = await fetch(`${BASE_URL}upload/${TENANT_ID}`, {
        method: "POST",
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
        body: formData,
      });
      const responseData = await response.json();

      setToast({
        type: false,
        message: "File uploaded Successfully",
      });

      // _onClickManualRun({ Import_id: import_data.Import_id });
      _onFileUploadAndRun({
        Import_id: import_data.Import_id,
        FileName: file.name,
      });
    } catch (error) {
      setToast({
        type: true,
        message: "File upload Error",
      });
    }
  };




  // 
  const _openCreateImport = () => {
    // console.log("test here", data.connectionDetails);
    let params = window.location.href.split("?")[1];
    if (params.includes("#")) {
      params = params.split("#")[0];
    }
    let connection = parseInt(params.split("&")[0].split("=")[1]);
    let connection_type = params.split("&")[1].split("=")[1].toLowerCase();
    const dataImports = {
      type: currentConnectionType,
      // import_data:{
      //   state.editSelected === false
      //     ? false
      //     : data.raw_imports.filter(
      //         (imp) => imp.import_id === state.editSelected
      //       )
      // },
      connectionID: state.connection,
      imstate: state || [],
      editImport: data?.editImport,
      allReportNames: data?.allReportNames,
      allReportsData: data?.allReportsData || [],
      connectionDetails: data.connectionDetails,

    }

    history.push("/dashboard/createimport", { dataImports });
  };


  // console.log("sourabhannaa=",data.allReportNames)



  if (data.loading) {
    return (
      <div className="h-screen max-w-screen mx-auto text-center flex justify-center flex-col">
        <AiOutlineLoading3Quarters
          className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
          size={40}
        />
      </div>
    );
  } else if (!permitted.permitted) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <ModalItemRemoveImport
        connection_id={state.connection}
        modalRefRemoveImport={modalRefRemoveImport}
      />
      {toast.message && (
        <ToastMsg
          isError={toast.type}
          msg={toast.message}
          isPageToast={true}
          setToast={setToast}
        />
      )}
      <main className="" id="home">
        <div className="mt-5 ml-3 md:px-5 flex justify-between max-w-full">
          <div>
            <h2 className="text-2xl sm:text-3xl text-ai-primary-text-dark font-semibold">
              Imports
            </h2>
            <span className="ml-1 text-md text-ai-primary-text-teal font-semibold">
              {data.connectionDetails.connection_name}
            </span>
          </div>

          <div className="pl-2 md:px-6">
            <button
              href="#create"
              onClick={_openCreateImport}
              // onClick={_newConnectionScroll}
              className="p-2 flex  items-center cursor-pointer rounded bg-ai-primary-text-teal text-white uppercase text-xs font-semibold"
            >
              <AiFillPlusCircle className="w-5 h-5 mr-2" />
              CREATE NEW IMPORT
            </button>
          </div>
        </div>

        <div className="px-6 py-2 sm:px-10">
          <div>
            <dl className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
              {/* {console.log("Allconn", data.allConnections)} */}
              {/* <DataCard props={data.allConnections} /> */}
              {data.imports.map((usecase, k) => (
                <ImportDataCard
                  k={k}
                  usecase={usecase}
                  actions={[
                    {
                      label: "Edit",
                      action: (row, k) => {
                        _onClickEditImport(usecase, k);
                        //  console.log("ROW AT SIMPLETABLE", row);
                      },
                      style: "text-indigo-600 hover:text-indigo-900 mr-10",
                    },
                    {
                      label: "Manual Run",
                      action: (usecase, k) => {
                        _onClickManualRun(usecase, k);
                      },
                      style: "text-indigo-600 hover:text-indigo-900 mr-10",
                    },
                    {
                      label: "Remove Import",
                      action: (usecase, k) => {
                        _onClickRemoveImport(usecase, k);
                      },
                      style: "text-indigo-600 hover:text-indigo-900 mr-10",
                    },
                    {
                      label: "Upload a File",
                      action: {},
                      style: "text-indigo-600 hover:text-indigo-900 mr-10",
                    },
                    {
                      label:
                        usecase.Status?.value === "InActive"
                          ? "Activate"
                          : "De-Activate",
                      action: (usecase, k) => {
                        _onUserStatusChange(usecase, k);
                      },
                      // (e, import_data) => {
                      //   onFileUpload(e, import_data);
                      //   //  console.log("Kvalue", k);

                      // },
                      style: "text-indigo-600 hover:text-indigo-900 mr-10",
                    },
                  ]}
                  defaultSort={false}
                  tableCSS={{ headerColor: "#E2EAE3", color: "black" }}
                  onUserStatusChange={(e, k) => {
                    _onUserStatusChange(e, k);
                    //  console.log("Kvalue", k);
                  }}
                  onUploadFile={(e, k) => {
                    onFileUpload(e, k);
                    //  console.log("Kvalue", k);
                  }}
                // paginationLimit="30"
                />
              ))}

            </dl>
          </div>
        </div>

      </main>
    </Fragment>
  );
};

export default ImportsPage;

/**
 *
 * 2. network id, report id integration, and bq_credential update from connection id in imports creation
 * 5. Error handling in both frontend and backend
 * 6. Authorization backend
 *
 */
