import "./App.css";
import "rsuite/dist/rsuite.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store/store";
import { Routes } from "./Routes";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {Routes.map((route, k) => (
            <Route path={route.path} exact={route.exact} key={k}>
              {route.component}
            </Route>
          ))}
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
