import React, { useState, useEffect } from "react";
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiOutlineLoading3Quarters,
} from "react-icons/ai";
import Input from "../../../../components/Inputs/Input";
import Modal from "../../../../components/Modal";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import ToolTipText from "../../../../components/Tooltips";
import { BASE_URL, TENANT_ID } from "../../../../utils/variables";
import { IoIosCloseCircle, IoMdAlert } from "react-icons/io";

const ReIssuePasswordModal = ({ def, reIssuePasswordRef }) => {
    // console.log("hussain", def)
    const [inputs, setInputs] = useState({
        connection_type: def.connection_type,
        connection_id: def.connection_id,
        password: "",
        access_key_id: "",
        secret_access_key: "",
    });

    // def.onLoadSetConnectionType(def.connection_type);

    const [inputError, setInputError] = useState({
        secretAccessKeyError: "",
        accessKeyIdError: "",
        passwordError: "",
        service_accountError: "",
    });

    const [toast, setToast] = useState({
        type: false,
        message: "",
    });

    const formValidation = () => {
        let isValid = true;

        if (def.connection_type.toUpperCase() === "TAM") {
            if (!def.service_account) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        service_accountError: "File invalid or missing",
                    };
                });
                isValid = false;
            }
            if (!inputs.access_key_id) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        accessKeyIdError: "Access Key ID invalid or missing",
                    };
                });
                isValid = false;
            }
            if (!inputs.secret_access_key) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        secretAccessKeyError: "Secret Access Key invalid or missing",
                    };
                });
                isValid = false;
            }
        } else if (def.connection_type.toUpperCase() === "GAM") {


            if (inputs.password?.length < 6 || !inputs.password) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        passwordError: "password invalid or missing",
                    };
                });
                isValid = false;
            }
        }
        return isValid;
    };
    const [state, setState] = useState({
        loading: false,
    });
    const [showPassword, setShowPassword] = useState(true);

    const handleChange = (e) => {
        if (e.target.name !== "connection_type") {
            setInputs({ ...inputs, [e.target.name]: e.target.value });
        }
        setInputError({
            secretAccessKeyError: "",
            accessKeyIdError: "",
            passwordError: "",
            service_accountError: "",
        });
    };

    const handleReIssuePassword = async () => {

        let isFormValid = formValidation();
        if (isFormValid) {
            // console.log("_theseareinputs", inputs)
            // console.log("This is def", def)
            setState({ ...state, loading: true });
            let k = def.connection_type === "TAM" ? {
                connection_type: def.connection_type,
                connection_id: def.connection_id,
                access_key_id: inputs.access_key_id,
                secret_access_key: inputs.secret_access_key,
            } : {
                connection_type: def.connection_type,
                connection_id: def.connection_id,
                password: inputs.password,
            }
            //  console.log("hussain", k)

            try {
                const response = await fetch(
                    `${BASE_URL}connection/issue-password/${TENANT_ID}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                        body: JSON.stringify(k)
                        // body: JSON.stringify({ ...inputs, connection_type: parseInt(def.connection_type) }),


                    }
                );
                const responseData = await response.json();
                setState({ ...state, loading: false });
                if (responseData.error) {
                    setToast({ type: true, message: responseData.error });
                } else {
                    setToast({ type: false, message: responseData.response });
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 2000);
                }
            } catch (error) {
                setState({
                    ...state,
                    loading: false,
                });
                setToast({ type: true, message: "Something went wrong" });
            }
        }
    };



    // useEffect(() => {
    //     //console.log("storage", localStorage.getItem("isSuperAdmin"));
    //     setInputs({ ...def });
    // }, [def]);


    return (
        <Modal ref={reIssuePasswordRef}>
            {/* <div className="p-4 sm:p-6"> */}
            <div className="">
                <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
                    <h2 className="text-base text-ai-primary-dark  font-bold ">
                        Issue Password
                    </h2>
                    <IoIosCloseCircle
                        className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
                        onClick={() => {
                            reIssuePasswordRef.current.closeModal();
                        }}
                    />
                </div>
                <div className="mt-0 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="mt-0 md:mt-0 md:col-span-3">
                            {/* <div> */}
                            <div className="overflow-hidden sm:rounded-md">
                                <div className="px-4 py-2 bg-white sm:p-2">
                                    <div className="grid grid-cols-6 gap-2">
                                        <div className="col-span-6 ">
                                            <label
                                                htmlFor="connection_type"
                                                className={def.connection_type.toUpperCase() !== "EMAIL" && def.connection_type.toUpperCase() !== "TAM" ?
                                                    "invisible" : "block text-sm font-bold text-gray-700"}

                                            >
                                                Connection Type: {def.connection_type.toUpperCase()}
                                            </label>
                                        </div>
                                        {def.connection_type.toUpperCase() === "EMAIL" ? (
                                            <>
                                                <div className="pt-2 col-span-4 sm:col-span-3">
                                                    <div className="flex">
                                                        <label
                                                            htmlFor="password"
                                                            className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                                                        >
                                                            Password
                                                        </label>
                                                        <ToolTipText
                                                            text={
                                                                "App password associated with the respective email (found under 2 step verification under google account settings)"
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex justify-center items-center	">
                                                        <Input
                                                            type={showPassword ? "password" : "text"}
                                                            name="password"
                                                            id="password"
                                                            onChange={handleChange}
                                                            value={inputs.password}
                                                            noMargin={true}
                                                        />
                                                        <span>
                                                            {showPassword ? (
                                                                <AiFillEye
                                                                    className="ml-1"
                                                                    onClick={() => {
                                                                        setShowPassword(!showPassword);
                                                                    }}
                                                                    size={30}
                                                                />
                                                            ) : (
                                                                <AiFillEyeInvisible
                                                                    className="ml-1"
                                                                    onClick={() => {
                                                                        setShowPassword(!showPassword);
                                                                    }}
                                                                    size={30}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                    <span className="text-red-600">
                                                        {inputError.passwordError}
                                                    </span>
                                                </div></>
                                        )
                                            : def.connection_type.toUpperCase() === "TAM" ? (
                                                <>
                                                    <div className="pt-2 col-span-4 sm:col-span-3">
                                                        <div className="flex">
                                                            <label
                                                                htmlFor="access_key_id"
                                                                className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                                                            >
                                                                Access Key ID
                                                            </label>
                                                            <ToolTipText
                                                                text={
                                                                    "Provide Access Key ID of AWS"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="flex">
                                                            <Input
                                                                type={showPassword ? "password" : "text"}
                                                                name="access_key_id"
                                                                id="access_key_id"
                                                                onChange={handleChange}
                                                                value={inputs.access_key_id}
                                                                noMargin={true}
                                                            />

                                                        </div>
                                                        <span className="text-red-600">
                                                            {inputError.accessKeyIdError}
                                                        </span>
                                                    </div>
                                                    <div className="pt-2 col-span-4 sm:col-span-3">
                                                        <div className="flex">
                                                            <label
                                                                htmlFor="secret_access_key"
                                                                className="flex text-sm font-semibold text-ai-primary-dark ml-1 mt-1"
                                                            >
                                                                Secret Access Key
                                                            </label>
                                                            <ToolTipText
                                                                text={
                                                                    "Provide Secret Access Key of AWS"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="flex justify-center items-center	">
                                                            <Input
                                                                type={showPassword ? "password" : "text"}
                                                                name="secret_access_key"
                                                                id="secret_access_key"
                                                                onChange={handleChange}
                                                                value={inputs.secret_access_key}
                                                                noMargin={true}
                                                            />
                                                            <span>
                                                                {showPassword ? (
                                                                    <AiFillEye
                                                                        className="ml-1"
                                                                        onClick={() => {
                                                                            setShowPassword(!showPassword);
                                                                        }}
                                                                        size={30}
                                                                    />
                                                                ) : (
                                                                    <AiFillEyeInvisible
                                                                        className="ml-1"
                                                                        onClick={() => {
                                                                            setShowPassword(!showPassword);
                                                                        }}
                                                                        size={30}
                                                                    />
                                                                )}
                                                            </span>
                                                        </div>
                                                        <span className="text-red-600">
                                                            {inputError.secretAccessKeyError}
                                                        </span>
                                                    </div>
                                                </>
                                            )
                                                : (
                                                    <>
                                                        <h6 className="col-span-6 my-auto text-center align-middle text-base text-ai-primary-dark  font-bold">{`This Connection Type (${def.connection_type.toUpperCase()}) does not have a Password!`}</h6>
                                                        <p className="invisible">{setTimeout(() => {
                                                            reIssuePasswordRef.current?.closeModal()
                                                        }, 3000)}</p>
                                                    </>
                                                )}
                                    </div>

                                    <div className={def.connection_type.toUpperCase() !== "EMAIL" && def.connection_type.toUpperCase() !== "TAM" ?
                                        "invisible" : "mt-4 ml-2 mb-2 sm:mt-2 sm:grid sm:grid-cols-6 sm:gap-2 sm:grid-flow-row-dense"}>

                                        <button
                                            type="button"
                                            onClick={() => handleReIssuePassword()}
                                            className="w-full col-span-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover text-base font-medium text-white sm:col-start-1 sm:text-sm"
                                        >
                                            {state.loading ? (
                                                <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                                                    <AiOutlineLoading3Quarters
                                                        className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                                                        size={40}
                                                    />
                                                </div>
                                            ) : (
                                                "Create"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {toast.message && (
                <ToastMsg
                    msg={toast.message}
                    isError={toast.type}
                    setToast={setToast}
                />
            )}


            {/* </div> */}
        </Modal>
    );
};

export default ReIssuePasswordModal;
