//=====================GLOBAL FUNCTIONS=========================//

import { getUserAccessToken } from "../managers/UserLocalManger";

/**
 * API Call - POST Method
 * with bodyParams: {"key":"value"}
 */
export async function apiCallPost(url, bodyParams, onSuccess, onFailure) {
  const user_accesstoken = await getUserAccessToken();

  try {
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: user_accesstoken,
      },

      body: JSON.stringify(bodyParams),
    });
    const data = await response.json();
    onSuccess(data);
  } catch (error) {
    onFailure(error);
  }
}

/**
 * API Call - GET Method
 */
export async function apiCallGet(url, onSuccess, onFailure) {
  const user_accesstoken = await getUserAccessToken();

  try {
    const response = await fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: user_accesstoken,
      },
    });
    const data = await response.json();
    onSuccess(data);
  } catch (error) {
    onFailure(error);
  }
}
