import React, { useState } from "react";
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiOutlineLoading3Quarters,
} from "react-icons/ai";
import Input from "../../../../components/Inputs/Input";
import Modal from "../../../../components/Modal";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import ToolTipText from "../../../../components/Tooltips";
import { BASE_URL, TENANT_ID } from "../../../../utils/variables";
import { IoIosCloseCircle } from "react-icons/io";
import { apiCallPost } from "../../../../api/RestAPICalls"
import { useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';



const CreateConnectionPage = () => {
    let history = useHistory();
    // We are getting data that we passed from _openEditConnection in connections page
    const location = useLocation();
    const data = location.state?.connPageData;
    // console.log("YAYYYYYYYYYYYYYYYYYYYY", JSON.stringify(data));

    // const [width, setWidth] = useState(window.innerWidth);

    // function handleWindowSizeChange() {
    //     setWidth(window.innerWidth);
    // }
    // useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     }
    // }, []);

    // const isMobile = width <= 768;

    const editCheck = (window.location.pathname).split("/");

    const [state, setState] = useState({
        uploadError: false,
        loading: false,
        isEdit: editCheck[3] === "edit" ? true : false,
    });
    //  console.log(state.isEdit);

    const [inputs, setInputs] = useState(state.isEdit ?
        {
            connectionType: window.location.pathname.split("/").pop(),
            serviceAccount: data?.service_account,
            networkID: data?.network_id,
            connectionName: data?.connection_name,
            email: data?.email,
            password: "",
            access_key_id: "",
            secret_access_key: "",
            project_name: data?.project_name,
        } :
        {
            connectionType: window.location.pathname.split("/").pop(),
            serviceAccount: "",
            networkID: "",
            connectionName: "",
            email: "",
            password: "",
            access_key_id: "",
            secret_access_key: "",
            project_name: "",
        });
    const [inputError, setInputError] = useState({
        connectionTypeError: "",
        serviceAccountError: "",
        networkIDError: "",
        connectionNameError: "",
        emailError: "",
        passwordError: "",
        accessKeyIdError: "",
        secretAccessKeyError: "",
        projectNameError: "",
    });



    const [toast, setToast] = useState({
        type: false,
        message: "",
    });

    const handleChange = (e) => {
        if (e.target.name === "connectionType") {
            setInputs({
                ...inputs,
                connectionName: "",
                [e.target.name]: e.target.value,
            });
        } else {
            setInputs({
                ...inputs,
                [e.target.name]: e.target.value,
            });
        }

        setInputError({
            connectionTypeError: "",
            serviceAccountError: "",
            networkIDError: "",
            connectionNameError: "",
            emailError: "",
            passwordError: "",
        });
    };
    const _onFileUploadCreateConnection = async (e) => {
        //    -----------------------  NEW CONNECTION CREATION
        try {
            let file = e.target.files[0];
            let formData = new FormData();
            formData.append("file", file);
            formData.append("type", "connection");
            formData.append("connection_id", "1");

            const response = await fetch(`${BASE_URL}upload/${TENANT_ID}`, {
                method: "POST",
                body: formData,
            });
            const responseData = await response.json();
            if (responseData.error) {
                setState({ ...state, uploadError: "Upload Unsuccessfull." });
                // setToast({
                //     type: false,
                //     message: "Upload Unsuccessfull.",
                // });


            } else {
                setInputs({ ...inputs, serviceAccount: responseData.path });
                setToast({
                    type: false,
                    message: `${responseData.path} file Upload successfully`,
                });
                setInputError({
                    ...inputError,
                    serviceAccountError: "",
                });
            }
            //{"error":"Something went wrong. Please try again."}

        } catch (error) {
            setState({ ...state, uploadError: "Upload Unsuccessfull." });
            setToast({
                type: false,
                message: "Upload Unsuccessfull.",
            });
        }
    };
    const [showPassword, setShowPassword] = useState(true);
    const formValidation = () => {
        let isValid = true;
        let networkIdNumber = Number(inputs.networkID)
        if (inputs.connectionType === "GAM") {
            if (!inputs.serviceAccount) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        serviceAccountError: "File invalid or missing",
                    };
                });
                isValid = false;
            }
            if (!inputs.networkID) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        networkIDError: "Network Id invalid or missing",
                    };
                });
                isValid = false;
            }
            if (isNaN(networkIdNumber)) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        networkIDError: "Network Id should be a Number",
                    };
                });
                isValid = false;
            }
            if (!inputs.connectionName) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        connectionNameError: "Connection invalid or missing",
                    };
                });
                isValid = false;
            }
        } else if (inputs.connectionType === "email") {
            if (!inputs.serviceAccount) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        serviceAccountError: "File invalid or missing",
                    };
                });
                isValid = false;
            }
            if (!inputs.connectionName) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        connectionNameError: "Connection invalid or missing",
                    };
                });
                isValid = false;
            }
            if (!inputs.project_name) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        projectNameError: "Project Name invalid or missing",
                    };
                });
                isValid = false;
            }
            if (!inputs.email || !/\S@\S+\.\S+/.test(inputs.email)) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        emailError: "Email invalid or missing",
                    };
                });
                isValid = false;
            }
            // Password validation below will only happen if isEdit is false
            if (!state.isEdit) {
                if (inputs.password?.length < 6) {
                    setInputError((prevError) => {
                        return {
                            ...prevError,
                            passwordError: "Password invalid or missing",
                        };
                    });
                    isValid = false;
                }
            }
        } else if (inputs.connectionType === "TAM") {
            //The AccessKey and SecretAccessKey inputs will only be validated if isEdit is false as we dont take those inputs in edit
            if (!state.isEdit) {
                if (!inputs.access_key_id) {
                    setInputError((prevError) => {
                        return {
                            ...prevError,
                            accessKeyIdError: "Access Key ID invalid or missing",
                        };
                    });
                    isValid = false;
                }
                if (!inputs.secret_access_key) {
                    setInputError((prevError) => {
                        return {
                            ...prevError,
                            secretAccessKeyError: "Secret Access Key invalid or missing",
                        };
                    });
                    isValid = false;
                }
            }
            if (!inputs.serviceAccount) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        serviceAccountError: "File invalid or missing",
                    };
                });
                isValid = false;
            }
            if (!inputs.serviceAccount) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        serviceAccountError: "File invalid or missing",
                    };
                });
                isValid = false;
            }
            if (!inputs.connectionName) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        connectionNameError: "Connection invalid or missing",
                    };
                });
                isValid = false;
            }
            if (!inputs.project_name) {
                setInputError((prevError) => {
                    return {
                        ...prevError,
                        projectNameError: "Project Name invalid or missing",
                    };
                });
                isValid = false;
            }
        }
        return isValid;
    };
    const onCreateConnection = async () => {
        let isFormValid = formValidation()
        // true;
        // formValidation()
        //API CALL FOR CREATE CONNECTION
        if (isFormValid && state.isEdit !== true) {
            setState({ ...state, loading: true });
            try {
                // let sendConnectionData = `${BASE_URL}connection/create/${TENANT_ID}`;

                let bodyParams = {

                    network_id: inputs.networkID,
                    service_account: inputs.serviceAccount,
                    email: inputs.email,
                    connection_name: inputs.connectionName,
                    connection_type: inputs.connectionType,
                    password: inputs.password,
                    tenant: localStorage.selected_tenant_id,
                    project_name: inputs.project_name,
                    tam_credentials: inputs.connectionType === "TAM" ? {
                        secret_access_key: inputs.secret_access_key,
                        access_key_id: inputs.access_key_id
                    } : ""

                }
                //Added old way of calling api here as the new way was showing a success message though we got a 500 error
                // await apiCallPost(sendConnectionData, bodyParams, onSuccessTenantSendinfo, onFailureTenantSendinfo);
                const response = await fetch(
                    `${BASE_URL}connection/create/${TENANT_ID}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                        body: JSON.stringify(bodyParams)
                        //JSON.stringify(inputs)
                        // body: JSON.stringify({ ...inputs, connection: parseInt(inputs.connection_id) }),

                    }
                );
                const responseData = await response.json();
                if (responseData.error) {
                    setToast({ type: true, message: responseData.error });
                    setState({
                        ...state,
                        loading: false,
                    });

                }
                else {
                    setToast({ type: false, message: "Sucessfully created a new Connection!" });
                    setState({
                        ...state,
                        loading: false,
                    });
                    setTimeout(() => {
                        //UNCOMMENT THIS TO REDIRECT TO CONNECTION AFTER CREATION
                        history.push(
                            `/dashboard/connections`)
                        window.location.reload(true);
                    }, 3000);
                }
            }
            catch {
                setState({
                    ...state,
                    loading: false,
                });
                setToast({
                    type: true,
                    message: "Something went wrong",
                });
            }

        }
        //API CALL FOR EDIT CONNECTION
        else if (isFormValid && state.isEdit === true) {
            const editBody = {
                connection_name: inputs.connectionName,
                connection_type: inputs.connectionType,
                service_account: inputs.serviceAccount,
                project_name: inputs.project_name,
                connection_id: data.connection_id,


                network_id: data.network_id,
                created_by: data.created_by,
                created_at: data.created_at,
                updated_at: data.updated_at,
                email: data.email,


                old_owners: data.old_owners,
                account: data.account,
                tenant: data.tenant,
                total_imports: data.total_imports,

            }
            setState({ ...state, loading: true });

            try {
                const response = await fetch(
                    `${BASE_URL}connection/edit/${TENANT_ID}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                        body: JSON.stringify(editBody)
                        //JSON.stringify(inputs)
                        // body: JSON.stringify({ ...inputs, connection: parseInt(inputs.connection_id) }),

                    }
                );
                const responseData = await response.json();
                setState({ ...state, loading: false });
                if (responseData.error) {
                    setToast({ type: true, message: responseData.error });
                } else {
                    setToast({ type: false, message: responseData.response });
                    setState({
                        ...state,
                        loading: false,
                    });
                    setTimeout(() => {
                        history.push(
                            `/dashboard/connections`)
                        window.location.reload(true);
                    }, 3000);
                }
            } catch (error) {
                setState({
                    ...state,
                    loading: false,
                });
                setToast({ type: true, message: "Something went wrong" });
            }
        }
    }

    const onSuccessTenantSendinfo = () => {
        setState({
            ...state,
            loading: false,
        });
        setToast({
            type: false,
            message: "Connection Created successfully",
        });
        setTimeout(() => {
            history.push(
                `/dashboard/connections`)
        }, 2000);
    }
    const onFailureTenantSendinfo = (response) => {
        setState({
            ...state,
            loading: false,
        });
        setToast({
            type: true,
            message: response,
        });
        return;
    }
    const renderBody = () => {
        if (inputs.connectionType === 'GAM') {
            return <>
                {/* CODE FOR SERVICE ACCOUNT */}
                <div className="col-span-6 pt-2">
                    <div className="flex">
                        <div
                            htmlFor="serviceAccount"
                            className="flex text-sm font-semibold text-ai-primary-dark"
                        >
                            Service Account Upload
                        </div>
                        <ToolTipText
                            text={
                                "Upload the service account json which is addded as a user in respective GAM account"
                            }
                        />
                    </div>
                    <label
                        htmlFor="serviceAccount"
                        className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                    >
                        <div className="space-y-1 text-center">
                            <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                            >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                                <label
                                    htmlFor="serviceAccount"
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                                >
                                    <span className="mx-auto text-center">
                                        Upload a file
                                    </span>
                                    <input
                                        name="serviceAccount"
                                        id="serviceAccount"
                                        type="file"
                                        className="sr-only"
                                        accept=".json"
                                        onChange={_onFileUploadCreateConnection}
                                    />
                                </label>
                            </div>
                            <p className="text-xs text-gray-500">
                                JSON File
                            </p>
                        </div>
                    </label>
                    <span className="text-red-600">
                        {inputError.serviceAccountError}
                    </span>
                </div>

                <div className="col-span-6">
                    {inputs.serviceAccount}
                </div>

                {state.uploadError && (
                    <div className="col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                {/* <!-- Heroicon name: solid/exclamation --> */}
                                <svg
                                    className="h-5 w-5 text-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                    {state.uploadError}
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {/* CODE FOR CONNECTION NAME */}
                <div className="col-span-6 pt-4 sm:col-span-3">
                    <div className="flex">
                        <label
                            htmlFor="connectionName"
                            className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                        >
                            Connection Name
                        </label>

                        <ToolTipText
                            text={
                                "User preference Name of the connection"
                            }
                        />
                    </div>

                    <Input
                        type="text"
                        name="connectionName"
                        id="connectionName"
                        onChange={handleChange}
                        value={inputs.connectionName}
                        placeholder="Connection Name"
                        noMargin={true}
                    />
                    <span className="text-red-600">
                        {inputError.connectionNameError}
                    </span>
                </div>

                {/* CODE FOR PROJECT NAME */}
                <div className="col-span-6 pt-4 sm:col-span-3">
                    <div className="flex">
                        <label
                            htmlFor="project_name"
                            className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                        >
                            Project Name
                        </label>
                        <ToolTipText text={"BQ Project Name"} />
                    </div>
                    <Input
                        type="text"
                        name="project_name"
                        id="project_name"
                        onChange={handleChange}
                        value={inputs.project_name}
                        noMargin={true}
                        placeholder="Project Name"
                    />

                </div>
                {/* CODE FOR NETWORK ID */}
                {/* {console.log("projectname", inputs.project_name)} */}
                <div className="col-span-6 pt-4 sm:col-span-3">
                    <div className="flex">
                        <label
                            htmlFor="NetworkID"
                            className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                        >
                            Network ID
                        </label>
                        <ToolTipText
                            text={
                                "Provide the Network ID of the GAM account"
                            }
                        />
                    </div>

                    <Input
                        type="text"
                        name="networkID"
                        placeholder="Network ID"
                        id="networkID"
                        onChange={handleChange}
                        value={inputs.networkID}
                        className=" focus:ring-ai-primary-teal focus:border-ai-primary-teal block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        noMargin={true}
                    // Using noMargin to pass true to Input component that will set the top margin of the Input 
                    //  box to zero. If noMargin is not specified then my-3 is set.
                    />
                    <span className="text-red-600">
                        {inputError.networkIDError}
                    </span>
                </div>
            </>;
        } else if (inputs.connectionType === 'email' || inputs.connectionType === 'GA') {
            return <>
                <div className="col-span-6 pt-2">
                    <div className="flex">
                        <div
                            htmlFor="serviceAccount"
                            className="flex text-sm font-semibold text-ai-primary-dark"
                        >
                            Service Account Upload
                        </div>
                        <ToolTipText
                            text={
                                "Upload the service account json which is addded as a user in respective EMAIL account"
                            }
                        />
                    </div>
                    <label
                        htmlFor="serviceAccount"
                        className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                    >
                        <div className="space-y-1 text-center">
                            <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                            >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                                <label
                                    htmlFor="serviceAccount"
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                                >
                                    <span className="mx-auto text-center">
                                        Upload a file
                                    </span>
                                    <input
                                        name="serviceAccount"
                                        id="serviceAccount"
                                        type="file"
                                        className="sr-only"
                                        accept=".json"
                                        onChange={_onFileUploadCreateConnection}
                                    />
                                </label>
                            </div>
                            <p className="text-xs text-gray-500">
                                JSON File
                            </p>
                        </div>
                    </label>
                    <span className="text-red-600">
                        {inputError.serviceAccountError}
                    </span>
                </div>
                <div className="col-span-6">
                    {inputs.serviceAccount}
                </div>

                {state.uploadError && (
                    <div className="col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                {/* <!-- Heroicon name: solid/exclamation --> */}
                                <svg
                                    className="h-5 w-5 text-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                    {state.uploadError}
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {/* Email Component */}
                {!state.isEdit &&
                    <div className="col-span-6 pt-4 sm:col-span-3">
                        <div className="flex">
                            <label
                                htmlFor="email"
                                className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                            >
                                Email
                            </label>
                            <ToolTipText
                                text={
                                    "Email ID of the email where the reports are scheduled to"
                                }
                            />
                        </div>
                        <Input
                            type="text"
                            name="email"
                            id="email"
                            autoComplete={false}
                            onChange={handleChange}
                            value={inputs.email}
                            noMargin={true}
                            placeholder="Email"
                        />
                        <span className="text-red-600">
                            {inputError.emailError}
                        </span>
                    </div>}
                {!state.isEdit &&
                    <div className="col-span-6 pt-4 sm:col-span-3">
                        <div className="flex">
                            <label
                                htmlFor="password"
                                className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                            >
                                Password
                            </label>
                            <ToolTipText
                                text={
                                    "App password associated with the respective email (found under 2 step verification under google account settings)"
                                }
                            />
                        </div>
                        <div className="flex justify-center items-center">
                            <input
                                type={showPassword ? "password" : "text"}
                                name="password"
                                id="password"
                                onChange={handleChange}
                                value={inputs.password}
                                className="mt-1  focus:ring-ai-primary-teal focus:border-ai-primary-teal block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                noMargin={true}
                                placeholder="Password"
                            />
                            <span>
                                {showPassword ? (
                                    <AiFillEye
                                        className="ml-1"
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                        size={30}
                                    />
                                ) : (
                                    <AiFillEyeInvisible
                                        className="ml-1"
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                        size={30}
                                    />
                                )}
                            </span>
                        </div>
                        <span className="text-red-600">
                            {inputError.passwordError}
                        </span>
                    </div>}

                <div className="col-span-6 pt-4 sm:col-span-3">
                    <div className="flex">
                        <label
                            htmlFor="connectionName"
                            className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                        >
                            Connection Name
                        </label>

                        <ToolTipText
                            text={
                                "User preference Name of the connection"
                            }
                        />
                    </div>
                    <Input
                        type="text"
                        name="connectionName"
                        id="connectionName"
                        onChange={handleChange}
                        value={inputs.connectionName}
                        noMargin={true}
                        placeholder="Connection Name"
                    />
                    <span className="text-red-600">
                        {inputError.connectionNameError}
                    </span>
                </div>

                <div className="col-span-6 pt-4 pb-4 sm:col-span-3">
                    <div className="flex">
                        <label
                            htmlFor="project_name"
                            className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                        >
                            Project Name
                        </label>
                        <ToolTipText text={"BQ Project Name"} />
                    </div>
                    <input
                        type="text"
                        name="project_name"
                        id="project_name"
                        onChange={handleChange}
                        value={inputs.project_name}
                        className="mt-1  focus:ring-ai-primary-teal focus:border-ai-primary-teal block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        noMargin={true}
                        placeholder="Project Name"
                    />
                    <span className="text-red-600">
                        {inputError.projectNameError}
                    </span>
                </div>
            </>;
        } else if (inputs.connectionType === 'TAM') {
            return <>
                <div className="col-span-6 pt-2">
                    <div className="flex">
                        <div
                            htmlFor="serviceAccount"
                            className="flex text-sm font-semibold text-ai-primary-dark"
                        >
                            BQ Service Account Upload
                        </div>
                        <ToolTipText
                            text={
                                "Upload the BQ service account json which is addded as a user in respective TAM account"
                            }
                        />
                    </div>
                    <label
                        htmlFor="serviceAccount"
                        className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                    >
                        <div className="space-y-1 text-center">
                            <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                            >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                                <label
                                    htmlFor="serviceAccount"
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                                >
                                    <span className="mx-auto text-center">
                                        Upload a file
                                    </span>
                                    <input
                                        name="serviceAccount"
                                        id="serviceAccount"
                                        type="file"
                                        className="sr-only"
                                        accept=".json"
                                        onChange={_onFileUploadCreateConnection}
                                    />
                                </label>
                            </div>
                            <p className="text-xs text-gray-500">
                                JSON File
                            </p>
                        </div>
                    </label>
                    <span className="text-red-600">
                        {inputError.serviceAccountError}
                    </span>
                </div>

                <div className="col-span-6">
                    {inputs.serviceAccount}
                </div>

                {state.uploadError && (
                    <div className="col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                {/* <!-- Heroicon name: solid/exclamation --> */}
                                <svg
                                    className="h-5 w-5 text-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                    {state.uploadError}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {/* Access Key Id Input */}

                {!state.isEdit &&
                    <div className="col-span-6 pt-4 sm:col-span-3">
                        <div className="flex">
                            <label
                                htmlFor="access_key_id"
                                className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                            >
                                Access Key ID
                            </label>
                            <ToolTipText
                                text={
                                    "Enter the Access Key ID"
                                }
                            />
                        </div>
                        <div className="flex justify-center items-center	">
                            <input
                                type={showPassword ? "password" : "text"}
                                name="access_key_id"
                                id="access_key_id"
                                onChange={handleChange}
                                value={inputs.access_key_id}
                                className="mt-1  focus:ring-ai-primary-teal focus:border-ai-primary-teal block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                noMargin={true}
                                placeholder="Access Key ID"
                            />
                            {/* <span>
                  {showPassword ? (
                    <AiFillEye
                      className="ml-1"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      size={30}
                    />
                  ) : (
                    <AiFillEyeInvisible
                      className="ml-1"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      size={30}
                    />
                  )}
                </span> */}
                        </div>
                        <span className="text-red-600">
                            {inputError.accessKeyIdError}
                        </span>
                    </div>}
                {/* Secret Access key Input */}
                {!state.isEdit &&
                    <div className="col-span-6 pt-4 sm:col-span-3">
                        <div className="flex">
                            <label
                                htmlFor="secret_access_key"
                                className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                            >
                                Secret Access Key
                            </label>
                            <ToolTipText
                                text={
                                    "Enter the Secret Access Key"
                                }
                            />
                        </div>
                        <div className="flex justify-center items-center	">
                            <input
                                type={showPassword ? "password" : "text"}
                                name="secret_access_key"
                                id="secret_access_key"
                                onChange={handleChange}
                                value={inputs.secret_access_key}
                                className="mt-1  focus:ring-ai-primary-teal focus:border-ai-primary-teal block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                noMargin={true}
                                placeholder="Secret Access Key"
                            />
                            <span>
                                {showPassword ? (
                                    <AiFillEye
                                        className="ml-1"
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                        size={30}
                                    />
                                ) : (
                                    <AiFillEyeInvisible
                                        className="ml-1"
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                        size={30}
                                    />
                                )}
                            </span>
                        </div>
                        <span className="text-red-600">
                            {inputError.secretAccessKeyError}
                        </span>
                    </div>}
                {/* Connection Name */}
                <div className="col-span-6 pt-4 sm:col-span-3">
                    <div className="flex">
                        <label
                            htmlFor="connectionName"
                            className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                        >
                            Connection Name
                        </label>

                        <ToolTipText
                            text={
                                "User preference Name of the connection"
                            }
                        />
                    </div>

                    <Input
                        type="text"
                        name="connectionName"
                        id="connectionName"
                        onChange={handleChange}
                        value={inputs.connectionName}
                        placeholder="Connection Name"
                        noMargin={true}
                    />
                    <span className="text-red-600">
                        {inputError.connectionNameError}
                    </span>
                </div>
                {/* Project Name */}
                <div className="col-span-6 pt-4 sm:col-span-3">
                    <div className="flex">
                        <label
                            htmlFor="project_name"
                            className="flex text-sm font-semibold text-ai-primary-dark mt-1"
                        >
                            Project Name
                        </label>
                        <ToolTipText text={"BQ Project Name"} />
                    </div>
                    <input
                        type="text"
                        name="project_name"
                        id="project_name"
                        onChange={handleChange}
                        value={inputs.project_name}
                        className="mt-1  focus:ring-ai-primary-teal focus:border-ai-primary-teal block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        noMargin={true}
                        placeholder="Project Name"
                    />
                    <span className="text-red-600">
                        {inputError.projectNameError}
                    </span>
                </div>


            </>;
        } else {
            return <h1>{inputs.connectionType}</h1>;
        }
    }
    // console.log("TheseAreInputs", inputs);

    return (
        <main className="" id="home">
            {/* Header Part */}
            <div className="mt-5 ml-3 md:px-5 flex justify-between max-w-full">
                <div>
                    <h2 className="text-2xl sm:text-3xl text-ai-primary-text-dark font-semibold">
                        {/* {}Create Connection */}
                        {state.isEdit ? "Edit Connection" : "Create Connection"}
                    </h2>
                    <span className="ml-1 text-lg text-ai-primary-text-teal font-semibold">
                        {inputs.connectionType.toUpperCase()}
                    </span>
                </div>
            </div>


            <div className="flex flex-col my-6 mx-7 bg-white">
                <div className="mt-4 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-3">
                        <div className="mt-0 md:mt-0 md:col-span-3">
                            <div>
                                <div className="shadow sm:rounded-md">
                                    <div className="px-6 py-2 mx-8 bg-white sm:p-3">
                                        <div className="py-4 grid grid-cols-6 mx-2 gap-3">
                                            {/* We are rendering the form body here */}
                                            {renderBody()}
                                            {/* {isMobile ? "NOPE" : renderBody()} */}
                                        </div>
                                        {toast.message && (
                                            <ToastMsg
                                                msg={toast.message}
                                                isError={toast.type}
                                                setToast={setToast}
                                            />
                                        )}
                                        <div className="mt-2 sm:mt-2 px-9 py-2 flex justify-end gap-4">
                                            <button
                                                className="w-32 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-button-bg hover:bg-ai-primary-gray text-base font-medium text-ai-primary-text-dark sm:text-sm text-right"
                                                onClick={() => history.push(
                                                    `/dashboard/connections`)}
                                            >Cancel</button>
                                            <button
                                                type="button"
                                                onClick={() => onCreateConnection()}
                                                className="w-32 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover text-base font-medium text-white sm:text-sm text-right"
                                            >
                                                {state.loading ? (
                                                    <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                                                        <AiOutlineLoading3Quarters
                                                            className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                                                            size={40}
                                                        />
                                                    </div>
                                                ) : state.isEdit ? (
                                                    "Edit"
                                                ) : (
                                                    "Create"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>)
}
export default CreateConnectionPage;