import React, { useState } from "react";

function DropdownSelect({ label, name, value, values, onChange, tooltip }) {
  const [selected, setSelected] = useState(value);
  const [open, toggleOpen] = useState(false);
  // console.log("newtest", values)
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={() => {
            toggleOpen(!open);
          }}
          onBlur={() => {
            setTimeout(() => {
              toggleOpen(false);
            }, 300);
          }}
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-ai-primary-teal focus:ring-ai-primary-teal"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          {selected}
          {/* <!-- Heroicon name: solid/chevron-down --> */}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {open && (
        <div
          className="overflow-y-auto origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 sm:w-32 md:w-56"
          //"origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
          //
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
          style={{ maxHeight: "80vh" }}
        >
          <div className="py-1" role="none">
            {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
            {values.map((value, k) => (
              <div
                key={k}
                className={
                  value.label === selected
                    ? "text-white bg-ai-primary-teal block px-4 cursor-pointer py-2 text-sm z-50"
                    : "text-gray-700 block px-4 py-2 text-sm cursor-pointer z-50"
                }
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
                onClick={() => {
                  setSelected(value.label);
                  onChange(value.value);
                  toggleOpen(false);
                }}
              >
                <p title={tooltip ? value.email : ""}>{value.label}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownSelect;
