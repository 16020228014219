const ProductSpecification = () => {
  return (
    <div className="p-4 sm:p-10">
      <p className="text-ai-primary-text-dark text-sm font-semibold tracking-wide">
        The ingestion solution provides a quick and easy way to update your
        daily data. With an intuitive user interface, you can quickly build the
        connections and imports you need, and it has a dashboard that makes it
        easy to keep an eye on any potential problems with data push.
      </p>

      <div className="mt-6">
        <p className="text-ai-primary-text-dark text-sm font-semibold tracking-wide">
          Additionally, it provides a number of features that enable you to:
        </p>
        <ul className="ml-2">
          {[
            "Manually execute your imports for testing or other pressing needs",
            "If there is a need for backfilling data, manually upload a file (Beta)",
          ].map((item, i) => {
            return (
              <li className="py-1 text-ai-primary-text-dark text-sm " key={i}>
                <span className="inline-flex w-4 h-4 rounded-full justify-center items-center bg-[#9396A8] text-white text-xs mr-1">
                  {String.fromCharCode(i + 65).toLowerCase()}
                </span>{" "}
                {item}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="mt-4">
        <p className="text-ai-primary-text-dark text-sm font-semibold tracking-wide">
          The tool may presently retrieve information from two key sources:
        </p>
        <ul className="ml-2">
          {[
            "Importing from an email's attachment or link",
            " Using the API to import data straight from Admanager (Other APIs in Pipeline)",
          ].map((item, i) => {
            return (
              <li className="py-1 text-ai-primary-text-dark text-sm " key={i}>
                <span className="inline-flex w-4 h-4 rounded-full justify-center items-center bg-[#9396A8] text-white text-xs mr-1">
                  {String.fromCharCode(i + 65).toLowerCase()}
                </span>{" "}
                {item}
              </li>
            );
          })}
        </ul>
      </div>

      <p className="text-ai-primary-text-dark text-sm font-semibold mt-6 tracking-wide">
        It is mostly possible to push this data into a bigquery or a Google
        sheet. It allows for two different data push methods: replace, where all
        of the old data is totally replaced by the new push.
      </p>
      <p className="text-ai-primary-text-dark text-sm font-semibold mt-4 tracking-wide">
        Or append, where the new data is added to the previous data while
        simultaneously deleting any duplicates that may have been in the earlier
        push. This function aids in minimizing query expenses and a user's
        efforts.
      </p>
    </div>
  );
};
export default ProductSpecification;
