import React, { useState } from 'react';

const CustomSelect = ({ label,
    name,
    value,
    values,
    onChange,
    disabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value === undefined ? values[0] : value);
    const [customText, setCustomText] = useState('');
    // console.log("Values", selectedOption);


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const handleInputChange = (e) => {
        setCustomText(e.target.value);
        onChange(e.target.value);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleOptionClick(customText);
        }
    };

    return (
        <div className="relative shadow-sm ">
            <div
                className="border border-gray-300 rounded-md p-2 cursor-pointer "
                onClick={toggleDropdown}
            >
                <div className="flex justify-between items-center ">
                    <span>{selectedOption || 'Select an option'}</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className={`w-4 h-4 transition-transform ${isOpen ? 'transform rotate-180' : ''
                            }`}
                    >
                        <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                </div>

            </div>
            {isOpen && (
                <div className="absolute z-10 w-full mt-2 py-2 bg-white rounded-md shadow-lg">
                    {values?.map((option) => (
                        <div
                            key={option}
                            name={name}
                            className="cursor-pointer hover:bg-green-100 p-2"
                            onClick={(e) => {
                                setSelectedOption(option);
                                onChange(option);
                                setIsOpen(false);
                            }}
                            // onChange={}
                            value={selectedOption}
                        >
                            {option}
                        </div>
                    ))}
                    <div className="p-2">
                        <input
                            type="text"
                            className="w-full border focus:border-green-400 focus:outline-none focus:ring-1 focus:ring-sa-secondary rounded-md px-2 py-1"
                            value={customText}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
