import React from "react";
import ToolTipText from "../../Tooltips";
import DropdownSelect from "../Select";

const Input = ({
  type,
  name,
  label,
  values,
  value,
  required,
  defaultValue,
  placeholder,
  onChange,
  isTooltip,
  ToolTipDescription,
  disabled,
  error,
  noMargin,
  maxlength
}) => {
  if (type === "text") {
    return (
      <div className={(noMargin === true ? "" : "my-3")}>
        {/* my-3 */}
        <div className="flex">
          <label
            htmlFor={name}
            className="flex text-sm font-semibold text-ai-primary-dark"
          //Was flex text-sm font-medium text-ai-primary-dark
          >
            {label}
          </label>
          {isTooltip && <ToolTipText text={ToolTipDescription} />}
        </div>

        <div className="mt-1">
          <input
            type={type}
            name={name}
            id={name}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            autoComplete="off"
            // autocomplete={name}
            maxlength={maxlength}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            className={`shadow-sm  placeholder-ai-primary-dark placeholder-opacity-50 focus:border-green-400 focus:outline-none focus:ring-1 focus:ring-sa-secondary block w-full sm:text-sm border-gray-300 rounded-md ${error && "border-ai-secondary-red"
              }`}
          />
          {error && (
            <span className="ml-1 mt-1  tracking-wider text-xs text-red-600 ">
              {error}
            </span>
          )}
        </div>
      </div>
    );
  } else if (type === "dropdown") {
    return (
      <div className="my-2">
        <DropdownSelect
          onChange={onChange}
          label={label}
          name={name}
          values={values}
          value={value}
          isTooltip={isTooltip}
          ToolTipDescription={ToolTipDescription}
          disabled={disabled}
        />
        {console.log("VALUE DRPDOWN", value)}
      </div>
    );
  } else if (type === "date") {
    return (
      <div className="my-2">
        <label
          htmlFor={name}
          className=" flex text-sm font-semibold text-ai-primary-dark"
        >
          {label}
        </label>
        <div className="mt-1">
          <input
            type={type}
            name={name}
            id={name}
            onChange={onChange}
            value={value}
            required={required}
            defaultValue={defaultValue}
            autocomplete={name}
            className="shadow-sm focus:ring-ai-primary-teal focus:shadow-ai-input-focus focus:border-ai-primary-teal block w-full sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="my-2">
        <div className="flex">
          <label
            htmlFor={name}
            className=" flex text-sm font-semibold text-ai-primary-dark"
          >
            {label}
          </label>
          {isTooltip && <ToolTipText text={ToolTipDescription} />}
        </div>
        <div className="mt-1">
          <input
            type={type}
            name={name}
            id={name}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            autocomplete={name}
            required={required}
            className={`shadow-sm  focus:ring-ai-primary-teal focus:shadow-ai-input-focus focus:border-ai-primary-teal block w-full sm:text-sm border-gray-300 rounded-md ${error && "border-ai-secondary-red"
              }`}
          />
          {error && (
            <span className="ml-1 mt-1  tracking-wider text-xs text-red-600 ">
              {error}
            </span>
          )}
        </div>
      </div>
    );
  }
};

export default Input;
