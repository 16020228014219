import React, { Fragment, useEffect, useState } from "react";

function Toggle({ label, id, name, value, values, onChange }) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(value);
  }, [value]);
  return (
    <Fragment>
      <label
        htmlFor={id}
        className="flex items-center align-middle justify-around my-4 m-auto cursor-pointer"
      >
        {/* <!-- toggle --> */}
        <div className="relative z-1">
          {/* <!-- input --> */}
          <input
            id={id}
            type="checkbox"
            checked={selected}
            defaultChecked={value}
            onChange={(e) => {
              setSelected(!selected);
              onChange(!selected);
            }}
            className="sr-only"
          />
          {/* <!-- line --> */}
          <div className="w-10 h-4 bg-gray-300 rounded-full shadow-inner"></div>
          {/* <!-- dot --> */}
          <div className="dot absolute w-6 h-6 bg-red-500 rounded-full shadow -left-1 -top-1 transition"></div>
        </div>
      </label>
    </Fragment>
  );
}

export default Toggle;
