import React, { useEffect, useState } from "react";
import Dropdown from "../Inputs/Select/indexNew";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";


function OverAllTenantSelection() {
  const [state, setState] = useState({
    tenanat_names: [],
    selected_tenant: parseInt(localStorage.getItem("selected_tenant_id")),
    all_tenants: [],
  });
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    let tenants = JSON.parse(localStorage.getItem("tenants")),
      selected_id = parseInt(localStorage.getItem("selected_tenant_id")),
      selected_name = "";

    let tenanat_names = tenants.map((tenant) => {
      if (tenant.tenant_id === selected_id) {
        selected_name = tenant.tenant_name;
      }
      return tenant.tenant_name;
    });
    setState({
      ...state,
      tenanat_names,
      selected_tenant: selected_name,
      all_tenants: tenants,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.tenanat_names.length === 0) return <div></div>;
  return (
    <div>
      <div className="flex">
        <p className="hidden lg:block items-center my-auto font-bold mr-4 text-lg">
          Tenant{" "}
        </p>
        <Dropdown
          onChange={(e) => {

            state.all_tenants.forEach((tenant) => {
              if (tenant.tenant_id === e) {
                localStorage.setItem("selected_tenant_id", tenant.tenant_id);
                localStorage.setItem(
                  "selected_tenant_name",
                  tenant.tenant_name
                );

                location.pathname === "/dashboard/imports" &&
                  history.push("/dashboard/connections")

                window.location.pathname.includes('/dashboard/connections/create/') && history.push("/dashboard/connections")


                window.location.reload();

              }
            });

          }}
          values={state.all_tenants.map((tenant) => {
            return { label: tenant.tenant_name, value: tenant.tenant_id };
          })}
          value={state.selected_tenant}

        />
      </div>
    </div>
  );
}

export default OverAllTenantSelection;
