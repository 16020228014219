import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { AiFillEdit, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import { MdDelete } from "react-icons/md";
import Loading from "../../../components/Loading";
import AddError from "./AddError";
import RemoveError from "./RemoveError";

const ErrorTroubleshooting = ({ userGuideData, loading, error, fetchData }) => {
  const modalRef = useRef();
  const removeModalRef = useRef();

  const [data, setData] = useState(userGuideData || []);
  const [selectedError, setSelectedError] = useState(null);
  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    let filterData = userGuideData.filter(
      (item) =>
        item.subHeading.toLowerCase().includes(value) ||
        item.values.join(" ").toLowerCase().includes(value)
    );
    setData(filterData);
  };

  const handleAddError = () => {
    setSelectedError(null);
    modalRef.current.openModal();
  };

  const handleClickEdit = (e, item) => {
    e.stopPropagation();
    setSelectedError(item);
    modalRef.current.openModal();
  };

  const handleClickDelete = (e, item) => {
    e.stopPropagation();
    setSelectedError(item);
    removeModalRef.current.openModal();
  };

  useEffect(() => {
    setData(userGuideData);
  }, [userGuideData]);
  const Item = ({ item }) => {
    const [showMore, setShowMore] = useState(false);
    return (
      <li
        className="py-5 px-6 sm:px-12 md:px-32 hover:bg-[#F2F8F8]  transition-all duration-500"
        style={{
          background: showMore ? "#F2F8F8" : "white",
        }}
      >
        <div
          className="flex justify-between cursor-pointer items-center"
          onClick={() => setShowMore(!showMore)}
        >
          <div className="flex gap-4">
            <h6 className="text-ai-primary-text-dark text-base">
              {item.subHeading}
            </h6>
            {showMore && localStorage.isSuperAdmin && (
              <>
                <AiFillEdit
                  className="w-5 h-5 text-ai-primary-text-teal"
                  onClick={(e) => handleClickEdit(e, item)}
                />
                <MdDelete
                  className="w-5 h-5 text-red-500"
                  onClick={(e) => handleClickDelete(e, item)}
                />
              </>
            )}
          </div>
          {showMore ? (
            <AiOutlineMinus className="w-4 h-4 cursor-pointer" />
          ) : (
            <AiOutlinePlus className="w-4 h-4 cursor-pointer" />
          )}
        </div>

        <div
          className={` ${showMore ? "max-h-60" : "max-h-0"
            } grid grid-cols-1 sm:grid-cols-3 mt-5 gap-4 overflow-hidden transition-all duration-500 ease-in-out`}
        >
          {item.values.length > 0 && (
            <ul className="col-span-2 ml-2">
              {item.values.map((value, j) => {
                return (
                  <li className="py-1 text-ai-primary-text-dark text-sm ">
                    <span className="inline-flex w-4 h-4 rounded-full justify-center items-center bg-[#9396A8] text-white text-xs mr-1">
                      {String.fromCharCode(j + 65).toLowerCase()}
                    </span>{" "}
                    {value}
                  </li>
                );
              })}
            </ul>
          )}

          {item.errorImage ? (
            <div className="col-span-1 text-center flex justify-center items-center bg-white">
              <img
                src={item.errorImage}
                alt={item.subHeading}
                className="rounded-sm hover:opacity-50"
              />
            </div>
          ) : (
            <div className="col-span-1 px-6 py-8 text-center flex justify-center items-center bg-white">
              Here we can add problem sreenshot or video
            </div>
          )}
        </div>
      </li>
    );
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <p className="text-ai-primary-text-red text-xl">{error}</p>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 p-4 sm:p-6">
        <p className="text-ai-primary-text-dark text-sm font-semibold col-span-2">
          Incase of any set up related issues, the product might fail to push
          the data. In this scenario, based on potential issues we have custom
          error messages that are updated against the import.
          <p className="mt-2">
            Below is the list of error messages along with list of items to
            check to resolve the respective issues
          </p>
        </p>
        <div className="flex flex-col justify-between gap-2 items-end">
          <input
            onChange={handleSearch}
            type="search"
            placeholder="Search"
            className="rounded-full h-9 border w-full col-span-1 bg-gray-50 p-4 focus:border-ai-primary-teal focus:outline-none focus:ring-1 focus:ring-sa-secondary block w-full"
          />
          {localStorage.isSuperAdmin && (
            <button
              className="px-2 py-2 mr-3 max-w-max bg-ai-primary-text-teal rounded-md text-white"
              onClick={handleAddError}
            >
              Add Error
            </button>
          )}
        </div>
      </div>

      <ul className="divide-y border-t">
        {data.length > 0 ? (
          data.map((item, i) => {
            return <Item item={item} key={item.id} />;
          })
        ) : (
          <li className="text-center text-lg mt-5">No Result Found</li>
        )}
      </ul>
      <AddError
        modalRef={modalRef}
        selectedItem={selectedError}
        data={data}
        fetchData={fetchData}
      />
      <RemoveError
        modalRef={removeModalRef}
        selectedItem={selectedError}
        data={data}
        fetchData={fetchData}
      />
    </>
  );
};
export default ErrorTroubleshooting;
