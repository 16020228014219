import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  AiOutlineLoading3Quarters,
  AiFillPlusCircle,
  AiOutlineOrderedList,
  AiOutlinePartition,
  AiOutlineDelete
} from "react-icons/ai";
import { FaCopy } from "react-icons/fa";
import Input from "../../../../components/Inputs/Input";
import InputChip from "../../../../components/Inputs/InputChip";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import { BASE_URL, TENANT_ID } from "../../../../utils/variables";
import { useLocation, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import CreateImport from "../CreateImport";
import Accordion from "../../../../components/Accordion/Accordion";
import DropdownSelect from "../../../../components/Inputs/Select";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";



const NewCreateImportPage = () => {
  let history = useHistory();
  // We are getting data that we passed from _openEditConnection in connections page
  const location = useLocation();

  const importData = location.state?.dataImports;
  let currentConnectionType = importData.connectionDetails.connection_type;
  const [create, setCreate] = useState({
    allReportsData: false,
    allReportNames: [],
    loading: false,
  });
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);
  const [step, setStep] = useState(0);
  const scrollRef = useRef();
  const [state, setState] = useState({
    loading: false,
    // success: false,
    // error: "",
  })
  //importData.imstate this was in the state above
  const [inputs, setInputs] = useState(
    importData.import_data
      ? {
        // subqueries: [],
        // mapping_gsheeturl: "",
        // mapping_gsheettabname: "",
        program_name: "",
        frequency: "",
        connectionType: currentConnectionType,
        reportName: "",
        report_id: "",
        report_type: "",
        day: "",
        time: "",
        date_column: "",
        partition_column: "",
        dataPushType: importData.import_data[0]?.processing_type
          ? importData.import_data[0].processing_type
          : "",
        input_email_subject: "",
        input_sheet_name: "",
        skip_rows: "",
        overlapping_column: "",
        importDestination: importData.import_data[0]?.output_file_type
          ? importData.import_data[0].output_file_type
          : "",
        google_sheet_output_url: "",
        output_sheetname: "",
        attachmentType: importData.import_data[0]?.report_type
          ? importData.import_data[0].report_type
          : "",
        bq_credentials: "",
        s3_location: "",
        bq_table_name: "",
        email_list: [], // add it
        mail_folder: "inbox",
        report_time_zone: importData.import_data[0]?.report_time_zone,
        link_type: "",
        end_row: null,
        connection: importData.connection,//connectionID
        account: localStorage.getItem("user_account_id"),
        status: "Active",
        ...importData.import_data[0],
        frequency_week:
          importData.import_data[0]?.frequency === "Hourly"
            ? importData.import_data[0]?.date_range_data
            : importData.import_data[0]?.frequency_week,
      }
      : {
        // subqueries: [],
        // mapping_gsheeturl: "",
        // mapping_gsheettabname: "",
        program_name: "",
        frequency: "",
        connectionType: currentConnectionType,
        reportName: "",
        day: "",
        frequency_week: "",
        time: "",
        dataPushType: "",
        input_email_subject: "",
        input_sheet_name: "",
        skip_rows: "",
        overlapping_column: "",
        importDestination: "",
        google_sheet_output_url: "",
        output_sheetname: "",
        attachmentType: "",
        bq_credentials: "",
        bq_table_name: "",
        email_list: [], // add it
        mail_folder: "inbox",
        report_time_zone: "",
        link_type: "",
        end_row: null,
        connection: importData.connection,
        account: localStorage.getItem("user_account_id"),
        status: "Active",
        s3_location: "",
        partition_column: "",
        date_column: "",
      }
  );

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  //#region Page 1 Create Import Support Code

  useEffect(() => {
    setInputs(
      importData.import_data
        ? {
          program_name: "",
          frequency: "",
          connectionType: currentConnectionType,
          reportName: "",
          report_id: "",
          day: "",
          time: "",
          dataPushType: importData.import_data[0]?.processing_type
            ? importData.import_data[0].processing_type
            : "",
          input_email_subject: "",
          input_sheet_name: "",
          skip_rows: "",
          overlapping_column: "",
          importDestination: importData.import_data[0]?.output_file_type
            ? importData.import_data[0].output_file_type
            : "",
          google_sheet_output_url: "",
          output_sheetname: "",
          attachmentType: importData.import_data[0]?.report_type
            ? importData.import_data[0].report_type
            : "",
          bq_credentials: "",
          bq_table_name: "",
          email_list: [], // add it
          mail_folder: "inbox",
          report_time_zone: importData.import_data[0]?.report_time_zone,
          link_type: "",
          end_row: null,
          connection: importData.import_data.connection,
          account: localStorage.getItem("user_account_id"),
          status: "Active",
          ...importData.import_data[0],
          frequency_week:
            importData.import_data[0]?.frequency === "Hourly"
              ? importData.import_data[0]?.date_range_data
              : importData.import_data[0]?.frequency_week,
        }
        : {
          program_name: "",
          frequency: "Hourly",
          connectionType: currentConnectionType,
          reportName: "",
          day: "",
          frequency_week: "",
          time: "",
          dataPushType: "",
          input_email_subject: "",
          input_sheet_name: "",
          skip_rows: "",
          overlapping_column: "",
          importDestination: "",
          google_sheet_output_url: "",
          output_sheetname: "",
          attachmentType: "",
          bq_credentials: "",
          bq_table_name: "",
          email_list: [], // add it
          mail_folder: "inbox",
          report_time_zone: "",
          link_type: "",
          end_row: null,
          connection: importData.connection,
          account: localStorage.getItem("user_account_id"),
          status: "Active",
        }
    );
  }, [importData.import_data]);
  const [inputError, setInputError] = useState({
    program_nameError: "",
    frequencyError: "",
    connectionTypeError: "",
    reportNameError: "",
    dayError: "",
    frequency_weekError: "",
    timeError: "",
    processing_typeError: "",
    input_email_subjectError: "",
    input_sheet_nameError: "",
    report_typeError: "",
    skip_rowsError: "",
    overlapping_columnError: "",
    output_file_typeError: "",
    google_sheet_output_urlError: "",
    output_sheetnameError: "",
    attachmentTypeError: "",
    bq_credentialsError: "",
    bq_table_nameError: "",
    email_listError: "",
    mail_folderError: "",
    report_time_zoneError: "",
    link_typeError: "",
    end_rowError: "",
    connectionError: "",
    accountError: "",
    tenant_nameError: "",
    statusError: "",
    s3LocationError: "",
    tableTypeError: "",
    partitionColumnError: "",
    dateColumnError: "",
  });
  const [copyColor, setCopyColor] = useState(false);
  //Calling Create/Edit Import API
  const onCreateImport = async () => {
    let isFormValid = formValidation();
    // console.log("these are inputs", inputs);
    //console.log(connectionDetails);
    if (isFormValid) {
      let body = currentConnectionType.toLowerCase() === "tam" ? {
        program_name: inputs.program_name,
        frequency: inputs.frequency,
        frequency_week: inputs.frequency_week,
        time:
          inputs.frequency === "Hourly" ? "0" : inputs.time.split(":").join(""),
        // overlapping_column:
        //   inputs.dataPushType === "Replace" ? "" : inputs.overlapping_column,
        bq_table_name: inputs.bq_table_name,
        // bq_credentials: "test",//localStorage.getItem("selectedServiceAccount"),
        // subqueries: [],
        // mapping_gsheeturl: "",
        // mapping_gsheettabname: "",
        email_list: inputs.email_list,
        connection: importData?.connectionDetails.connection_id,
        account: inputs.account,
        status: inputs.status,
        report_type: inputs.report_type,
        table_type: inputs.table_type,
        output_file_type: inputs.importDestination,
        processing_type: inputs.dataPushType,
        s3_location: inputs.s3_location ? inputs.s3_location : "",
        import_id: inputs.import_id,
        date_column: inputs.date_column,
        google_sheet_output_url: inputs.google_sheet_output_url,
        output_sheetname: inputs.output_sheetname,

        // partition_column: inputs.partition_column,
      } : {
        ...inputs,
        processing_type: inputs.dataPushType,
        output_file_type: inputs.importDestination,
        report_type: inputs.attachmentType,
        date_range_data: inputs.date_range_data,
        connection: importData?.connectionDetails.connection_id,
        incoming_email: importData?.connectionDetails.email,

        // Day means DATE (eg:25-11-2021)
        day:
          inputs.frequency === "Daily" ||
            inputs.frequency === "Weekly" ||
            inputs.frequency === "Hourly"
            ? ""
            : inputs.day,

        // frequency_week means weekday (eg:Sunday)
        frequency_week:
          inputs.frequency === "Daily" ||
            inputs.frequency === "Hourly" ||
            inputs.frequency === "Monthly"
            ? ""
            : inputs.frequency_week,

        time:
          inputs.frequency === "Hourly" ? "0" : inputs.time.split(":").join(""),

        overlapping_column:
          inputs.dataPushType === "Replace" ? "" : inputs.overlapping_column,

        bq_table_name:
          inputs.importDestination === "Gsheet" ? "" : inputs.bq_table_name,

        output_sheetname:
          inputs.importDestination === "BQ Table"
            ? ""
            : inputs.output_sheetname,

        google_sheet_output_url:
          inputs.importDestination === "BQ Table"
            ? ""
            : inputs.google_sheet_output_url,
      };

      if (currentConnectionType.toLowerCase() === "email" || currentConnectionType.toLowerCase() === "tam") {
        body.bq_credentials = importData?.connectionDetails?.service_account;
      } else {
        body.bq_credentials = importData?.connectionDetails?.service_account;
        body.network_id = importData?.connectionDetails?.network_id;
      }

      let tenantId = TENANT_ID;
      let API = "";

      if (currentConnectionType.toLowerCase() === "tam") {
        API = `${BASE_URL}imports/tam/${tenantId}`;
      }
      else if (importData.import_data) {
        API = `${BASE_URL}imports/update/${tenantId}`;
      }
      else {
        API =
          importData.import_data.toLowerCase() === "email" || currentConnectionType.toLowerCase() === "ga"
            ? `${BASE_URL}imports/email/${tenantId}`
            : `${BASE_URL}imports/gam/${tenantId}`
        // :`${BASE_URL}imports/tam/${tenantId}`;
      }

      //console.log("testhere", body);

      try {
        setState({ error: "", success: "", loading: true });
        // const body2 = {
        //   ...body,
        //   selected_tenant_id: parseInt(localStorage.selected_tenant_id)
        // };
        if (importData.import_data && currentConnectionType.toLowerCase() === "tam") {
          const response = await fetch(API, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            body: JSON.stringify(body),
          });
          const responseData = await response.json();
        }
        else {
          const response = await fetch(API, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            body: JSON.stringify(body),
          })
          const responseData = await response.json();
        }



        setState({
          ...state,
          loading: false,
        });

        setToast({
          type: false,
          message: "Import Operation Successfull!",
        });
        //UNCOMMNET THESE TO REDIRECT

        setTimeout(() => {
          window.location.reload(true);
        }, 3000);

        onClose();
      } catch (error) {
        setState({
          ...state,
          loading: false,
        });
        setToast({
          type: true,
          message: "Something went wrong",
        });
        //console.log("setToast", toast);
      }

      // fetch(API, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      //   },
      //   body: JSON.stringify(body),
      // })
      //   .then((res) => res.json())
      //   .then((res) => {

      //     setState({
      //       ...state,
      //       success: "Import Operation Successfull!",
      //       error: null,
      //       loading: false,
      //     });
      //     setTimeout(() => {
      //       window.location.reload(true);
      //     }, 1000);
      //   })
      //   .catch((err) => {
      //     setState({
      //       ...state,
      //       success: false,
      //       error: "Something went wrong",
      //       loading: false,
      //     });
      //   });
    }
  };

  //Validating Input form
  const formValidation = () => {
    let isValid = true;
    // checks for both connection types
    if (!inputs.program_name.trim()) {
      setInputError((prevError) => {
        return {
          ...prevError,
          program_nameError: "Import Name is Invalid or Missing",
        };
      });
      isValid = false;
    }
    if (!inputs.frequency || inputs.frequency === "-- Select --") {
      setInputError((prevError) => {
        return {
          ...prevError,
          frequencyError: "Frequency is Invalid or Missing",
        };
      });
      isValid = false;
    }
    else if (inputs.frequency === "Monthly") {
      if (!inputs.day) {
        setInputError((prevError) => {
          return {
            ...prevError,
            dayError: "frequency Date is Invalid or Missing",
          };
        });
        isValid = false;
      }
    } else if (inputs.frequency === "Weekly")
      if (!inputs.frequency_week || inputs.frequency_week === "-- Select --") {
        setInputError((prevError) => {
          return {
            ...prevError,
            frequency_weekError: "Frequency day is Invalid or Missing",
          };
        });
        isValid = false;
      }
    if (!inputs.dataPushType || inputs.dataPushType === "-- Select --") {
      setInputError((prevError) => {
        return {
          ...prevError,
          dataPushTypeError: "Data Push Type  is Invalid or Missing",
        };
      });
      isValid = false;
    } else if (inputs.dataPushType === "Append") {
      if (!inputs.overlapping_column.trim() && inputs.frequency !== "Hourly") {
        if (currentConnectionType.toLowerCase() !== "tam") {
          setInputError((prevError) => {
            return {
              ...prevError,
              overlapping_columnError: "Primary Key is Invalid or Missing",
            };
          });
          isValid = false;
        }
      }
    }
    if (
      !inputs.importDestination ||
      inputs.importDestination === "-- Select --"
    ) {
      setInputError((prevError) => {
        return {
          ...prevError,
          importDestinationError: "Import Destination is Invalid or Missing",
        };
      });
      isValid = false;
    } else if (inputs.importDestination === "BQ Table") {
      if (!inputs.bq_table_name?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            bq_table_nameError: "BQ Table Path is Invalid or Missing",
          };
        });
        isValid = false;
      }
    } else if (inputs.importDestination === "Gsheet") {
      if (!inputs.google_sheet_output_url?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            google_sheet_output_urlError:
              "Google Sheet URL is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.output_sheetname?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            output_sheetnameError: "Data push Tab Name is Invalid or Missing",
          };
        });
        isValid = false;
      }
    }
    if (inputs.email_list?.length < 1) {
      setInputError((prevError) => {
        return {
          ...prevError,
          email_listError: "Must have email",
        };
      });
      isValid = false;
    }
    // checks for only email connection type
    if (currentConnectionType.toLowerCase() === "email") {
      if (!inputs.time?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            timeError: "Frequency time is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.mail_folder.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            mail_folderError: "Email Folder is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.input_email_subject.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            input_email_subjectError: "Email Subject is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.input_sheet_name?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            input_sheet_nameError:
              "Input Attachment Name is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.skip_rows?.toString()?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            skip_rowsError: "skip rows(from Row 1) is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.end_row?.toString()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            end_rowError: "skip rows(end of the report) is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.attachmentType || inputs.attachmentType === "-- Select --") {
        setInputError((prevError) => {
          return {
            ...prevError,
            attachmentTypeError: "Attachment Type is Invalid or Missing",
          };
        });
        isValid = false;
      }
    }

    // checks for only gam connection type
    if (currentConnectionType.toLowerCase() === "gam") {
      if (inputs.frequency !== "Hourly") {
        if (!inputs.time) {
          setInputError((prevError) => {
            return {
              ...prevError,
              timeError: "Frequency time is Invalid or Missing",
            };
          });
          isValid = false;
        }
      }
      if (!inputs.report_id) {
        setInputError((prevError) => {
          return {
            ...prevError,
            reportNameError: "Report Name is Invalid or Missing",
          };
        });
        isValid = false;
      }
      // if (!inputs.report_time_zone) {
      //   setInputError((prevError) => {
      //     return {
      //       ...prevError,
      //       report_time_zoneError: "Report time zone is Invalid or Missing",
      //     };
      //   });
      //   isValid = false;
      // }
    }
    if (currentConnectionType.toLowerCase() === "tam") {
      if (!inputs.time?.trim() && inputs.frequency !== "Hourly") {
        setInputError((prevError) => {
          return {
            ...prevError,
            timeError: "Frequency time is Invalid or Missing",
          };
        });
        isValid = false;
      }

      if (!inputs.s3_location) {
        setInputError((prevError) => {
          return {
            ...prevError,
            s3LocationError: "S3 Location is Invalid or Missing",
          };
        });
        isValid = false;
      }

      if (inputs.table_type === "-- Select --") {
        setInputError((prevError) => {
          return {
            ...prevError,
            tableTypeError: "Table type is Invalid or Missing",
          };
        });
        isValid = false;
      }
      //partitionColumnError
      // if (inputs.table_type === "Partitioned Table" && !inputs.partition_column) {
      //   if (inputs.frequency !== "Hourly") {
      //     setInputError((prevError) => {
      //       return {
      //         ...prevError,
      //         partitionColumnError: "Partition Column is Invalid or Missing",
      //       };
      //     });
      //     isValid = false;
      //   }
      // }
      if (inputs.importDestination === "BQ Table") {
        if (!inputs.date_column && inputs.frequency !== "Hourly") {
          setInputError((prevError) => {
            return {
              ...prevError,
              dateColumnError: "Date Column is Invalid or Missing",
            };
          });
          isValid = false;
        }
      }
    }

    return isValid;
  };
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });

    setState({ error: "", success: "" });
    setInputError({ ...inputError, [e.target.name + "Error"]: "" });
  };


  //Fetching report names for GAM
  const fetchReportNames = async (id) => {
    let connection_type = importData?.connectionDetails?.connection_type;

    if (connection_type.toUpperCase() === "GAM") {
      const API = `${BASE_URL}fetch-gamreports`;
      try {
        const response = await fetch(API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            network_id: localStorage.getItem("selectedNetworkId"),
          }),
        });
        const responseData = await response.json();
        let reportNames = [];
        responseData.response.forEach((i, v) => {
          reportNames.push(i.report_name);
        });
        reportNames.unshift("-- Select --");
        setCreate({
          allReportsData: responseData,
          allReportNames: reportNames,
          loading: false,
        });
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          success: false,
          error: "Something went wrong",
        }));
      }
    }
  };

  useEffect(() => {
    // Call the fetchReportNames function with the appropriate argument
    fetchReportNames(importData.connectionDetails.network_id);
  }, []);


  const onClose = () => {
    // history.push(
    //   `/dashboard/imports?connection=${importData.connectionDetails.connection_id}&type=${importData.connectionDetails.connection_type}`
    // );
    history.goBack();
  }
  //#endregion


  //Inputs for Part 2(Column Mappings)
  const sampleCols = ["Select an option", "Col1", "Col2", "Col3", "Col4", "Col5", "Col6", "Col7"];
  const secondCols = ["1", "2", "3", "4"];
  const [inputValues, setInputValues] = useState(() => {
    const initialInputValues = Array(sampleCols.length).fill({ input_column: '', bq_table_column: '' });
    return initialInputValues;
  });



  //Recursive Inputs for Part 3 (Thresholds) mapping_gsheetinfo
  const [mapping_gsheetinfo, setMapping_gsheetinfo] = useState([{
    columnname: "",
    mapping_gsheeturl: "",
    tabname: "",
  }]);

  const [subquery_gsheetinfo, setSubquery_gsheetinfo] = useState([{
    mapping_gsheeturl: "",
    tabname: ""
  }])
  //calculated_expressions
  const [calculated_expressions, setCalculated_expressions] = useState([{
    new_column_name: "",
    column_name1: "",
    operation: "",
    column_name2: "",
  }])

  //"subquery_gsheetinfo":[{"tabname":"tabname1","subquery_gsheeturl":"url1"},{"tabname":"tabname2",       "subquery_gsheeturl":"url2"}],




  //#region Support code for the MultiPageComponent

  const sideItems = [
    {
      sideItemId: 1,
      label: "Import",
      svgIcon: null,
      reactIcon: <AiFillPlusCircle className="w-5 h-5" />,
    },
    {
      sideItemId: 2,
      label: "Column Mappings",
      reactIcon: <AiOutlineOrderedList className="w-5 h-5" />,
    },
    {
      sideItemId: 3,
      label: "Threshold",
      reactIcon: <AiOutlinePartition className="w-5 h-5" />,
    },
  ];
  const onCLickNext = () => {
    let isFormvalid = true;
    if (isFormvalid) {
      if (step === sideItems.length - 1) {
        // console.log("epic");
      } else {
        setStep(step + 1);
        // console.log("not epic");
      }
    }
  };
  const _renderIcon = (data, index) => {
    let icon;
    let iconColor;
    let opacity;
    if (step === index) {
      iconColor = "#D65510";
      opacity = "1";
    } else if (index < step) {
      iconColor = "#0CCB7D";
      opacity = "1";
    } else {
      iconColor = "#2B313C";
      opacity = "0.5";
    }
    if (data.svgIcon) {
      icon = data.svgIcon({ color: iconColor, opacity: opacity });
    } else {
      icon = data.reactIcon;
    }
    return icon;
  };
  const _renderButtonData = () => {
    let button;
    if (step === sideItems.length - 1 && showSubmitLoading) {
      button = (
        <div className="h-5 w-5 mx-auto  text-center flex justify-center items-center flex-col">
          <AiOutlineLoading3Quarters
            className="animate-spin font-extrabold text-center  flex flex-col justify-center"
            size={40}
          />
        </div>
      );
    } else if (step === sideItems.length - 1) {
      button = "Submit";
    } else {
      button = "Next";
    }
    return button;
  };
  //#endregion


  // step 1 createImport
  const createImport = () => {
    // return (
    //   <div className="flex flex-col gap-6 md:gap-8">
    //     <CreateImport
    //       type={currentConnectionType}
    //       import_data={importData.import_data}
    //       connectionID={importData.connection}
    //       onClose={onClose}
    //       editImport={importData.editImport}
    //       allReportNames={create?.allReportNames}
    //       allReportsData={create?.allReportsData?.response || []}
    //       connectionDetails={importData?.connectionDetails}
    //     />
    //   </div>
    // );
    return (
      <div className="p-2 px-4 bg-white" ref={scrollRef}>
        {currentConnectionType.toLowerCase() === "email" || currentConnectionType.toLowerCase() === "ga" ? (
          // <!-- Email connection -->
          <div className="space-y-3 "  >
            <div className="space-y-8 ">

              <div>

                <p className="mt-1 text-sm text-gray-500">
                  {/* Use a permanent address where you can receive mail. */}
                </p>
              </div>

              <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  {/*  */}
                  <Input
                    label="Import Name"
                    name="program_name"
                    type="text"
                    value={inputs.program_name}
                    onChange={handleChange}
                  />
                  <span className="text-red-600">
                    {inputError.program_nameError}
                  </span>

                  {/*  */}
                  <Input
                    onChange={(e) => {
                      setInputs({ ...inputs, frequency: e });
                      setInputError({ ...inputError, frequencyError: "" });
                    }}
                    label="Frequency"
                    type="dropdown"
                    name="frequency"
                    values={["-- Select --", "Weekly", "Daily"]}
                    //"Monthly",
                    value={inputs.frequency}
                    isTooltip={true}
                    ToolTipDescription="Select how frequently the import should run"
                  />
                  <span className="text-red-600">
                    {inputError.frequencyError}
                  </span>

                  {/*  */}
                  {inputs.frequency === "Monthly" && (
                    <Input
                      label="Frequency Month"
                      name="day"
                      type="date"
                      value={inputs.day}
                      onChange={handleChange}
                    />
                  )}
                  <span className="text-red-600">{inputError.dayError}</span>

                  {/*  */}
                  {inputs.frequency === "Weekly" && (
                    <Input
                      onChange={(e) => {
                        setInputs({ ...inputs, frequency_week: e });
                        setInputError({
                          ...inputError,
                          frequency_weekError: "",
                        });
                      }}
                      type="dropdown"
                      label="Select Day"
                      name="frequency_week"
                      values={[
                        "-- Select --",
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                      ]}
                      value={inputs.frequency_week}
                    />
                  )}
                  <span className="text-red-600">
                    {inputError.frequency_weekError}
                  </span>

                  {/*  */}
                  <Input
                    label="Frequency Time"
                    name="time"
                    type="time"
                    value={
                      inputs.time.length === 4
                        ? inputs.time.slice(0, 2) +
                        ":" +
                        inputs.time.slice(2, 4)
                        : inputs.time
                    }
                    onChange={handleChange}
                  />
                  <span className="text-red-600">{inputError.timeError}</span>

                  {/*  */}
                  <Input
                    label="Mail Folder"
                    name="mail_folder"
                    type="text"
                    value={inputs.mail_folder}
                    onChange={handleChange}
                  />
                  <span className="text-red-600">
                    {inputError.mail_folderError}
                  </span>

                  {/*  */}

                  <Input
                    label="Input Email Subject"
                    name="input_email_subject"
                    type="text"
                    value={inputs.input_email_subject}
                    onChange={handleChange}
                  />
                  <span className="text-red-600">
                    {inputError.input_email_subjectError}
                  </span>

                  {/*  */}
                  <Input
                    label="Input Attachment Name"
                    name="input_sheet_name"
                    type="text"
                    value={inputs.input_sheet_name}
                    onChange={handleChange}
                  />
                  <span className="text-red-600">
                    {inputError.input_sheet_nameError}
                  </span>
                </div>

                <div className="sm:col-span-3">
                  {/*  */}
                  <Input
                    onChange={(e) => {
                      setInputs({ ...inputs, dataPushType: e });
                      setInputError({ ...inputError, dataPushTypeError: "" });
                    }}
                    label="Data Push Type"
                    type="dropdown"
                    name="dataPushType"
                    values={["-- Select --", "Replace", "Append"]}
                    value={inputs.dataPushType}
                    isTooltip={true}
                    ToolTipDescription="Replaces the entire existing data with new data"
                  />
                  <span className="text-red-600">
                    {inputError.dataPushTypeError}
                  </span>

                  {/*  */}
                  {inputs.dataPushType === "Append" && (
                    <Input
                      label="Primary Key"
                      name="overlapping_column"
                      type="text"
                      value={inputs.overlapping_column}
                      onChange={handleChange}
                      isTooltip={true}
                      ToolTipDescription="Appends the new data to the existing data after removing the duplicate rows"
                    />
                  )}
                  <span className="text-red-600">
                    {inputError.overlapping_columnError}
                  </span>

                  {/*  */}
                  <Input
                    onChange={(e) => {
                      setInputs({ ...inputs, importDestination: e });
                      setInputError({
                        ...inputError,
                        importDestinationError: "",
                      });
                    }}
                    label="Import Destination"
                    name="importDestination"
                    type="dropdown"
                    values={["-- Select --", "BQ Table", "Gsheet"]}
                    value={inputs.importDestination}
                  />
                  <span className="text-red-600">
                    {inputError.importDestinationError}
                  </span>

                  {inputs.importDestination === "BQ Table" && (
                    <Input
                      onChange={(e) => {
                        setInputs({ ...inputs, table_type: e });
                        setInputError({
                          ...inputError,
                          table_type: "",
                        });
                      }}
                      label="Table Type"
                      name="table_type"
                      type="dropdown"
                      values={[
                        "-- Select --",
                        "Regular Table",
                        "Partitioned Table",
                      ]}
                      value={inputs?.table_type}
                    />
                  )}

                  {/*  */}
                  {inputs.importDestination === "BQ Table" && (
                    <>
                      <Input
                        label="BQ Table Path"
                        name="bq_table_name"
                        type="text"
                        value={inputs.bq_table_name}
                        onChange={handleChange}
                      />
                      <span className="text-red-600">
                        {inputError.bq_table_nameError}
                      </span>
                      <div>
                        {/*  */}
                        <Input
                          label="Service Account"
                          name="select_servicea_ccount"
                          type="text"
                          value={localStorage.getItem("selectedServiceAccount")}
                          disabled={"disabled"}
                        />
                      </div>
                    </>
                  )}

                  {/*  */}
                  {inputs.importDestination === "Gsheet" && (
                    <div>
                      <Input
                        label="Google Sheet URL"
                        name="google_sheet_output_url"
                        type="text"
                        value={inputs.google_sheet_output_url}
                        onChange={handleChange}
                        isTooltip={true}
                        ToolTipDescription="Provide the complete Gsheet URL"
                      />
                      <span className="text-red-600">
                        {inputError.google_sheet_output_urlError}
                      </span>

                      <Input
                        label="Data push Tab Name"
                        name="output_sheetname"
                        type="text"
                        value={inputs.output_sheetname}
                        onChange={handleChange}
                        isTooltip={true}
                        ToolTipDescription="Provide the tab name to which the data should be pushed"
                      />
                      <span className="text-red-600">
                        {inputError.output_sheetnameError}
                      </span>
                      <div>
                        <div className="mt-5">
                          <span className="ml-2 text-black text-sm">
                            Service account to give access -
                          </span>
                        </div>
                        <div className="flex">
                          <span className="ml-2 text-black text-sm font-bold">
                            ingestion-projects@databeat-internal.iam.gserviceaccount.com
                          </span>
                          <FaCopy
                            className="h-4 w-4 ml-3 mt-1 cursor-pointer"
                            style={{ color: copyColor ? "blue" : "black" }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                "ingestion-projects@databeat-internal.iam.gserviceaccount.com"
                              );
                              setCopyColor(true);
                            }}
                          />
                          {copyColor && (
                            <span className="ml-2 text-blue-500 text-sm font-bold">
                              Copied!
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/*  */}
                  <Input
                    label="Rows to Skip (from Row 1)"
                    name="skip_rows"
                    type="number"
                    value={inputs.skip_rows}
                    onChange={handleChange}
                    isTooltip={true}
                    ToolTipDescription="Provide the number of unnecessary rows from the top to remove before importing"
                  />
                  <span className="text-red-600">
                    {inputError.skip_rowsError}
                  </span>

                  {/*  */}
                  <Input
                    label="Rows to Skip (end of the report)"
                    name="end_row"
                    type="number"
                    value={inputs.end_row}
                    onChange={handleChange}
                    isTooltip={true}
                    ToolTipDescription="Provide the number of unnecessary rows from the bottom to remove before importing"
                  />
                  <span className="text-red-600">
                    {inputError.end_rowError}
                  </span>

                  {/*  */}
                  <Input
                    onChange={(e) => {
                      setInputs({ ...inputs, attachmentType: e });
                      setInputError({ ...inputError, attachmentTypeError: "" });
                    }}
                    label="Attachment Type"
                    name="attachmentType"
                    type="dropdown"
                    values={["-- Select --", "Attachment", "Link"]}
                    value={
                      inputs.attachmentType.charAt(0).toUpperCase() +
                      inputs.attachmentType.slice(1)
                    }
                    isTooltip={true}
                    ToolTipDescription="Refers to any file (csv, xlsx, zip etc)"
                  />
                  <span className="text-red-600">
                    {inputError.attachmentTypeError}
                  </span>

                  {/*  */}
                  {inputs.attachmentType === "Link" && (
                    <div>
                      <label className="inline-flex items-center mt-8">
                        <input
                          type="checkbox"
                          name="isZip"
                          value={inputs.link_type}
                          className="appearance-none checked:bg-blue-600 checked:border-transparent  "
                          onChange={(e) => {
                            setInputs({
                              ...inputs,
                              link_type: e.target.checked ? "zip" : "",
                            });
                          }}
                        />
                        <div className="flex">
                          <span className="ml-2 text-gray-700">
                            Zip File in link
                          </span>
                        </div>
                      </label>
                    </div>
                  )}

                  {/*  */}
                  <div className="my-2">
                    <label
                      htmlFor="multi"
                      className=" flex text-sm font-semibold text-ai-primary-dark"
                    >
                      Email List
                    </label>
                    <div className="mt-1">
                      <InputChip
                        values={inputs.email_list}
                        onChange={(e) =>
                          setInputs({ ...inputs, email_list: e })
                        }
                      />
                      <span className="text-red-600">
                        {inputError.email_listError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            {/* {toast.message && (
              <ToastMsg
                msg={toast.message}
                isError={toast.type}
                setToast={setToast}
              />
            )} */}

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  onClick={() => onClose()}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                  Cancel
                </button>
                <button
                  onClick={onCreateImport}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ai-primary-teal"
                >
                  {state.loading ? (
                    <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                      <AiOutlineLoading3Quarters
                        className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                        size={40}
                      />
                    </div>
                  ) : importData.editImport ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : currentConnectionType.toLowerCase() === "gam" ? (
          // <!-- GAM API connection -->
          <div className="space-y-8">
            <div className="space-y-8">
              <div className="">
                <div>

                  <p className="mt-1 text-sm text-gray-500">
                    {/* Use a permanent address where you can receive mail. */}
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <div>
                      <Input
                        label="Import Name"
                        name="program_name"
                        type="text"
                        value={inputs.program_name}
                        onChange={handleChange}
                      />
                      <span className="text-red-600">
                        {inputError.program_nameError}
                      </span>
                      <Input
                        label="Network id"
                        name="network_id"
                        type="text"
                        value={localStorage.getItem("selectedNetworkId")}
                        disabled={"disabled"}
                      />
                      <Input
                        type="dropdown"
                        onChange={(e) => {
                          setInputs({ ...inputs, frequency: e });
                          setInputError({ ...inputError, frequencyError: "" });
                        }}
                        label="Frequency"
                        name="frequency"
                        values={[
                          "-- Select --",
                          // "Monthly",
                          // "Weekly",
                          "Daily",
                          "Hourly",
                        ]}
                        value={inputs.frequency}
                      />
                      <span className="text-red-600">
                        {inputError.frequencyError}
                      </span>

                      {/*  */}
                      {inputs.frequency === "Monthly" && (
                        <>
                          <Input
                            label="Frequency Month"
                            name="day"
                            type="date"
                            value={inputs.day}
                            onChange={handleChange}
                          />
                          <span className="text-red-600">
                            {inputError.dayError}
                          </span>
                        </>
                      )}

                      {/*  */}
                      {inputs.frequency === "Weekly" && (
                        <>
                          <Input
                            type="dropdown"
                            onChange={(e) => {
                              setInputs({ ...inputs, frequency_week: e });
                              setInputError({
                                ...inputError,
                                frequency_weekError: "",
                              });
                            }}
                            label="Select Day"
                            name="frequency_week"
                            values={[
                              "-- Select --",
                              "Sunday",
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                            ]}
                            value={inputs.frequency_week}
                          />
                          <span className="text-red-600">
                            {inputError.frequency_weekError}
                          </span>
                        </>
                      )}

                      {/*  */}
                      {/* {inputs.frequency === "Hourly" && (
                        <>
                          <Input
                            type="dropdown"
                            onChange={(e) =>
                              setInputs({ ...inputs, frequency_week: e })
                            }
                            label="Select Data Date Range"
                            name="frequency_week"
                            values={[
                              "-- Select --",
                              "Today",
                              "Yesterday",
                              "Last-3 Days",
                              "Last-7 Days",
                            ]}
                            value={inputs.frequency_week}
                          />
                          <span className="text-red-600">
                            {inputError.frequency_weekError}
                          </span>
                        </>
                      )} */}

                      {/*  */}
                      {inputs.frequency !== "Hourly" && (
                        <>
                          <Input
                            label="Frequency Time"
                            name="time"
                            type="time"
                            value={
                              inputs.time.length === 4
                                ? inputs.time.slice(0, 2) +
                                ":" +
                                inputs.time.slice(2, 4)
                                : inputs.time
                            }
                            onChange={handleChange}
                          />
                          <span className="text-red-600">
                            {inputError.timeError}
                          </span>
                        </>
                      )}

                      {/*  */}

                      <>
                        <Input
                          type="dropdown"
                          onChange={(e) =>
                            setInputs({ ...inputs, date_range_data: e })
                          }
                          label="Select Data Date Range"
                          name="date_range_data"
                          values={[
                            "-- Select --",
                            "Today",
                            "Yesterday",
                            "Last-3 Days",
                            "Last-7 Days",
                          ]}
                          value={inputs.date_range_data}
                        />
                        <span className="text-red-600">
                          {inputError.date_range_dataError}
                        </span>
                      </>
                      {/* <Input
                        label="Report Time Zone"
                        name="report_time_zone"
                        values={["-- Select --", "UTC", "IST", "EST"]}
                        value={inputs.report_time_zone}
                        type="dropdown"
                        onChange={(e) => {
                          setInputs({ ...inputs, report_time_zone: e });
                          setInputError({
                            ...inputError,
                            report_time_zoneError: "",
                          });
                        }}
                      /> */}
                      <span className="text-red-600">
                        {inputError.report_time_zoneError}
                      </span>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <div className="my-2">
                      {/*  */}
                      <Input
                        onChange={(e) => {
                          setInputs({ ...inputs, dataPushType: e });
                          setInputError({ ...inputError, dataPushTypeError: "" });
                        }}
                        label="Data Processing Type/ Data Import Type"
                        name="dataPushType"
                        type="dropdown"
                        values={["-- Select --", "Replace", "Append"]}
                        value={inputs.dataPushType}
                      />
                      <span className="text-red-600">
                        {inputError.dataPushTypeError}
                      </span>

                      {/*  */}
                      {inputs.dataPushType === "Append" && (
                        <Input
                          label="Primary Key"
                          name="overlapping_column"
                          type="text"
                          value={inputs.overlapping_column}
                          onChange={handleChange}
                        />
                      )}
                      <span className="text-red-600">
                        {inputError.overlapping_columnError}
                      </span>

                      {/*  */}
                      <div className="my-2">
                        <Input
                          onChange={(e) => {
                            setInputs({ ...inputs, importDestination: e });
                            setInputError({
                              ...inputError,
                              importDestinationError: "",
                            });
                          }}
                          type="dropdown"
                          label="Import Destination"
                          name="importDestination"
                          values={["-- Select --", "BQ Table", "Gsheet"]}
                          value={inputs.importDestination}
                        />
                        <span className="text-red-600">
                          {inputError.importDestinationError}
                        </span>
                      </div>
                      {inputs.importDestination === "BQ Table" && (
                        <Input
                          onChange={(e) => {
                            setInputs({ ...inputs, table_type: e });
                            setInputError({
                              ...inputError,
                              table_type: "",
                            });
                          }}
                          label="Table Type"
                          name="Table_type"
                          type="dropdown"
                          values={[
                            "-- Select --",
                            "Regular Table",
                            "Partitioned Table",
                          ]}
                          value={inputs?.table_type}
                        />
                      )}
                      {/*  */}
                      {inputs.importDestination === "BQ Table" && (
                        <Input
                          label="BQ Table Path"
                          name="bq_table_name"
                          type="text"
                          value={inputs.bq_table_name}
                          onChange={handleChange}
                        />
                      )}
                      <span className="text-red-600">
                        {inputError.bq_table_nameError}
                      </span>
                      {/*  */}
                      {inputs.importDestination === "Gsheet" && (
                        <div>
                          <Input
                            label="Google Sheet URL"
                            name="google_sheet_output_url"
                            type="text"
                            value={inputs.google_sheet_output_url}
                            onChange={handleChange}
                          />
                          <span className="text-red-600">
                            {inputError.google_sheet_output_urlError}
                          </span>

                          <div>
                            <div className="mt-5">
                              <span className="ml-2 text-black text-sm">
                                Service account to give access -
                              </span>
                            </div>
                            <div className="flex">
                              <span className="ml-2 text-black text-sm font-bold">
                                ingestion-projects@databeat-internal.iam.gserviceaccount.com
                              </span>
                              <FaCopy
                                className="h-4 w-4 ml-3 mt-1 cursor-pointer"
                                style={{ color: copyColor ? "blue" : "black" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    "ingestion-projects@databeat-internal.iam.gserviceaccount.com"
                                  );
                                  setCopyColor(true);
                                }}
                              />
                              {copyColor && (
                                <span className="ml-2 text-blue-500 text-sm font-bold">
                                  Copied!
                                </span>
                              )}
                            </div>
                          </div>
                          <Input
                            label="Data push Tab Name"
                            name="output_sheetname"
                            type="text"
                            value={inputs.output_sheetname}
                            onChange={handleChange}
                          />
                          <span className="text-red-600">
                            {inputError.output_sheetnameError}
                          </span>
                        </div>
                      )}
                      <div>
                        {/*  */}
                        <Input
                          label="Select Sevice Account"
                          name="select_servicea_ccount"
                          type="text"
                          value={localStorage.getItem("selectedServiceAccount")}
                          disabled={"disabled"}
                        />
                      </div>
                      {/* {console.log("allReportsData____", allReportsData)} */}
                      {create?.allReportsData?.response?.length > 0 && (
                        <Input
                          onChange={(e) => {
                            setInputs({
                              ...inputs,
                              reportName: e,
                              report_id: create?.allReportsData?.response
                                .filter((report) => report.report_name == e)[0]
                                .report_id.toString(),
                            });
                            setInputError({ ...inputError, reportNameError: "" });
                          }}
                          label="Report Name"
                          name="reportName"
                          type="dropdown"
                          values={create?.allReportNames}
                          value={
                            create?.allReportsData?.response.filter(
                              (report) => report.report_id == inputs.report_id
                            ).length > 0
                              ? create?.allReportsData?.response.filter(
                                (report) => report.report_id == inputs.report_id
                              )[0].report_name
                              : ""
                          }
                        />
                      )}
                      <span className="text-red-600">
                        {inputError.reportNameError}
                      </span>

                      <div className="my-2">
                        <label
                          htmlFor="multi"
                          className="flex text-sm font-semibold text-ai-primary-dark"
                        >
                          Email List
                        </label>
                        <div className="mt-1">
                          <InputChip
                            values={inputs.email_list}
                            onChange={(e) =>
                              setInputs({ ...inputs, email_list: e })
                            }
                          />
                          <span className="text-red-600">
                            {inputError.email_listError}
                          </span>
                        </div>
                      </div>

                      {/*  */}
                    </div>
                  </div>

                  {/*  */}
                </div>
              </div>
            </div>

            {toast.message && (
              <ToastMsg
                msg={toast.message}
                isError={toast.type}
                setToast={setToast}
              />
            )}

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  onClick={() => onClose()}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                  Cancel
                </button>
                <button
                  onClick={onCreateImport}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ai-primary-teal"
                >
                  {importData.editImport ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        ) : currentConnectionType.toLowerCase() === "tam" ? (
          <div className="space-y-8">
            <div className="space-y-8">
              <div className="">
                <div>
                  <p className="mt-1 text-sm text-gray-500">
                    {/* Use a permanent address where you can receive mail. */}
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <div>
                      <Input
                        label="Import Name"
                        name="program_name"
                        type="text"
                        value={inputs.program_name}
                        onChange={handleChange}
                      //disabled={editImport ? "disabled" : ""}
                      />
                      <span className="text-red-600">
                        {inputError.program_nameError}
                      </span>

                      <Input
                        type="dropdown"
                        onChange={(e) => {
                          setInputs({ ...inputs, report_type: e });
                          //setInputError({ ...inputError, frequencyError: "" });
                        }}
                        label="Report Type"
                        name="report_type"
                        values={[
                          "-- Select --",
                          "Report",
                          "Stream"

                        ]}
                        value={inputs.report_type}
                      />
                      {/* <span className="text-red-600">
                        {inputError.frequencyError}
                      </span> */}
                      {inputs.report_type === "Report" ?
                        <Input
                          type="dropdown"
                          onChange={(e) => {
                            setInputs({ ...inputs, frequency: e });
                            setInputError({ ...inputError, frequencyError: "" });
                          }}
                          label="Frequency"
                          name="frequency"
                          values={[
                            "-- Select --",
                            "Weekly",
                            "Daily",

                          ]}
                          value={inputs.frequency}
                        /> : inputs.report_type === "Stream" ?
                          <Input
                            type="dropdown"
                            onChange={(e) => {
                              setInputs({ ...inputs, frequency: e });
                              setInputError({ ...inputError, frequencyError: "" });
                            }}
                            label="Frequency"
                            name="frequency"
                            values={[
                              // "-- Select --",
                              "Hourly",
                            ]}
                            value={inputs.frequency}
                            disabled
                          />
                          : ""}

                      <span className="text-red-600">
                        {inputError.frequencyError}
                      </span>

                      {/*  */}

                      {inputs.frequency === "Weekly" && (
                        <>
                          {/* <Input
                            type="dropdown"
                            onChange={(e) => {
                              setInputs({ ...inputs, frequency_week: e });
                              setInputError({
                                ...inputError,
                                frequency_weekError: "",
                              });
                            }}
                            label="Select Day"
                            name="frequency_week"
                            values={[
                              "-- Select --",
                              "Sunday",
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                            ]}
                            value={inputs.frequency_week}
                          /> */}
                          <Input
                            onChange={(e) => {
                              setInputs({ ...inputs, frequency_week: e });
                              setInputError({
                                ...inputError,
                                frequency_weekError: "",
                              });
                            }}
                            type="dropdown"
                            label="Select Day"
                            name="frequency_week"
                            values={[
                              "-- Select --",
                              "Sunday",
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                            ]}
                            value={inputs.frequency_week}
                          />
                          <span className="text-red-600">
                            {inputError.frequency_weekError}
                          </span>
                        </>
                      )}

                      {/*  */}
                      {/* {inputs.frequency === "Hourly" && (
                      <>
                        <Input
                          type="dropdown"
                          onChange={(e) =>
                            setInputs({ ...inputs, frequency_week: e })
                          }
                          label="Select Data Date Range"
                          name="frequency_week"
                          values={[
                            "-- Select --",
                            "Today",
                            "Yesterday",
                            "Last-3 Days",
                            "Last-7 Days",
                          ]}
                          value={inputs.frequency_week}
                        />
                        <span className="text-red-600">
                          {inputError.frequency_weekError}
                        </span>
                      </>
                    )} */}

                      {/*  */}
                      {inputs.frequency !== "Hourly" && (
                        <>
                          <Input
                            label="Frequency Time"
                            name="time"
                            type="time"
                            value={
                              inputs.time.length === 4
                                ? inputs.time.slice(0, 2) +
                                ":" +
                                inputs.time.slice(2, 4)
                                : inputs.time
                            }
                            onChange={handleChange}
                          />
                          <span className="text-red-600">
                            {inputError.timeError}
                          </span>
                        </>
                      )}

                      {/*  */}
                      {/* 
                      <>
                        <Input
                          type="dropdown"
                          onChange={(e) =>
                            setInputs({ ...inputs, date_range_data: e })
                          }
                          label="Select Data Date Range"
                          name="date_range_data"
                          values={[
                            "-- Select --",
                            "Today",
                            "Yesterday",
                            "Last-3 Days",
                            "Last-7 Days",
                          ]}
                          value={inputs.date_range_data}
                        />
                        <span className="text-red-600">
                          {inputError.date_range_dataError}
                        </span>
                      </> */}
                      {/* <Input
                      label="Report Time Zone"
                      name="report_time_zone"
                      values={["-- Select --", "UTC", "IST", "EST"]}
                      value={inputs.report_time_zone}
                      type="dropdown"
                      onChange={(e) => {
                        setInputs({ ...inputs, report_time_zone: e });
                        setInputError({
                          ...inputError,
                          report_time_zoneError: "",
                        });
                      }}
                    /> 
                      <span className="text-red-600">
                        {inputError.report_time_zoneError}
                      </span>*/}
                      <Input
                        onChange={(e) => {
                          setInputs({ ...inputs, dataPushType: e });
                          setInputError({ ...inputError, dataPushTypeError: "" });
                          // console.log("dataPushtype", inputs.dataPushType)
                        }}
                        label="Data Processing Type/ Data Import Type"
                        name="dataPushType"
                        type="dropdown"
                        values={["-- Select --", "Append", "Replace"]}
                        value={inputs.dataPushType}

                      />
                      <span className="text-red-600">
                        {inputError.dataPushTypeError}
                      </span>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <div className=" my-2">
                      {/*  */}


                      {/* PRIMARY KEY */}
                      {/* {inputs.dataPushType === "Append" && inputs.frequency !== "Hourly" ? (
                          <Input
                            label="Primary Key"
                            name="overlapping_column"
                            type="text"
                            value={inputs.overlapping_column}
                            onChange={handleChange}
                          />
                        ) : ""}
                        <span className="text-red-600">
                          {inputError.overlapping_columnError}
                        </span> */}

                      {/*  */}
                      <div className="">
                        <Input
                          onChange={(e) => {
                            setInputs({ ...inputs, importDestination: e });
                            setInputError({
                              ...inputError,
                              importDestinationError: "",
                            });
                          }}
                          type="dropdown"
                          label="Import Destination"
                          name="importDestination"
                          values={["-- Select --", "BQ Table", "Gsheet"]}
                          value={inputs.importDestination}
                        />
                        <span className="text-red-600">
                          {inputError.importDestinationError}
                        </span>
                      </div>
                      {inputs.importDestination === "BQ Table" && (
                        <Input
                          onChange={(e) => {
                            setInputs({ ...inputs, table_type: e });
                            setInputError({
                              ...inputError,
                              tableTypeError: "",
                            });
                          }}
                          label="Table Type"
                          name="Table_type"
                          type="dropdown"
                          values={[
                            "-- Select --",
                            "Regular Table",
                            "Partitioned Table",
                          ]}
                          value={inputs?.table_type}
                        />

                      )}
                      <span className="text-red-600">
                        {inputError.tableTypeError}
                      </span>
                      {inputs.importDestination === "BQ Table" && inputs.frequency !== "Hourly" ? (
                        <Input
                          label="Date Column"
                          name="date_column"
                          type="text"
                          value={inputs.date_column}
                          onChange={handleChange}
                        />
                      ) : ""}
                      <span className="text-red-600">
                        {inputError.dateColumnError}
                      </span>
                      {inputs.importDestination === "Gsheet" && (
                        <div>
                          <Input
                            label="Google Sheet URL"
                            name="google_sheet_output_url"
                            type="text"
                            value={inputs.google_sheet_output_url}
                            onChange={handleChange}
                          />
                          <span className="text-red-600">
                            {inputError.google_sheet_output_urlError}
                          </span>

                          <div>
                            <div className="mt-5">
                              <span className="ml-2 text-black text-sm">
                                Service account to give access -
                              </span>
                            </div>
                            <div className="flex">
                              <span className="ml-2 text-black text-sm font-bold">
                                ingestion-projects@databeat-internal.iam.gserviceaccount.com
                              </span>
                              <FaCopy
                                className="h-4 w-4 ml-3 mt-1 cursor-pointer"
                                style={{ color: copyColor ? "blue" : "black" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    "ingestion-projects@databeat-internal.iam.gserviceaccount.com"
                                  );
                                  setCopyColor(true);
                                }}
                              />
                              {copyColor && (
                                <span className="ml-2 text-blue-500 text-sm font-bold">
                                  Copied!
                                </span>
                              )}
                            </div>
                          </div>
                          <Input
                            label="Data push Tab Name"
                            name="output_sheetname"
                            type="text"
                            value={inputs.output_sheetname}
                            onChange={handleChange}
                          />
                          <span className="text-red-600">
                            {inputError.output_sheetnameError}
                          </span>
                        </div>
                      )}
                      {/* PARTITION COLUMN */}
                      {/* {inputs.table_type === "Partitioned Table" && inputs.frequency !== "Hourly" ?
                          (
                            <Input
                              label="Partition Column"
                              name="partition_column"
                              type="text"
                              value={inputs.partition_column}
                              onChange={handleChange}
                            />
                          ) : ""}
                        <span className="text-red-600">
                          {inputError.partitionColumnError}
                        </span> */}
                      {/*  */}
                      {inputs.importDestination === "BQ Table" && (
                        <Input
                          label="BQ Table Path"
                          name="bq_table_name"
                          type="text"
                          value={inputs.bq_table_name}
                          onChange={handleChange}
                        />
                      )}
                      <span className="text-red-600">
                        {inputError.bq_table_nameError}
                      </span>
                      {/*  */}

                      <div>
                        {/*  */}
                        <Input
                          label="Select Sevice Account"
                          name="select_servicea_ccount"
                          type="text"
                          value={localStorage.getItem("selectedServiceAccount")}
                          disabled={"disabled"}
                        />
                      </div>
                      {/* {console.log("allReportsData____", allReportsData)} */}
                      {/* {allReportsData.length > 0 && (
                        <Input
                          onChange={(e) => {
                            setInputs({
                              ...inputs,
                              reportName: e,
                              report_id: allReportsData
                                .filter((report) => report.report_name == e)[0]
                                .report_id.toString(),
                            });
                            setInputError({ ...inputError, reportNameError: "" });
                          }}
                          label="Report Name"
                          name="reportName"
                          type="dropdown"
                          values={allReportNames}
                          value={
                            allReportsData.filter(
                              (report) => report.report_id == inputs.report_id
                            ).length > 0
                              ? allReportsData.filter(
                                (report) => report.report_id == inputs.report_id
                              )[0].report_name
                              : ""
                          }
                        />
                      )} */}
                      {/* <Input
                          // onChange={(e) => {
                          //   setInputs({
                          //     ...inputs,
                          //     reportName: e,
                          //     report_id: allReportsData
                          //       .filter((report) => report.report_name == e)[0]
                          //       .report_id.toString(),
                          //   });
                          //   setInputError({ ...inputError, reportNameError: "" });
                          // }}
                          label="S3 Location"
                          name="reportName"
                          type="text"
                          values={allReportNames}
  
                        /> */}

                      <Input
                        label="S3 Location"
                        name="s3_location"
                        type="text"
                        value={inputs.s3_location}
                        onChange={handleChange}
                      />
                      <span className="text-red-600">
                        {inputError.s3LocationError}
                      </span>

                      <div className="my-2">
                        <label
                          htmlFor="multi"
                          className="flex text-sm font-semibold text-ai-primary-dark"
                        >
                          Email List
                        </label>
                        <div className="mt-1">
                          <InputChip
                            values={inputs.email_list}
                            onChange={(e) =>
                              setInputs({ ...inputs, email_list: e })
                            }
                          />
                          <span className="text-red-600">
                            {inputError.email_listError}
                          </span>
                        </div>
                      </div>

                      {/*  */}
                    </div>
                  </div>

                  {/*  */}
                </div>
              </div>
              {/* {toast.message && (
                <ToastMsg
                  msg={toast.message}
                  isError={toast.type}
                  setToast={setToast}
                />
              )} */}

            </div>


            {/* <div className="pt-5">
              <div className="flex justify-end">
                <button
                  onClick={() => onClose()}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                  Cancel
                </button>
                <button
                  onClick={onCreateImport}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ai-primary-teal"
                >
                  {importData.editImport ? "Update" : "Save"}
                </button>
              </div>
            </div> */}
          </div>
        ) : (<p></p>)}
        {toast.message && (
          <ToastMsg
            msg={toast.message}
            isError={toast.type}
            setToast={setToast}
          />
        )}
      </div>
    );
  };

  // step 2 columnMapping
  const columnMapping = () => {
    const handleFieldChange = (index, key, value) => {
      const newInputValues = [...inputValues];
      newInputValues[index] = {
        ...newInputValues[index],
        [key]: value,
      };
      setInputValues(newInputValues);
    };


    return (
      <div>

        <div className="flex justify-between items-center gap-2 p-4 md:gap-8"><h4 className="">Columns</h4><h4 className="">Mapping</h4></div>
        {sampleCols.map((col, index) => (
          <div className="flex justify-between items-center gap-2 p-4 md:gap-8">
            <div className="w-full">
              <Input
                // onChange={(e) => {
                //   setInputs({ ...inputs, dataPushType: e });
                //   // setInputError({ ...inputError, dataPushTypeError: "" });
                // }}
                onChange={(value) => handleFieldChange(index, 'input_column', value)}
                type="dropdown"
                name="dataPushType"
                values={sampleCols}
                value={inputValues[index]?.input_column || ''}
              />
            </div>
            <div className=" w-full justify-end">

              <CustomSelect name="dataPushType" values={secondCols} value={inputValues[index]?.bq_table_column || ''} textValue={inputValues[index]?.bq_table_column || ''} onChange={(value) => handleFieldChange(index, 'bq_table_column', value)} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  // step 3 threshold
  const threshold = () => {
    //#region Support Code for Mapping File Accordion
    const handleMappingFile = (name, value, index) => {
      const updatedInputs = [...mapping_gsheetinfo];
      updatedInputs[index] = { ...updatedInputs[index], [name]: value };
      setMapping_gsheetinfo(updatedInputs);
    };
    const handleAddNewMappingFile = () => {
      setMapping_gsheetinfo([...mapping_gsheetinfo, {
        columnname: "",
        mapping_gsheeturl: "",
        tabname: "",
      }]);
    };
    const handleRemoveInput = (index) => {
      console.log("Index", index);
      const updatedInputs = [...mapping_gsheetinfo];
      console.log("updatedInputs", index);
      updatedInputs.splice(index, 1);
      setMapping_gsheetinfo(updatedInputs);
    };
    //#endregion

    //#region Support Code for SQL Code Block
    const handleSqlBlock = (name, value, index) => {
      const updatedInputs = [...subquery_gsheetinfo];
      updatedInputs[index] = { ...updatedInputs[index], [name]: value };
      setSubquery_gsheetinfo(updatedInputs);
    };
    const handleAddNewSqlBlock = () => {
      setSubquery_gsheetinfo([...subquery_gsheetinfo, {
        mapping_gsheeturl: "",
        tabname: "",
      }]);
    };
    const handleRemoveSqlBlock = (index) => {

      const updatedInputs = [...subquery_gsheetinfo];
      updatedInputs.splice(index, 1);
      setSubquery_gsheetinfo(updatedInputs);
    };
    //#endregion

    //#region Support Code for Threshold
    const handleDataTransformBlock = (name, value, index) => {
      const updatedInputs = [...calculated_expressions];
      updatedInputs[index] = { ...updatedInputs[index], [name]: value };
      setCalculated_expressions(updatedInputs);
    };
    const handleAddNewDataTransformBlock = () => {
      setCalculated_expressions([...calculated_expressions, {
        new_column_name: "",
        column_name1: "",
        operation: "",
        column_name2: "",
      }]);
    };
    const handleRemoveDataTransformBlock = (index) => {

      const updatedInputs = [...calculated_expressions];
      updatedInputs.splice(index, 1);
      setCalculated_expressions(updatedInputs);
    };
    //#endregion

    return (
      <div className="flex flex-col gap-6 md:gap-8">
        {/* Accordion 1 Mapping File */}
        <div className="space-y-2">
          <Accordion title="Mapping File">
            {mapping_gsheetinfo.map((input, index) => (
              <div
                key={index}
                className="flex justify-center shadow-sm m-1 items-center bg-gray-100 flex-wrap gap-2 p-4 md:gap-8 "
              >
                <div className="w-full md:w-auto">
                  <DropdownSelect
                    type="dropdown"
                    value={mapping_gsheetinfo[index].columnname}
                    values={["Select an option", "A", "B", "C", "D", "E"]}
                    label="Column Name"
                    name="columnname"
                    id="columnname"
                    onChange={(e) => handleMappingFile("columnname", e, index)}
                    placeholder="Column Name"
                    noMargin={true}
                  />
                </div>
                <div className="w-full md:w-auto">
                  <Input
                    type="text"
                    label="Google Sheet URL"
                    name="mapping_gsheeturl"
                    value={mapping_gsheetinfo[index].mapping_gsheeturl}
                    id="mapping_gsheeturl"
                    onChange={(e) => handleMappingFile("mapping_gsheeturl", e.target.value, index)}
                    placeholder="Google Sheet URL"
                    noMargin={true}
                  />
                </div>
                <div className="w-full md:w-auto">
                  <Input
                    type="text"
                    label="Tab Name"
                    name="tabname"
                    value={mapping_gsheetinfo[index].tabname}
                    id="tabname"
                    onChange={(e) => handleMappingFile("tabname", e.target.value, index)}
                    placeholder="Tab Name"
                    noMargin={true}
                  />
                </div>
                <div className="flex justify-center items-center text-center w-full md:w-auto">
                  {index === mapping_gsheetinfo.length - 1 ? (
                    <button
                      type="button"
                      className="text-button hover:text-blue-500"
                      onClick={handleAddNewMappingFile}
                    >
                      <AiFillPlusCircle className="mt-4 w-5 h-5" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="text-red-500 hover:text-red-300"
                      onClick={() => handleRemoveInput(index)}
                    >
                      <AiOutlineDelete className="mt-4 w-5 h-5" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </Accordion>
        </div>
        {/* Accordion 2 SQL Code Block */}
        <div className="space-y-2">
          <Accordion title="SQL Code Block">
            {subquery_gsheetinfo.map((input, index) => (
              <div
                key={index}
                className="flex justify-center shadow-sm m-1 items-center bg-gray-100 flex-wrap gap-2 p-4 md:gap-8 "
              >
                <div className="w-full md:w-auto">

                  <Input
                    type="text"
                    label="Google Sheet URL"
                    name="mapping_gsheeturl"
                    value={subquery_gsheetinfo[index].mapping_gsheeturl}
                    id="mapping_gsheeturl"
                    onChange={(e) => handleSqlBlock("mapping_gsheeturl", e.target.value, index)}
                    placeholder="Google Sheet URL"
                    noMargin={true}
                  />
                </div>
                <div className="w-full md:w-auto">
                  <Input
                    type="text"
                    label="Tab Name"
                    name="tabname"
                    value={subquery_gsheetinfo[index].tabname}
                    id="tabname"
                    onChange={(e) => handleSqlBlock("tabname", e.target.value, index)}
                    placeholder="Tab Name"
                    noMargin={true}
                  />
                </div>
                <div className="flex justify-center items-center text-center w-full md:w-auto">
                  {index === subquery_gsheetinfo.length - 1 ? (
                    <button
                      type="button"
                      className="text-button hover:text-blue-500"
                      onClick={handleAddNewSqlBlock}
                    >
                      <AiFillPlusCircle className="mt-4 w-5 h-5" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="text-red-500 hover:text-red-300"
                      onClick={() => handleRemoveSqlBlock(index)}
                    >
                      <AiOutlineDelete className="mt-4 w-5 h-5" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </Accordion>
        </div>
        {/* Accordion 3 Data Transformation  */}
        <div className="space-y-2">
          <Accordion title="Data Transformation">
            {calculated_expressions.map((input, index) => (
              <div
                key={index}
                className="flex justify-center shadow-sm m-1 items-center bg-gray-100 flex-wrap gap-2 p-4 md:gap-8 "
              >
                <div className="w-full md:w-auto">
                  <Input
                    type="text"
                    label="New Column Name"
                    name="new_column_name"
                    value={calculated_expressions[index].new_column_name}
                    id="new_column_name"
                    onChange={(e) => handleDataTransformBlock("new_column_name", e.target.value, index)}
                    placeholder="New Column Name"
                    noMargin={true}
                  />
                </div>
                <h3 className="pt-6">=</h3>
                <div className="w-full md:w-auto">
                  <Input
                    type="dropdown"
                    value={calculated_expressions[index].column_name1}
                    values={["Select an option", "A", "B", "C", "D", "E"]}
                    label="Column Name"
                    name="column_name1"
                    id="column_name1"
                    onChange={(e) => handleDataTransformBlock("column_name1", e, index)}
                    placeholder="Column Name"
                    noMargin={true}
                  />
                </div>
                <div className="w-full md:w-auto">
                  <Input
                    type="dropdown"
                    value={calculated_expressions[index].operation}
                    values={["Select an option", "Plus(+)", "Minus(-)", "Multiply(X)", "Divide(÷)"]}
                    label="Operation"
                    name="operation"
                    id="operation"
                    onChange={(e) => handleDataTransformBlock("operation", e, index)}
                    placeholder="Column Name"
                    noMargin={true}
                  />
                </div>
                <div className="w-full md:w-auto">
                  <Input
                    type="dropdown"
                    value={calculated_expressions[index].column_name2}
                    values={["Select an option", "A", "B", "C", "D", "E"]}
                    label="Column Name"
                    name="column_name2"
                    id="column_name2"
                    onChange={(e) => handleDataTransformBlock("column_name2", e, index)}
                    placeholder="Column Name"
                    noMargin={true}
                  />
                </div>
                <div className="flex justify-center items-center text-center w-full md:w-auto">
                  {index === calculated_expressions?.length - 1 ? (
                    <button
                      type="button"
                      className="text-button hover:text-blue-500"
                      onClick={handleAddNewDataTransformBlock}
                    >
                      <AiFillPlusCircle className="mt-4 w-5 h-5" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="text-red-500 hover:text-red-300"
                      onClick={() => handleRemoveDataTransformBlock(index)}
                    >
                      <AiOutlineDelete className="mt-4 w-5 h-5" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </Accordion>
        </div>
      </div>
    );

  };


  //Main Return
  return (
    <>
      <div className="mt-5 ml-3 md:px-5 flex justify-between max-w-full">
        <div>
          <h2 className="text-2xl sm:text-3xl text-ai-primary-text-dark font-semibold">
            {importData.editImport ? "Edit" : "Create"} Import
          </h2>
          <span className="ml-1 text-md pb-1 text-ai-primary-text-teal font-semibold">
            {currentConnectionType.toUpperCase()}
          </span>
        </div>
      </div>

      <div className="">
        <div
          className="flex py-10 px-2 sm:px-8 md:px-10 overflow-hidden min-h-screen"
          style={{ background: "#F1F2F2" }}
        >
          <main className="flex-1 flex flex-col overflow-hidden">
            <nav className="flex justify-around rounded-md text-center items-center">
              {sideItems.map((head, i) => {
                let sideItemColor;
                let sideItemBorder;
                if (step === i) {
                  sideItemColor = "text-ai-current-step";
                  sideItemBorder = "bg-ai-current-step w-[99%]";
                } else if (i < step) {
                  sideItemColor = "text-ai-done-step";
                  sideItemBorder = "bg-ai-done-step w-[99%]";
                } else {
                  sideItemColor = "text-ai-primary-text-dark opacity-60";
                  sideItemBorder = "bg-gray-300";
                }
                return (
                  <div
                    key={i}
                    className="w-full h-12 justify-between  flex flex-col items-center duration-200"
                  >
                    <div
                      className={`flex gap-1 text-center items-center ${sideItemColor} `}
                    >
                      <span className="mr-2">{_renderIcon(head, i)}</span>
                      <p
                        className={`hidden sm:block text-sm font-bold duration-200`}
                      >
                        {head.label}
                      </p>
                    </div>
                    <div
                      className={`h-1 w-full relative duration-200 ${sideItemBorder}`}
                    >
                    </div>
                  </div>
                );
              })}
            </nav>
            {/* <div className="bg-white px-6 md:py-8 md:px-10 lg:py-10 lg:px-28"> */}
            <div className="bg-white px-6 py-6" >
              {step === 0 && createImport()}
              {step === 1 && columnMapping()}
              {step === 2 && threshold()}

              <div className="pt-10">

                <div className="w-full  flex justify-between">
                  <div className="flex">
                    <button
                      className="bg-button-bg uppercase py-2 px-6  md:py-3 md:px-12 rounded-md shadow-sm text-sm font-semibold text-ai-primary-text-dark hover:bg-ai-primary-gray focus:outline-none focus:outine-none"
                      // onClick={(e) => {
                      //     route.push("/cue-summary");
                      // }}
                      onClick={() => {
                        console.log("Recursive", mapping_gsheetinfo);
                        console.log("SQL", subquery_gsheetinfo);
                        console.log("Data Transformation", calculated_expressions);
                        console.log(`{ "mapping_gsheetinfo":${JSON.stringify(mapping_gsheetinfo)},
                        "subquery_gsheetinfo":${JSON.stringify(subquery_gsheetinfo)},
                        "calculated_expressions":${JSON.stringify(calculated_expressions)}}
                                              `,




                        )
                        // , mapping_gsheetinfo calculated_expressions
                      }}
                    >
                      Cancel
                    </button>
                  </div>

                  <div className="flex md:gap-8 gap-1 justify-end">
                    {step > 0 && (
                      <button
                        type="submit"
                        className="bg-button-bg uppercase py-2 px-6 md:py-3 md:px-12 rounded-md shadow-sm text-sm font-semibold text-ai-primary-text-dark hover:bg-ai-primary-gray focus:outline-none focus:outine-none"
                        onClick={(e) => {
                          setStep(step - 1);
                          scrollRef.current?.scrollIntoView({
                            // behavior: "smooth",
                            block: "start",
                          });
                        }}
                      >
                        Back
                      </button>
                    )}

                    <button
                      className=" uppercase font-semibold py-2 px-6  md:py-3 md:px-12 border border-transparent shadow-sm text-md rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none"
                      onClick={onCLickNext}
                    >
                      {_renderButtonData()}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main >
        </div >
      </div >
    </>
  );
}

export default NewCreateImportPage;