import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { apiCallPost } from "../../../../api/RestAPICalls";
import Multiselect from "../../../../components/Inputs/Multiselect";
import Modal from "../../../../components/Modal";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import { BASE_URL } from "../../../../utils/variables";
import SimpleInput from "../../../../components/Inputs/Input/simple";
import { IoIosCloseCircle } from "react-icons/io";
import Input from "../../../../components/Inputs/Input";
// import { Dropdown } from "rsuite";
//import DropdownSelect from "../../../../components/Inputs/Select/indexNew";
//import Dropdown from "../../../../components/Dropdown";
import Dropdown from "../../../../components/Inputs/Select/indexNew";
import MultiSelectChips from "../../../../components/Inputs/MultiSelectChips/MultiSelectChips";


const ModalItemUser = ({ modalRef, tenantTableData = [] }) => {
  const [inputs, setInputs] = useState({
    email: "",
    tenant: "",
    full_name: "",
    first_name: "",
    last_name: "",
    mobile: "",
    user_type: "Normal User",
  });

  const [error, setError] = useState({
    fullNameError: "",
    tenantError: "",
    emailError: "",
    firstNameError: "",
    lastNameError: "",
    mobileError: "",
    userTypeError: "",
  });

  const [state, setState] = useState({
    loading: false,
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });
  const [formDataError, setFormDataError] = useState({});

  const handleChange = (e) => {
    if (e.target.name === "user_type") {
      setInputs({
        ...inputs,
        user_type: "",
        [e.target.name]: e.target.value,
      });
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }

    setError({
      fullNameError: "",
      tenantError: "",
      emailError: "",
      firstNameError: "",
      lastNameError: "",
      mobileError: "",
      userTypeError: "",
    });
    setFormDataError({ ...formDataError, [e.target.name]: "" });
  };

  const formValidation = () => {
    let isValid = true;
    // if (!inputs.full_name.trim() || inputs.full_name.trim().length < 4) {
    //   setError((PrevError) => {
    //     return {
    //       ...PrevError,
    //       fullNameError: "Fullname is Invalid or missing",
    //     };
    //   });
    //   isValid = false;
    // }
    if (!inputs.tenant) {
      setError((PrevError) => {
        return { ...PrevError, tenantError: "Tenant is required" };
      });
      isValid = false;
    }
    if (!inputs.email || !/\S@\S+\.\S+/.test(inputs.email)) {
      setError((PrevError) => {
        return { ...PrevError, emailError: "Email is invalid or missing" };
      });
      isValid = false;
    }
    if (
      !inputs?.mobile ||
      !/^[6789]\d{9}$/.test(inputs.mobile)
    ) {
      setError((prevError) => {
        return {
          ...prevError,
          mobileError: "Invalid or missing mobile number",
        };
      });
      isValid = false;
    }
    if (!inputs.first_name.trim() || inputs.first_name.trim().length < 2) {
      setError((PrevError) => {
        return {
          ...PrevError,
          firstNameError: "First Name is Invalid or missing",
        };
      });
      isValid = false;
    }
    if (!inputs.last_name.trim()) {
      setError((PrevError) => {
        return {
          ...PrevError,
          lastNameError: "Last Name is Invalid or missing",
        };
      });
      isValid = false;
    }
    return isValid;
  };
  const onTestForm = () => {
    inputs.full_name = inputs.first_name + " " + inputs.last_name;
    const isFormValid = formValidation();
    if (isFormValid) { }
    // console.log("These are the Inputs", inputs);
  }
  const onCreateUser = (e) => {
    inputs.full_name = inputs.first_name + " " + inputs.last_name;
    e.preventDefault();
    let createUserURL = BASE_URL + "user/create";
    const isFormValid = formValidation();

    if (isFormValid) {
      let body = inputs;
      //  console.log("THIS IS THE BODY!", body)
      body.tenant_ids = [];
      tenantTableData.all_tenants.forEach((tenant) => {
        if (body.tenant.includes(tenant["Tenant Name"].value)) {
          body.tenant_ids.push(tenant["Tenant ID"].value.toString());
        } else {
          // console.log("____________else", tenant["Tenant ID"].value.toString());
        }
      });
      body.admin = localStorage.getItem("user_email");
      setState({ ...state, loading: true });
      apiCallPost(
        createUserURL,
        inputs,
        (res) => {
          // on success
          //console.log("User_ created success", res);

          if (res.error) {
            // console.log("RES", res)
            setState({ ...state, loading: false });
            setToast({
              type: true,
              message: "Something went wrong. Please try again!",
            });
          } else {
            setInputs({
              email: "",
              tenant: "",
            });

            setError({
              fullNameError: "",
              tenantError: "",
              emailError: "",
            });

            setState({ ...state, loading: false });
            setToast({
              type: false,
              message:
                "User created successfully. The credentials are sent to the newly created email.",
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }

        },

        (res) => {
          // on failure
          // console.log("User_ created failure", res);

          setState({ ...state, loading: false });
          setToast({
            type: true,
            message: "Something went wrong. Please try again!",
          });
        }
      );
    }
  };
  // console.log("Inputs", inputs);

  return (
    <Modal ref={modalRef}>
      {/* {console.log("error", error)} */}
      <div className="">
        <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
          <h2 className="text-base text-ai-primary-dark  font-bold ">
            Create User{" "}
          </h2>
          <IoIosCloseCircle
            className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
            onClick={() => {
              modalRef.current.closeModal();
            }}
          />
        </div>
        <div className="p-4 flex flex-col gap-4">
          <div className="grid grid-cols-2  gap-x-2 gap-y-2">
            {/* <Input
              label="Full Name"
              type="text"
              name="full_name"
              id="full_name"
              onChange={handleChange}
              value={inputs.full_name}
              error={error?.fullNameError}
            /> */}
            <Input
              label="First Name*"
              placeholder="First Name"
              type="text"
              name="first_name"
              id="first_name"
              onChange={handleChange}
              value={inputs.first_name}
              error={error?.firstNameError}
            />
            <Input
              label="Last Name*"
              placeholder="Last Name"
              type="text"
              name="last_name"
              id="last_name"
              onChange={handleChange}
              value={inputs.last_name}
              error={error?.lastNameError}
            />
            <Input
              label="Phone*"
              placeholder="Phone"
              type="text"
              id="mobile"
              name="mobile"
              onChange={handleChange}
              value={inputs.mobile}
              error={error?.mobileError}
              maxlength="10" />

            <Input
              label="Email*"
              placeholder="Email"
              type="text"
              name="email"
              id="email"
              onChange={handleChange}
              value={inputs.email}
              error={error?.emailError}
            />

            {/* <Dropdown
              onChange={(q) => {
                setInputs({
                  ...inputs,
                  user_type: q
                });
                //console.log("DATA TO SEND", dataToSend)



                //console.log("THIS IS THE FINAL DATA", dataToSend);
              }}
              values={[
                { label: "User", value: "user" },
                { label: "Admin", value: "admin" },
                { label: "Super User", value: "super_user" },
              ]}
              label="User Type"
              name="user_type"
              value="User Type"
            /> */}
            <div>
              <label
                htmlFor="user_type"
                className="flex pb-1 text-sm font-semibold text-ai-primary-dark"
              >
                User type
              </label>
              <select
                label="User Type"
                onChange={handleChange}
                value={inputs.user_type}
                placeholder="User Type"
                id="user_type"
                name="user_type"
                className="mt-1 shadow-sm placeholder-ai-primary-dark placeholder-opacity-50 focus:border-green-400 focus:outline-none focus:ring-1 focus:ring-sa-secondary block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value="Normal User">User</option>
                <option value="Admin">Super User</option>
              </select>
            </div>
          </div>
          {/* {inputs.user_type !== "Admin" && */}
          <div className="col-span-6 sm:col-span-6">


            {/* <Multiselect
              onChange={(e) => setInputs({ ...inputs, tenant: e })}
              placeholder="Tenant"
              label="Tenant"
              name="tenant"
              values={tenantTableData.all_tenants.map(
                (item) => item["Tenant Name"]
              )}
            /> */}

            < MultiSelectChips label="Tenants" values={tenantTableData.all_tenants.map(
              (item) => item["Tenant Name"]
            )}
              onChange={(e) => setInputs({ ...inputs, tenant: e })}
            />

            <span className="text-red-600">{error.tenantError}</span>
          </div>
          {/* } */}
          {toast.message && (
            <ToastMsg
              msg={toast.message}
              isError={toast.type}
              setToast={setToast}
            />
          )}
          <button
            className=" max-w-max font-medium py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none"
            onClick={onCreateUser}
          //onClick={onTestForm}
          >
            {state.loading ? (
              <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                <AiOutlineLoading3Quarters
                  className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                  size={40}
                />
              </div>
            ) : (
              "Create"
            )}
          </button>
        </div>
      </div>

    </Modal>
  );
};

export default ModalItemUser;








