/* This example requires Tailwind CSS v2.0+ */
import { useState, forwardRef, useImperativeHandle } from "react";

const Modal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  useImperativeHandle(
    ref,
    () => ({
      openModal() {
        setOpen(true);
      },
      closeModal() {
        setOpen(false);
      },
    }),
    []
  );

  return (
    <div
      className={
        open
          ? "fixed z-30 inset-0 overflow-y-auto"
          : "w-0 h-0 fixed z-30 inset-0 overflow-y-auto"
      }
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-full text-center ">
        {open && (
          <div
            className={
              open
                ? "ease-in duration-200 opacity-100 fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                : "opacity-0 ease-out z-0 duration-300 fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
            }
            // onClick={() => setOpen(!open)}
            aria-hidden="true"
          ></div>
        )}

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className={
            open
              ? "mx-2 sm:mx-0 ease-in duration-200 opacity-100  translate-y-0 sm:scale-100 inline-block align-middle bg-white rounded-lg  text-left  shadow-xl transform transition-all  sm:align-middle sm:max-w-lg w-full"
              : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95  ease-out duration-300 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          }
        >
          {props.children}
        </div>
      </div>
    </div>
  );
});

export default Modal;