import React, { Fragment, useState, useEffect } from "react";
import ToolTipText from "../../../components/Tooltips";

function DropdownSelect({
  label,
  name,
  value,
  values,
  onChange,
  isTooltip,
  ToolTipDescription,
  disabled
}) {
  const [selected, setSelected] = useState(value === undefined ? values[0] : value);

  useEffect(() => {
    setSelected(value === undefined ? values[0] : value);
    console.log("StateUpdate");
  }, [value, values]);

  return (
    <Fragment>
      <div className="flex">
        <label
          htmlFor={name}
          className="flex pb-1 text-sm font-semibold text-ai-primary-dark"
        >
          {label}
        </label>
        {isTooltip && <ToolTipText text={ToolTipDescription} />}
      </div>

      <select
        onChange={(e) => {
          const newValue = e.target.value;
          setSelected(newValue);
          onChange(newValue);
        }}
        value={selected}
        id={name}
        name={name}
        className={`shadow-sm  placeholder-ai-primary-dark placeholder-opacity-50 focus:border-green-400 focus:outline-none focus:ring-1 focus:ring-sa-secondary block w-full sm:text-sm border-gray-300 rounded-md ${disabled && `cursor-not-allowed disabled:opacity-75`
          } `}
        disabled={disabled}
      >
        {values.map((value, k) => (
          <option value={value} key={k}>
            {value}
          </option>
        ))}
      </select>
    </Fragment>
  );
}

export default DropdownSelect;
