module.exports = {
  // BASE_URL: process.env.REACT_APP_BASE_URL, // local database
  BASE_URL: "https://apis.databeat.io/",
  // BASE_URL: process.env.REACT_APP_BASE_URL,
  // window.location.origin.includes("https://localhost/")
  //   ? process.env.REACT_APP_BASE_URL
  //   : process.env.REACT_APP_BASE_URL,
  USERS: [
    {
      Name: "Daphne Acosta",
      Email: "mi.ac@google.edu",
      "Number Range": 8,
      Balance: "$87.12",
      Region: "Swiętokrzyskie",
      Status: "Active",
    },
    {
      Name: "Faith Beach",
      Email: "velit@hotmail.ca",
      "Number Range": 1,
      Balance: "$47.55",
      Status: "Not Active",
      Region: "Chandigarh",
    },
    {
      Name: "Zenia Galloway",
      Email: "et@outlook.couk",
      "Number Range": 10,
      Status: "Not Active",
      Balance: "$85.07",
      Region: "Toscana",
    },
    {
      Name: "Oren Gilmore",
      Email: "pulvinar.arcu.et@google.org",
      "Number Range": 1,
      Balance: "$91.62",
      Status: "Active",
      Region: "Madrid",
    },
    {
      Name: "Cody Alvarado",
      Email: "amet.luctus.vulputate@yahoo.net",
      "Number Range": 4,
      Balance: "$94.98",
      Region: "Ceará",
      Status: "Not Active",
    },
    {
      Name: "Clare Olson",
      Email: "facilisi.sed@protonmail.couk",
      "Number Range": 9,
      Status: "Active",
      Balance: "$13.09",
      Region: "Connacht",
    },
    {
      Name: "Miranda Ferrell",
      Email: "nibh@yahoo.couk",
      "Number Range": 6,
      Status: "Active",
      Balance: "$28.11",
      Region: "Antioquia",
    },
    {
      Name: "Hayes Bonner",
      Email: "morbi.neque@yahoo.net",
      "Number Range": 6,
      Balance: "$3.18",
      Status: "Not Active",
      Region: "Yukon",
    },
    {
      Name: "Geraldine Alvarado",
      Email: "faucibus.orci.luctus@hotmail.org",
      "Number Range": 7,
      Status: "Active",
      Balance: "$62.06",
      Region: "Puno",
    },
    {
      Name: "Joseph Spears",
      Email: "vitae.risus@outlook.org",
      "Number Range": 7,
      Status: "Not Active",
      Balance: "$53.67",
      Region: "Puglia",
    },
  ],

  TENANT_ID:
    localStorage.getItem("isSuperAdmin") === true
      ? 0
      : localStorage.getItem("selected_tenant_id"),
};
