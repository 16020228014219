import React, { useState, useEffect } from "react";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import Input from "../../../../components/Inputs/Input";
import Modal from "../../../../components/Modal";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import ToolTipText from "../../../../components/Tooltips";
import { BASE_URL, TENANT_ID } from "../../../../utils/variables";
import { IoIosCloseCircle } from "react-icons/io";

const EditConnectionModal = ({ def, editConnectionRef }) => {
  const [inputs, setInputs] = useState({
    connectionType: def.connection_type,
  });
  // console.log("deffff", def);
  const [inputError, setInputError] = useState({
    service_accountError: "",
    network_idError: "",
    connection_idError: "",
    emailError: "",
    passwordError: "",
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  const formValidation = () => {
    let isValid = true;

    if (def.connection_type.toUpperCase() === "GAM") {
      if (!def.service_account) {
        setInputError((prevError) => {
          return {
            ...prevError,
            service_accountError: "File invalid or missing",
          };
        });
        isValid = false;
      }
      if (!inputs.network_id) {
        setInputError((prevError) => {
          return {
            ...prevError,
            network_idError: "Network Id invalid or missing",
          };
        });
        isValid = false;
      }
      if (!inputs.connection_name) {
        setInputError((prevError) => {
          return {
            ...prevError,
            connection_nameError: "Connection invalid or missing",
          };
        });
        isValid = false;
      }
    } else if (def.connection_type.toUpperCase() === "EMAIL" || def.connection_type.toUpperCase() === "GA") {
      if (!inputs.connection_name) {
        setInputError((prevError) => {
          return {
            ...prevError,
            connection_nameError: "Connection invalid or missing",
          };
        });
        isValid = false;
      }
      if (!inputs.email || !/\S@\S+\.\S+/.test(inputs.email)) {
        setInputError((prevError) => {
          return {
            ...prevError,
            emailError: "Email invalid or missing",
          };
        });
        isValid = false;
      }
      if (inputs.password?.length < 6) {
        setInputError((prevError) => {
          return {
            ...prevError,
            passwordError: "password invalid or missing",
          };
        });
        isValid = false;
      }
    } else if (def.connection_type.toUpperCase() === "TAM") {
      if (!inputs.connection_name) {
        setInputError((prevError) => {
          return {
            ...prevError,
            connection_nameError: "Connection invalid or missing",
          };
        });
        isValid = false;
      }
      if (!inputs.project_name) {
        setInputError((prevError) => {
          return {
            ...prevError,
            project_nameError: "Project invalid or missing",
          };
        });
        isValid = false;
      }
    }
    return isValid;
  };
  const [state, setState] = useState({
    loading: false,
  });
  const [showPassword, setShowPassword] = useState(true);

  const handleChange = (e) => {
    if (e.target.name !== "connectionType") {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
    setInputError({
      service_accountError: "",
      network_idError: "",
      connection_idError: "",
      emailError: "",
      passwordError: "",
    });
  };

  const onCreateConnection = async () => {
    let isFormValid = formValidation();
    if (isFormValid) {
      setState({ ...state, loading: true });
      try {
        const response = await fetch(
          `${BASE_URL}connection/edit/${TENANT_ID}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            body: JSON.stringify(inputs)
            // body: JSON.stringify({ ...inputs, connection: parseInt(inputs.connection_id) }),

          }
        );
        const responseData = await response.json();
        setState({ ...state, loading: false });
        if (responseData.error) {
          setToast({ type: true, message: responseData.error });
        } else {
          setToast({ type: false, message: responseData.response });
          setTimeout(() => {
            window.location.reload(true);
          }, 2000);
        }
      } catch (error) {
        setState({
          ...state,
          loading: false,
        });
        setToast({ type: true, message: "Something went wrong" });
      }
    }
  };

  const handleFilesChosen = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.files[0] });
  };

  useEffect(() => {
    //console.log("storage", localStorage.getItem("isSuperAdmin"));
    setInputs({ ...def });
  }, [def]);

  const _onFileUploadEditConnection = async (e) => {
    //    -----------------------  NEW CONNECTION CREATION
    //console.log("_onFileUploadEditConnection call");
    try {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "connection");
      formData.append("connection_id", inputs.connection_id);

      const response = await fetch(`${BASE_URL}upload/${TENANT_ID}`, {
        method: "POST",
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
        body: formData,
      });
      const responseData = await response.json();
      // console.log("responseData", responseData);
      setToast({ type: false, message: "file uploaded successfully." });
    } catch (error) {
      //console.log("-> ", error);
      setToast({ type: true, message: "File upload unsuccessfull." });
    }
  };

  const renderBody = () => {
    if (def.connection_type.toUpperCase() === "GAM") {
      return <>
        <div className="col-span-6">
          <div className="flex">
            <div
              htmlFor="serviceAccount"
              className="flex text-sm font-semibold text-ai-primary-dark"
            >
              Service Account Upload
            </div>
            <ToolTipText
              text={
                "Upload the service account json which is addded as a user in respective GAM account"
              }
            />
          </div>
          <label
            htmlFor="serviceAccount"
            className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
          >
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="service_account"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                >
                  <span className="mx-auto text-center">
                    Upload a file
                  </span>
                  <input
                    name="serviceAccount"
                    id="serviceAccount"
                    type="file"
                    className="sr-only"
                    accept=".json"
                    onChange={_onFileUploadEditConnection}
                  />
                </label>
              </div>
              <p className="text-xs text-gray-500">
                JSON File
              </p>
            </div>
            <span className="text-red-600">
              {inputError.service_accountError}
            </span>
          </label>
          {def.service_account}
        </div>

        <div className="col-span-6">
          <div className="flex">
            <label
              htmlFor="NetworkID"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Network ID
            </label>
            <ToolTipText
              text={
                "Provide the Network ID of the GAM account"
              }
            />
          </div>
          <Input
            type="text"

            name="network_id"
            id="network_id"
            onChange={handleChange}
            value={inputs.network_id}
            placeholder="Network ID"
            noMargin={true}
          />
          <span className="text-red-600">
            {inputError.network_idError}
          </span>
        </div>

        <div className="col-span-3 sm:col-span-3">
          <div className="flex">
            <label
              htmlFor="connectionName"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Connection Name
            </label>

            <ToolTipText
              text={
                "User preference Name of the connection"
              }
            />
          </div>
          <Input
            type="text"
            name="connection_name"
            id="connection_name"
            onChange={handleChange}
            value={inputs.connection_name}
            placeholder="Connection Name"
            noMargin={true}
          />
          <span className="text-red-600">
            {inputError.connection_nameError}
          </span>
        </div>

        <div className="col-span-3 sm:col-span-3">
          <div className="flex">
            <label
              htmlFor="project_name"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Project Name
            </label>
            <ToolTipText text={"BQ Project Name"} />
          </div>
          <Input
            type="text"
            name="project_name"
            id="project_name"
            onChange={handleChange}
            value={inputs.project_name}
            noMargin={true}
            placeholder="Project Name"
          />
          <span className="text-red-600">
            {inputError.project_nameError}
          </span>
        </div>
      </>
    } else if (def.connection_type.toUpperCase() === "EMAIL" || def.connection_type.toUpperCase() === "GA") {
      return <>
        <div className="col-span-6">
          <div className="flex">
            <div
              htmlFor="serviceAccount"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Service Account Upload
            </div>
            <ToolTipText
              text={
                "Upload the service account json which is addded as a user in respective GAM account"
              }
            />
          </div>
          <label
            htmlFor="serviceAccount"
            className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
          >
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="serviceAccount"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                >
                  <span className="mx-auto text-center">
                    Upload a file
                  </span>
                  <input
                    name="serviceAccount"
                    id="serviceAccount"
                    type="file"
                    className="sr-only"
                    accept=".json"
                    onChange={_onFileUploadEditConnection}
                  />
                </label>
              </div>
              <p className="text-xs text-gray-500">
                JSON File
              </p>
            </div>
          </label>
          {def.service_account}
        </div>

        <div className="col-span-6 sm:col-span-4">
          <div className="flex">
            <label
              htmlFor="email"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Email
            </label>
            <ToolTipText
              text={
                "Email ID of the email where the reports are scheduled to"
              }
            />
          </div>
          <Input
            type="text"
            name="email"
            id="email"
            autoComplete={false}
            onChange={handleChange}
            value={inputs.email}
            noMargin={true}
            disabled={
              localStorage.getItem("isSuperAdmin") ===
                null ||
                localStorage.getItem("isSuperAdmin") === false
                ? false
                : true
            }
            className="mt-1  focus:ring-ai-primary-teal focus:border-ai-primary-teal block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <span className="text-red-600">
            {inputError.emailError}
          </span>
        </div>


        <div className="col-span-6 sm:col-span-4">
          <div className="flex">
            <label
              htmlFor="connectionName"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Connection Name
            </label>

            <ToolTipText
              text={
                "User preference Name of the connection"
              }
            />
          </div>
          <Input
            type="text"
            name="connection_name"
            id="connection_name"
            onChange={handleChange}
            value={inputs.connection_name}
            noMargin={true}
          />
          <span className="text-red-600">
            {inputError.connection_nameError}
          </span>
        </div>

        <div className="col-span-6 sm:col-span-4">
          <div className="flex">
            <label
              htmlFor="project_name"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Project Name
            </label>
            <ToolTipText text={"BQ Project Name"} />
          </div>
          <Input
            type="text"
            name="project_name"
            id="project_name"
            onChange={handleChange}
            value={inputs.project_name}
            noMargin={true}
          />
          <span className="text-red-600">
            {inputError.project_nameError}
          </span>
        </div>
      </>
    }
    else if (def.connection_type.toUpperCase() === "TAM") {
      return <>
        <div className="col-span-6">
          <div className="flex">
            <div
              htmlFor="serviceAccount"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Service Account Upload
            </div>
            <ToolTipText
              text={
                "Upload the service account json which is addded as a user in respective GAM account"
              }
            />
          </div>
          <label
            htmlFor="serviceAccount"
            className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
          >
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="serviceAccount"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                >
                  <span className="mx-auto text-center ">
                    Upload a file
                  </span>
                  <input
                    name="serviceAccount"
                    id="serviceAccount"
                    type="file"
                    className="sr-only"
                    accept=".json"
                    onChange={_onFileUploadEditConnection}
                  />
                </label>
              </div>
              <p className="text-xs text-gray-500">
                JSON File
              </p>
            </div>
          </label>
          {def.service_account}
        </div>

        <div className="col-span-6 sm:col-span-4">
          <div className="flex">
            <label
              htmlFor="connectionName"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Connection Name
            </label>

            <ToolTipText
              text={
                "User preference Name of the connection"
              }
            />
          </div>
          <Input
            type="text"
            name="connection_name"
            id="connection_name"
            onChange={handleChange}
            value={inputs.connection_name}
            noMargin={true}
          />
          <span className="text-red-600">
            {inputError.connection_nameError}
          </span>
        </div>

        <div className="col-span-6 sm:col-span-4">
          <div className="flex">
            <label
              htmlFor="project_name"
              className="flex text-sm font-semibold text-ai-primary-dark mt-1"
            >
              Project Name
            </label>
            <ToolTipText text={"BQ Project Name"} />
          </div>
          <Input
            type="text"
            name="project_name"
            id="project_name"
            onChange={handleChange}
            value={inputs.project_name}
            noMargin={true}
          />
          <span className="text-red-600">
            {inputError.project_nameError}
          </span>
        </div>
      </>
    }
  }

  return (
    <Modal ref={editConnectionRef}>
      {/* <div className="p-4 sm:p-6"> */}
      <div className="">
        {/* <h3
            className="text-lg leading-6 font-bold text-gray-900"
            id="modal-title"
          >
            Edit Connection
          </h3> */}
        <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
          <h2 className="text-base text-ai-primary-dark  font-bold ">
            Edit Connection
          </h2>
          <IoIosCloseCircle
            className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
            onClick={() => {
              editConnectionRef.current.closeModal();
            }}
          />
        </div>
        <div className="mt-0 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-0 md:mt-0 md:col-span-3">
              {/* <div> */}
              <div className="overflow-hidden sm:rounded-md">
                <div className="px-4 py-2 bg-white sm:p-2">
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="connectionType"
                        className="block text-sm font-bold text-gray-700"
                      >
                        Connection Type: {def.connection_type.toUpperCase()}
                      </label>
                    </div>

                    {renderBody()}
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      {toast.message && (
        <ToastMsg
          msg={toast.message}
          isError={toast.type}
          setToast={setToast}
        />
      )}

      <div className="p-2 ml-2 mt-2 sm:mt-2 sm:grid sm:grid-cols-6 sm:gap-2 sm:grid-flow-row-dense">
        <button
          type="button"
          onClick={() => onCreateConnection()}
          className="w-full col-span-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover text-base font-medium text-white sm:col-start-1 sm:text-sm"
        >
          {state.loading ? (
            <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
              <AiOutlineLoading3Quarters
                className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                size={40}
              />
            </div>
          ) : (
            "Update"
          )}
        </button>
        {/* <button
          type="button"
          onClick={() => {
            editConnectionRef.current.closeModal();
            setInputError({
              service_accountError: "",
              network_idError: "",
              connection_idError: "",
              emailError: "",
              passwordError: "",
            });
          }}
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
        >
          Close
        </button> */}
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default EditConnectionModal;
