import React, { useEffect, useState } from "react";
import { BsArrowDown } from "react-icons/bs";
import { MdPlayCircleFilled, MdTransferWithinAStation } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import Toggle from "../Toggle";
import { MdDeleteForever } from "react-icons/md";
import { BiTransferAlt } from "react-icons/bi";

function DataTable({
  headers,
  content,
  onClickRemoveUser,
  onClickRemoveTenant,
  onClickRemoveImport,
  onUserStatusChange,
  onClickEditUser,
  onClickTransferTenant,
  emptyNotification,
  onUploadFile,
  onClickManualRun,
  onReIssuePassword,
  tableCSS,
}) {
  const [data, setData] = useState({
    headers: headers,
    content: content,
  });
  const [sortBy, setSortBy] = useState({ type: headers[0], order: 1 });

  useEffect(() => {
    let newData = content;
    newData.sort((x, y) => (x[headers[0]] > y[headers[0]] ? 1 : -1));
    setData({ ...data, content: newData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Sort = (newSortBy) => {
    let newData = data.content;
    if (newSortBy === sortBy.type) {
      if (sortBy.order === 1) {
        newData.sort((x, y) => (x[newSortBy] < y[newSortBy] ? 1 : -1));
        setSortBy({ type: newSortBy, order: -1 });
      } else {
        newData.sort((x, y) => (x[newSortBy] > y[newSortBy] ? 1 : -1));
        setSortBy({ type: newSortBy, order: 1 });
      }
    } else {
      newData.sort((x, y) => (x[newSortBy] > y[newSortBy] ? 1 : -1));
      setSortBy({ type: newSortBy, order: 1 });
    }

    setData({ ...data, content: newData });
  };
  return (
    <div className="border-b border-gray-200 sm:rounded-lg">
      <table className="relative min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {data.headers.map((head, k) => (
              <th
                scope="col"
                key={k}
                style={{
                  backgroundColor: tableCSS?.headerColor,
                  color: tableCSS?.color,
                }}
                onClick={() => Sort(head)}
                className={
                  sortBy === head
                    ? `sticky z-20 top-0 px-6 cursor-pointer py-3 text-sm font-semibold text-ai-primary-text-dark capitalize tracking-wider whitespace-nowrap ${k === 0 ? "text-left" : "text-right"
                    }`
                    : `sticky z-20 top-0 px-6 cursor-pointer py-3 text-sm font-semibold text-ai-primary-text-dark capitalize tracking-wider whitespace-nowrap ${k === 0 ? "text-left" : "text-right"
                    }`
                }
              >
                <span
                  // className={
                  //   k === 0
                  //     ? "flex justify-start items-start"
                  //     : "flex justify-end items-end"
                  // }
                  //Changed the className below as we need the Actions header to be in the center
                  className={head === "Actions" ? "flex justify-center items-center" : "flex justify-start items-start"}
                >
                  {sortBy.type === head && (
                    <BsArrowDown className="mr-2 text-lg" />
                  )}{" "}
                  {head}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        {data.content.length > 0 ? (
          <tbody className="">
            {data.content.map((connection, k) => (
              <tr key={k} className={k % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                {data.headers.map((header, v) => {
                  if (header === "Run") {
                    return (
                      <td
                        key={v}
                        className="px-6 py-4 my-auto whitespace-nowrap text-right text-sm font-medium"
                      >
                        <a
                          href="#s"
                          className="text-indigo-600 my-auto hover:text-indigo-900  flex justify-center items-center	"
                        >
                          <MdPlayCircleFilled
                            className=" text-lg"
                            onClick={() => {
                              onClickManualRun(connection, k);
                            }}
                          />
                        </a>
                      </td>
                    );
                  } else if (header === "Edit") {
                    return (
                      <td
                        key={v}
                        className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                      ><p title={header}>
                          <a
                            href="#edit"
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            <AiFillEdit
                              className=" text-lg"
                              onClick={() => {
                                onClickEditUser(connection, k);
                              }}
                            />

                          </a>
                        </p>
                      </td>
                    );
                  }
                  else if (header === "Actions") {
                    return (
                      <td
                        key={v}
                        className="px-6 text-center text-sm font-medium"
                      >
                        <div className="flex justify-center">
                          <p title="Edit" className="mr-4 mt-3">
                            <a
                              href="#edit"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              <AiFillEdit
                                className=" text-lg"
                                onClick={() => {
                                  onClickEditUser(connection, k);
                                }}
                              />
                            </a>
                          </p>
                          <p title="Transfer Tenants" className="mr-4 mt-3">
                            <a
                              href="#transfer"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              <BiTransferAlt
                                className=" text-lg"
                                onClick={() => {
                                  onClickTransferTenant(connection, k);
                                }}
                              />
                            </a>
                          </p>
                          <p title="Re-issue Password" className="">
                            <button
                              onClick={() => onReIssuePassword(connection["Email"])}
                              className="p-1 cursor-pointer text-red-600 rounded-md font-semibold"
                            >
                              Re-issue Password
                            </button>
                          </p>
                        </div>
                      </td>




                    );
                  } else if (header === "Transfer Tenant") {
                    return (
                      <td
                        key={v}
                        className="px-6 py-4 whitespace-nowrap flex justify-center items-center text-center text-sm font-medium"
                      ><p title={header}>
                          <a
                            href="#transfer"
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            <BiTransferAlt
                              className=" text-lg"
                              onClick={() => {
                                onClickTransferTenant(connection, k);
                              }}
                            />
                          </a>
                        </p>
                      </td>
                    );
                  } else if (header === "Status") {
                    return (
                      <Toggle
                        key={v}
                        name="Status"
                        id={"active" + k}
                        value={connection[header] === "Active" ? true : false}
                        onChange={(e) => {
                          onUserStatusChange(e, connection);
                        }}
                      />
                    );
                  } else if (header === "User Status") {
                    return (
                      <div className="flex justify-center">
                        <p title={header}>
                          <Toggle
                            key={v}
                            name="Status"
                            id={"active" + k}
                            value={connection[header]}
                            onChange={(e) => {
                              onUserStatusChange(e, connection);
                            }}
                          />
                        </p>
                      </div>
                    );
                  } else if (header === "Upload") {
                    return (
                      <div key={v} className="flex text-sm text-gray-600">
                        <label
                          htmlFor={"file_upload_" + k}
                          className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                          <span className="mx-auto text-center">
                            Upload a file
                          </span>
                          <input
                            name={"file_upload_" + k}
                            id={"file_upload_" + k}
                            onChange={(e) => {
                              onUploadFile(e, connection);
                            }}
                            type="file"
                            accept=".json"
                            className="sr-only"
                            title={header}
                          />
                        </label>
                      </div>
                    );
                  } else if (header === "Remove") {
                    return (
                      <td key={v} className="px-6  text-sm font-medium">
                        <button
                          onClick={() => {
                            onClickRemoveUser(connection["Account ID"]);
                          }}
                          className="p-1 cursor-pointer font-semibold text-red-600 rounded-md"
                          title={header}
                        >
                          Remove
                        </button>
                      </td>
                    );
                  } else if (header === "Re-issue Password") {
                    return (
                      <td key={v} className="px-6  text-sm font-medium">
                        <p title={header}>
                          <button
                            onClick={() => onReIssuePassword(connection["Email"])}
                            className="p-1 cursor-pointer text-blue-500 rounded-md font-semibold"

                          >
                            Re-issue Password
                          </button>
                        </p>
                      </td>
                    );
                  } else if (header === "RemoveTenant") {
                    return (
                      <td
                        key={v}
                        className="px-6 py-4 flex justify-center items-center text-right text-sm font-medium"
                      >
                        <button
                          onClick={() => {
                            onClickRemoveTenant(connection["Tenant ID"]);
                          }}
                          className="p-1 cursor-pointer bg-red-600 rounded-md text-white"
                        >
                          Remove
                        </button>
                      </td>
                    );
                  } else if (header === "Delete") {
                    return (
                      <td
                        key={v}
                        className="px-6 py-4 my-auto whitespace-nowrap text-right text-sm font-medium"
                      >
                        <a
                          href="#s"
                          className="text-red-600 my-auto hover:text-red-900  flex justify-center items-center	"
                        >
                          <MdDeleteForever
                            className=" text-lg"
                            onClick={() => {
                              onClickRemoveImport(connection);
                            }}
                          />
                        </a>
                      </td>
                    );
                  } else if (header === "Client Name") {
                    return (
                      <td
                        key={v}
                        className="px-6 py-4 my-auto whitespace-nowrap text-right text-sm font-medium"
                      >
                        <p
                          style={{
                            maxWidth: "200px",
                          }}
                          className={"flex "}
                          title={connection[header]}
                        >
                          {connection[header]}
                        </p>
                      </td>
                    );
                  } else if (header === "Tenant Name") {
                    return (
                      <td
                        key={v}
                        className="px-6 py-4 my-auto whitespace-nowrap text-right text-sm font-medium"
                      >
                        <p
                          style={{
                            maxWidth: "200px",
                          }}
                          className={"flex "}
                          title={connection[header]}
                        >
                          {connection[header]}
                        </p>
                      </td>
                    );
                  } else if (header === "Connection Name") {
                    return (
                      <td
                        key={v}
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                        }}
                        className="px-6 py-4 cursor-pointer whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        <p
                          style={{
                            maxWidth: "200px",
                          }}
                          className="flex justify-left"
                          title={connection[header]}
                        >
                          {connection[header].toUpperCase()}
                        </p>
                      </td>
                    );
                  } else if (header === "Time") {
                    return (
                      <td
                        key={v}
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                        }}
                        className="px-6 py-4 cursor-pointer whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        <p
                          style={{
                            maxWidth: "200px",
                          }}
                          className="flex justify-end"
                          title={connection[header]}
                        >
                          {connection[header].includes(":")
                            ? connection[header].split(":").join("")
                            : connection[header]}
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td
                        key={v}
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                        }}
                        className={
                          header === "Import Name"
                            ? "px-6 py-4 text-sm cursor-pointer font-medium text-gray-900"
                            : "px-6 py-4 cursor-pointer whitespace-nowrap text-sm font-medium text-gray-900"
                        }
                      >
                        <p
                          style={
                            header !== "Run Status"
                              ? {
                                maxWidth: "200px",
                              }
                              : {
                                maxWidth: "200px",
                                marginRight: "auto",
                                display: "flex",
                                justifyContent: "end",
                              }
                          }
                          className={
                            header === "Import Name" ||
                              header === "User Name" ||
                              header === "Email" ||
                              header === "Frequency" ||
                              header === "Latest Run Status" ||
                              header === "Run Status" ||
                              header === "Error" ||
                              header === "Tenant"
                              ? " ml-0"
                              : "flex"
                          }
                          title={connection[header]}
                        >
                          {connection[header]}
                        </p>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className="my-4 w-full mx-auto text-center">
            <tr>
              <td
                colSpan={`${data.headers.length}`}
                className="font-semibold py-3"
              >
                {emptyNotification}
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

export default DataTable;
