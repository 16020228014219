import React, { useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';

const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="rounded-md mb-4 shadow-md">
            <div
                className="flex rounded-md border border-gray-300 items-center justify-between bg-gray-100 px-4 py-2 cursor-pointer"
                onClick={toggleAccordion}
            >
                <h2 className="text-lg text-black font-medium">{title}</h2>
                <RiArrowDropDownLine

                    className={`text-lg h-5 w-5 transform ${isOpen ? 'rotate-180' : ''}`}

                />

            </div>
            {isOpen && (
                <div
                    className={`accordion-content rounded-md overflow-scroll transition-all duration-300 ease-in-out ${isOpen ? 'max-h-96' : 'max-h-0'
                        }`}
                    style={{ maxHeight: "500px" }}
                >
                    <div className="p-4">{children}</div>
                </div>
            )}
        </div>
    );
};

export default Accordion;
