import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { apiCallPost } from "../../../../api/RestAPICalls";
import Input from "../../../../components/Inputs/Input";
import Modal from "../../../../components/Modal";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import { BASE_URL } from "../../../../utils/variables";
import { IoIosCloseCircle } from "react-icons/io";

const ModalItemRemoveUser = ({
  modalRefRemoveUser,
  usersData,
  removeUserID,
}) => {
  const [inputs, setInputs] = useState({
    confirm: "",
    account_name:
      usersData.allUsers?.filter((user) => user["Account ID"] === removeUserID)
        .length > 0
        ? usersData.allUsers?.filter(
            (user) => user["Account ID"] === removeUserID
          )[0]["User Name"]
        : "",
  });
  const [removeState, setRemoveState] = useState({
    loading: false,
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onRemoveUser = (e) => {
    setRemoveState({ ...removeState, loading: true });
    e.preventDefault();
    let removeUserURL = BASE_URL + "remove/user";
    let bodyParams = {
      account_id: removeUserID,
    };

    apiCallPost(
      removeUserURL,
      bodyParams,
      _onSuccessRemoveUser,
      _onFailureRemoveUser
    );
  };
  const _onSuccessRemoveUser = async (responseJson) => {
    modalRefRemoveUser.current.closeModal();
    window.location.reload(true);
    setInputs({
      email: "",
      tenant: "",
      username: "",
    });
    setRemoveState({ ...removeState, loading: false });
    setToast({ type: false, message: "User Removed successfully" });
  };

  const _onFailureRemoveUser = async (responseJson) => {
    setRemoveState({
      ...removeState,
      loading: false,
    });
    setToast({ type: true, message: "Something Went Wrong" });
  };

  return (
    <Modal ref={modalRefRemoveUser}>
      {/* <div className="p-4 sm:p-6">
        <div className="mt-3">
          <h3
            className="text-lg leading-6 font-bold text-gray-900"
            id="modal-title"
          >
            Removing the User
          </h3>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="mt-5 md:mt-0 md:col-span-3">
                <div>
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className=" ">
                        <>
                          <div className="col-span-4 sm:col-span-4">
                            <label
                              htmlFor="confirm"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Please type CONFIRM to remove the{" "}
                              <span className="font-bold">
                                {inputs.account_name}
                              </span>{" "}
                              user
                            </label>
                            <Input
                              type="text"
                              name="remove"
                              id="remove"
                              onChange={handleChange}
                              value={inputs.remove}
                            />
                          </div>
                          {toast.message && (
                            <ToastMsg
                              msg={toast.message}
                              isError={toast.type}
                              setToast={setToast}
                            />
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            disabled={inputs.remove === "CONFIRM" ? false : true}
            type="button"
            onClick={onRemoveUser}
            className={
              inputs.remove === "CONFIRM"
                ? "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                : "w-full inline-flex justify-center disabled:opacity-50 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
            }
          >
            {removeState.loading ? (
              <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                <AiOutlineLoading3Quarters
                  className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                  size={40}
                />
              </div>
            ) : (
              "Remove"
            )}
          </button>
          <button
            type="button"
            onClick={() => modalRefRemoveUser.current.closeModal()}
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          >
            Close
          </button>
        </div>
      </div> */}
      <div>
        <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
          <h2 className="text-base text-ai-primary-dark  font-bold ">
            Remove User
          </h2>
          <IoIosCloseCircle
            className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
            onClick={() => {
              modalRefRemoveUser.current.closeModal();
            }}
          />
        </div>
        <div className="p-4 ">
          <Input
            label="Please type CONFIRM to remove the user"
            type="text"
            name="remove"
            id="remove"
            onChange={handleChange}
            value={inputs.remove}
          />
          {toast.message && (
            <ToastMsg
              msg={toast.message}
              isError={toast.type}
              setToast={setToast}
            />
          )}
          <button
            className={` max-w-max font-medium py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none ${
              inputs.remove !== "CONFIRM" && "disabled:opacity-50"
            } `}
            onClick={onRemoveUser}
            disabled={inputs.remove === "CONFIRM" ? false : true}
          >
            {removeState.loading ? (
              <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col ">
                <AiOutlineLoading3Quarters
                  className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                  size={40}
                />
              </div>
            ) : (
              "Remove"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalItemRemoveUser;
