import React from "react";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";
import { apiCallPost } from "../../../api/RestAPICalls";
import Modal from "../../../components/Modal";
import { BASE_URL } from "../../../utils/variables";

function RemoveError({ modalRef, selectedItem = null, data, fetchData }) {
  const [state, setState] = useState({
    loading: false,
    error: "",
  });
  const handleRemove = () => {
    let body = data.filter((item) => item.id !== selectedItem.id);
    setState({ ...state, loading: true });
    // console.log("body", body);
    apiCallPost(
      BASE_URL + "user-guide",
      body,
      (res) => {
        if (res?.error) {
          setState({
            ...state,
            loading: false,
            error: res.error,
          });
        } else {
          setState({ ...state, loading: false });
          modalRef.current.closeModal();
          fetchData();
        }
      },
      (err) => {
        setState({
          ...state,
          loading: false,
          error: "Something went wrong",
        });
      }
    );
  };
  return (
    <Modal ref={modalRef}>
      <>
        <div className="w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
          <h2 className="text-base text-ai-primary-dark  font-bold ">Remove</h2>
          <IoIosCloseCircle
            className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
            onClick={() => {
              modalRef.current.closeModal();
            }}
          />
        </div>
        <div className="flex flex-col items-center justify-center my-10 py-4 px-6">
          <p className="text-ai-primary-text-dark text-lg font-semibold text-center">
            {`Are you sure you want to remove "${selectedItem && selectedItem.subHeading
              }"`}
          </p>
          <div className="flex gap-4 mt-6">
            <button
              onClick={handleRemove}
              className=" max-w-max font-medium py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none"
            >
              {state.loading ? (
                <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                  <AiOutlineLoading3Quarters
                    className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                    size={40}
                  />
                </div>
              ) : (
                "Yes"
              )}
            </button>
            <button
              onClick={() => {
                modalRef.current.closeModal();
              }}
              className=" max-w-max font-medium py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none"
            >
              No
            </button>
          </div>
          {state.error && (
            <p className="my-2 text-ai-primary-text-red ">{state.error}</p>
          )}
        </div>
      </>
    </Modal>
  );
}

export default React.memo(RemoveError);
