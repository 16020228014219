import LoginPage from "./pages/login";
import ForgotPasswordPage from "./pages/forgot-password";
import DashboardRoutes from "./pages/dashboard";
// icons
import { AiOutlineProfile } from "react-icons/ai";
import { SiGoogleanalytics, SiMinutemailer } from "react-icons/si";
import { BsFillPeopleFill, BsFillInfoSquareFill } from "react-icons/bs";
import Test from "./pages/dashboard/imports/CreateImportPage/NewCreateImportPage";
// dashboard pages
import ConnectionsPage from "./pages/dashboard/connections/index";
import OverviewPage from "./pages/dashboard/overview";
import ImportsPage from "./pages/dashboard/imports";
import CreateImport from "./pages/dashboard/imports/create";
import ProductsSuperAdmin from "./pages/superadmin/Products";
import UserbaseSuperadmin from "./pages/superadmin/Users/index";
import UserGuide from "./pages/dashboard/userguide/index";
import CreateConnectionPage from "./pages/dashboard/connections/createConnectionPage/CreateConnectionPage";

import {
  alertConfigSVG_Sidebar,
  alertsSVG_Sidebar,
  dashboardSVG_Sidebar,
  exceptionsConfigSVG_Sidebar,
  usecaseSVG_Sidebar,
  userManagementConfigSVG_Sidebar,
} from "./assets/svgIcons";
import Profile from "./pages/profile";
import CreateConnectionModal from "./pages/dashboard/connections/createConnection";

import NewCreateImportPage from "./pages/dashboard/imports/CreateImportPage/NewCreateImportPage";

const dashboardRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard/super",
    exact: true,
    isSuperAdmin: true,
    showUser: false,
    component: <ProductsSuperAdmin />,
    icon: dashboardSVG_Sidebar,
    showInSidebar: true,
  },
  {
    name: "Overview",
    path: "/dashboard",
    exact: true,
    isSuperAdmin: true,
    showUser: true,
    component: <OverviewPage />,
    icon: dashboardSVG_Sidebar,
    showInSidebar: true,
  },
  {
    name: "Connections",
    path: "/dashboard/connections",
    exact: true,
    isSuperAdmin: true,
    showUser: true,
    component: <ConnectionsPage />,
    showInSidebar: true,
    icon: usecaseSVG_Sidebar,
  },
  {
    name: "Create Connection",
    path: "/dashboard/connections/create/:type",
    exact: true,
    isSuperAdmin: false,
    showUser: false,
    component: <CreateConnectionPage />,
    showInSidebar: false,
    icon: dashboardSVG_Sidebar,
  },
  {
    name: "Edit Connection",
    path: "/dashboard/connections/edit/:type",
    exact: true,
    isSuperAdmin: false,
    showUser: false,
    component: <CreateConnectionPage />,
    showInSidebar: false,
    icon: dashboardSVG_Sidebar,
  },
  {
    name: "User Management",
    path: "/dashboard/super/users",
    exact: true,
    isSuperAdmin: true,
    showUser: false,
    component: <UserbaseSuperadmin />,
    icon: userManagementConfigSVG_Sidebar,
    showInSidebar: true,
  },

  {
    name: "Imports",
    path: "/dashboard/imports", // subpage for connections
    isSuperAdmin: false,
    showUser: true,
    // exact: true,
    component: <ImportsPage />,
    showInSidebar: false,
    icon: dashboardSVG_Sidebar,
  },
  {
    name: "import create",
    path: "/dashboard/createimport",
    // exact: true,
    isSuperAdmin: true,
    showUser: false,
    component: <NewCreateImportPage />,
    icon: dashboardSVG_Sidebar,
    showInSidebar: false,
  },

  // {
  //   name: "Connections",
  //   path: "/dashboard/connections",
  //   exact: true,
  //   isSuperAdmin: true,
  //   component: <ConnectionsPage />,
  //   showInSidebar: true,
  //   icon: usecaseSVG_Sidebar,
  // },
  {
    name: "User Guide",
    path: "/dashboard/guide",
    exact: true,
    isSuperAdmin: true,
    showUser: true,
    component: <UserGuide />,
    showInSidebar: true,
    icon: alertConfigSVG_Sidebar,
  },
  // {
  //   name: "User Guide",
  //   path: "/dashboard/guide",
  //   exact: true,
  //   isSuperAdmin: true,
  //   component: <UserGuide />,
  //   showInSidebar: true,
  //   icon: alertConfigSVG_Sidebar,
  // },

  {
    name: "Create Ingestions",
    path: "/dashboard/ingestions/create/:type",
    exact: true,
    isSuperAdmin: false,
    showUser: false,
    component: <CreateImport />,
    showInSidebar: false,
    icon: dashboardSVG_Sidebar,
  },

  {
    name: "Profile",
    path: "/dashboard/profile",
    exact: true,
    isSuperAdmin: true,
    showUser: true,
    component: <Profile />,
    showInSidebar: false,
    icon: dashboardSVG_Sidebar,
  },
];

const Routes = [
  {
    path: "/",
    component: <LoginPage />,
    exact: true,
  },
  {
    path: "/ForgotPassword",
    component: <ForgotPasswordPage />,
    exact: true,
  },
  {
    path: "/dashboard",
    component: <DashboardRoutes />,
    exact: false,
  },
  {
    path: "/*",
    component: <LoginPage />,
    exact: false,
  }
];
//<Route path='/*' element={<Homepage />} />

export { Routes, dashboardRoutes };
