import React from "react";

const Chips = ({ data = [], showNumber }) => {
    // console.log("chipsdata", data);
    const _renderChipsArray = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-start ">
                {data.map((i, v) => {
                    return (
                        <span className="relative px-4 mb-1 py-2 rounded-full text-ai-primary-dark  shadow-sm border border-ai-primary-grat-unselect-border bg-white font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                            {i}
                            {showNumber && (
                                <span className=" absolute  p-1 h-5 text-xs w-5 flex justify-center align-middle items-center bg-ai-primary-gray-dark rounded-full -top-2 -right-1 text-white">
                                    {v + 1}
                                </span>
                            )}
                        </span>
                    );
                })}
            </div>
        );
    };
    const _renderChips = () => {
        return (
            <div>
                <span className="px-4 py-2 rounded-full text-ai-primary-dark border border-ai-primary-grat-unselect-border bg-white font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                    {data}
                </span>
            </div>
        );
    };
    return (
        <div>{typeof data === "string" ? _renderChips() : _renderChipsArray()}</div>
    );
};

export default Chips;
